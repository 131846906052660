<template>
  <div class="brandselect_left flexL clearFix">
    <div class="letterlst">
      <div class="flexC">
        <a
          :href="'#' + item.letter"
          v-for="item in brands"
          v-bind:key="item.letter"
          >{{ item.letter }}</a
        >
      </div>
    </div>

    <div class="zm_bigbox">
      <ul class="letter_con" id="letter_con" style="width: 250px">
        <li v-for="item in brands" v-bind:key="item.letter">
          <div
            class="letterli"
            :id="item.letter"
            @click="item.is_show = !item.is_show"
          >
            {{ item.letter }}
          </div>
          <dl class="letterli_2" v-show="item.is_show">
            <div class="flexL">
              <dt class="flexL">{{ item.letter }}</dt>
              <dd>
                <div
                  class="zm-letterli2"
                  v-for="brand in item.brands"
                  v-bind:key="brand.id"
                >
                  <div class="flexBC letterli_name">
                    <span @click="changeBrand(brand)"
                      >{{ brand.name }}<em></em></span
                    ><span class="rletter_icon"></span>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BrandsData from "@/data/brands";
export default {
  name: "ImageBrandSelect",
  data() {
    return {
      brands: [],
      currentLetter: "",
    };
  },
  props: {
    isOpenQuote: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  mounted() {},
  created() {
    this.initData();
  },
  computed: {},
  methods: {
    back() {
      this.$route.go(-1);
    },
    initData() {
      let letters = []; // 数据列表
      let brandMap = {}; // 每一个索引指向一个数组，这个数组存储该字母索引的朋友项

      BrandsData.forEach((brand) => {
        const letter = brand.letter;
        brandMap[letter] || (brandMap[letter] = []);
        brandMap[letter].push(brand);
      });

      for (let letter in brandMap) {
        letters.push({
          letter: letter,
          is_show: false,
          brands: brandMap[letter],
        });
      }

      // 按首字母排序
      // 字符串比较不能用减号得出大小，返回都是NaN
      // 因此这里需要通过小于号比较
      letters.sort((a, b) => {
        return a.letter <= b.letter ? -1 : 1;
      });

      this.brands = letters;

      console.log(this.brands);
    },
    changeBrand(brand) {
      console.log(this.isOpenQuote);
      if (this.isOpenQuote) {
        this.$router.push({ name: "CarImgBrand", params: { id: brand.id } });
      } else {
        this.$emit("changeBrand", brand);
      }

      console.log("---> ", brand);
    },
  },
};
</script>

<style lang="scss" scoped>
.brandselect_left {
  position: absolute;
  z-index: 1;
  .letterlst {
    background-color: #333;
    width: 30px;
    text-align: center;
    height: 800px;
    a {
      color: #fff;
      display: block;
      width: 30px;
    }
  }
  ul.letter_con {
    width: 250px;
    height: 800px;
    overflow-y: auto;
    margin-bottom: 0;
    li {
      &:nth-child(odd) {
        background: url("../assets/images/bjbg1.jpeg") no-repeat;
      }
      &:nth-child(even) {
        background: url("../assets/images/bjbg2.jpeg") no-repeat;
      }
      .letterli {
        width: 250px;
        height: 150px;
        background-color: rgba(213, 28, 19, 0.7);
        color: #fff;
        text-align: center;
        font-size: 69px;
        line-height: 150px;
        cursor: pointer;
        transition: background-color 0.4s;
      }

      .letterli_2 {
        padding: 25px 10px 30px 15px;
        width: 250px;
        background-color: #333;
        color: #fff;
        box-sizing: border-box;
        margin-bottom: 0;
        .letterli_name.active {
          span:first-child {
            font-size: 16px;
          }
        }
        dt {
          font-size: 30px;
          padding-right: 15px;
          width: 65px;
          box-sizing: border-box;
        }

        dd {
          width: 180px;
          .zm-letterli2 {
            cursor: pointer;
            line-height: 36px;
            position: relative;
            box-sizing: border-box;
          }
        }
      }

      .letterli_3 {
        background-color: rgba(51, 51, 51, 0.8);
        position: absolute;
        // left: 100%;
        top: 0;
        left: 175px;
        box-sizing: border-box;
        padding: 10px 15px;
        z-index: 2;
        max-height: 550px;
        overflow: auto;

        a {
          color: #fff;
          display: block;
          line-height: 36px;
          min-width: 165px;
          width: auto;
        }
      }
    }
  }
}

.flexC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
