<template>
  <div class="intro-nav">
    <dl class="dl1">
      <dt>关于我们</dt>
      <dd>
        <router-link
          :to="{ name: 'AboutUs' }"
          :class="current == 'aboutus' ? 'on' : ''"
        >
          晟行汽车简介
        </router-link>
      </dd>
      <dd>
        <router-link
          :to="{ name: 'ContactUS' }"
          :class="current == 'contactus' ? 'on' : ''"
        >
          联系我们
        </router-link>
      </dd>
      <dd>
        <router-link
          :to="{ name: 'Agreement' }"
          :class="current == 'agreement' ? 'on' : ''"
        >
          服务协议
        </router-link>
      </dd>
    </dl>
    <dl class="dl1">
      <dt>特卖指南</dt>
      <dd>
        <router-link
          :to="{ name: 'SaleFlow' }"
          :class="current == 'saleflow' ? 'on' : ''"
        >
          特卖流程
        </router-link>
      </dd>
      <dd>
        <router-link
          :to="{ name: 'Question' }"
          :class="current == 'question' ? 'on' : ''"
        >
          常见问题
        </router-link>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: "IntroNav",
  data() {
    return {};
  },
  props: {
    current: {
      type: String,
      default: "",
      required: true,
    },
  },
  mounted() {},
  computed: {},
  methods: {
    back() {
      this.$route.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-nav {
  dl.dl1 {
    border-top: none;
    padding-top: 0;
    margin-bottom: 30px;
    dt {
      font-weight: 700;
      line-height: 1em;
      border-left: 4px solid #dd2a2a;
      padding-left: 10px;
      margin-bottom: 10px;
      color: #333;
      font-size: 16px;
    }

    dd {
      padding: 2px 10px;
      a {
        font-size: 14px;
        color: #777;
        position: relative;
      }
      a.on {
        color: #dd2a2a;
      }
    }
  }
}
</style>
