<template>
  <div class="container">
    <PageHeader current="newcarnews" />
    <div class="main-container">
      <div class="w1200">
        <a-breadcrumb separator=">" class="w-breadcrumb">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 新车 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="w1200 news__box">
        <div class="box-left">
          <div class="newsColumn" style="min-height: 503px">
            <div class="swiper-container" style="cursor: -webkit-grab"></div>

            <div class="subNews">
              <!--新闻列表-->
              <ul class="result-list on">
                <a-skeleton
                  :loading="loading.news"
                  active
                  :paragraph="{ rows: 26 }"
                  :title="false"
                >
                  <li
                    class="result-item"
                    v-for="item in news"
                    v-bind:key="item.id"
                  >
                    <router-link
                      :to="{ name: 'NewsDetail', params: { id: item.id } }"
                    >
                      <img :src="item.img_url"
                    /></router-link>
                    <div>
                      <h4>
                        <router-link
                          :to="{ name: 'NewsDetail', params: { id: item.id } }"
                          >{{ item.title }}</router-link
                        >
                      </h4>
                      <p>
                        {{ item.summary }}
                      </p>
                      <label class="carTags">
                        <span>{{ item.sub_classify }}</span
                        ><span>{{ item.published_at }}</span>
                      </label>
                    </div>
                  </li>
                </a-skeleton>
              </ul>
              <!-- 分页 -->
            </div>
            <div></div>
          </div>
        </div>
        <div class="box-right">
          <div class="hotChoice hotChoiceNum">
            <h5>热门精选</h5>
            <!--热门精选（调取本周内发布的文章，访问量最高的十条。）-->

            <div class="choiceCnt">
              <a-skeleton
                :loading="loading.hot"
                active
                :paragraph="{ rows: 12 }"
                :title="false"
              >
                <p v-for="(item, i) in hotNews" v-bind:key="item.id">
                  <router-link
                    :to="{ name: 'NewsDetail', params: { id: item.id } }"
                    ><span :class="i < 3 ? 'on' : ''">{{ i + 1 }}. </span
                    >{{ item.title }}</router-link
                  >
                </p>
              </a-skeleton>
            </div>
          </div>
          <div class="benefit-info">
            <h4 class="m-title">特惠品牌</h4>
            <ul id="detailAct">
              <li>
                <router-link :to="{ name: 'Cars', query: { bid: 74 } }"
                  ><img src="@/assets/images/dz.jpg" />
                </router-link>
              </li>
            </ul>
          </div>
          <!-- 热门搜索-->
          <div class="hot-search">
            <h4 class="m-title">热门搜索</h4>
            <ul id="hot-search">
              <li v-for="kw in keywords" v-bind:key="kw">
                <router-link :to="{ name: 'Search', query: { keyword: kw } }">
                  {{ kw }}
                </router-link>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getAllNews } from "@/api/news";
import { getCommonRankNews } from "@/api/common";
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";

export default {
  name: "Index",
  components: {
    PageHeader,
    Footer,
  },
  data() {
    return {
      news: [],
      keywords: [
        "东风",
        "伊兰特",
        "吉利",
        "宝马",
        "现代",
        "日产",
        "丰田",
        "长安",
        "欧蓝德",
        "奥迪",
        "大众",
        "奔驰",
        "雅阁",
      ],
      loading: {
        news: true,
        hot: true,
      },
      hotNews: [],
      classify: [
        {
          classify: 1,
          name: "头条资讯",
        },
        {
          classify: 2,
          name: "新车资讯",
        },
        {
          classify: 3,
          name: "导购精选",
        },
        {
          classify: 4,
          name: "试驾评测",
        },
        {
          classify: 5,
          name: "日常新闻",
        },
      ],
      currentClassify: 0,
      page:1,
      limit:50
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    console.log(this.$route.query.classify);
    if (
      this.$route.query.classify !== undefined &&
      this.$route.query.classify !== ""
    ) {
      this.currentClassify = this.$route.query.classify;
    }
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getAllNews({
        page: this.page,
        limit: this.limit,
        classify: this.currentClassify,
        isNewCar:true
      }).then((response) => {
        this.news = response.data;
        this.loading.news = false;
      });

      getCommonRankNews().then((resp) => {
        this.hotNews = resp.data;
        this.loading.hot = false;
      });
    },
    changeClassify(item) {
      this.currentClassify = item.classify;
      this.loading.news = true;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w-breadcrumb {
  margin: 15px 0;
}
.news__box {
  margin-top: 20px;
  .box-left {
    width: 830px;
    position: relative;
    float: left;
    .newsColumn {
      float: left;
      width: 830px;
      // padding-left: 170px;
    }
    .leftSideTab {
      width: 150px;
      margin-right: 20px;
      position: absolute;
      left: 0;
      top: 20px;
      background: #f2f4fc;
      padding: 20px 0 20px 12px;

      float: left;
      h5 {
        font-size: 16px;
        color: #77777a;
        font-weight: bold;
        line-height: 1.5;
        padding: 16px 17px 8px 17px;
      }
      div.on {
        background: #fff url(../assets/sideTabBg.png) no-repeat right 17px top
          20px !important;
        color: #f54a45;
      }
      div {
        font-size: 16px;
        color: #77777a;
        // padding: 16px 17px;
        line-height: 1;
        cursor: pointer;
        margin-bottom: 2px;
        border-radius: 4px 0 0 4px;
      }
    }

    .subNews {
      .result-list.on {
        display: block;
        .result-item {
          padding: 20px 0;
          border-bottom: 1px solid #e1e2eb;
          overflow: hidden;
          & > a {
            width: 200px;
            height: 133px;
            float: left;
            img {
              width: 100%;
              height: 100%;
            }
          }
          & > div {
            width: 600px;
            height: 133px;
            position: relative;
            float: right;
            h4 {
              font-size: 18px;

              line-height: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: normal;
              color: #3b5998;
              a {
                color: #3b5998;
              }
            }
            p {
              font-size: 14px;
              -webkit-line-clamp: 2;
              height: 3em;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 1.5;
              color: #76767a;
            }
            .carTags {
              position: absolute;
              bottom: 0;
              left: 0;
              font-size: 14px;
              color: #b0b1b8;
              span {
                margin-right: 24px;
              }
            }
          }
        }
      }
    }
  }

  .box-right {
    width: 330px;
    float: right;
    .benefit-info {
      margin-bottom: 40px;
      width: 100%;
      margin-top: 20px;
      .m-title {
        font-size: 18px;
        font-weight: bold;
      }

      ul {
        li {
          margin: 20px 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .hotChoice {
      h5 {
        font-size: 18px;
        color: #3b3b3d;
        padding-bottom: 10px;
        font-weight: bold;
        margin-top: 20px;
      }

      .choiceCnt {
        overflow: hidden;
        p {
          a {
            color: #76767a;
          }
          font-size: 14px;
          color: #76767a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 10px;
          span.on {
            color: #f54a45;
            font-style: normal;
            margin-right: 5px;
          }
        }
      }
    }

    .hot-search {
      .m-title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 40px;
        padding-left: 0;
        border-left: none;
        color: #3b3b3d;
        margin-bottom: 20px;
        line-height: 24px;
      }

      & > ul {
        overflow: hidden;
        li {
          font-size: 14px;
          color: #76767a;
          white-space: nowrap;
          border: none;
          padding-left: 0;
          padding-top: 0;
          padding-bottom: 0;
          float: left;
          border: none;
          margin-right: 8px;
          margin-bottom: 10px;
          cursor: pointer;
          display: block;
          border-radius: 4px;
          padding-right: 8px;
        }
      }
    }
  }
}
</style>

