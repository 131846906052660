<template>
  <div class="container">
    <DealerHeader current="series" />
    <div class="main-container">
      <div class="w1200">
        <div style="padding: 20px 0 30px">当前位置： <span>车型报价</span></div>
      </div>
      <div class="w1200 dcs-warp">
        <div class="fl main_brand_box">
          <div class="main_brand">
            <div class="jxsindex_title">主营品牌</div>
            <ul class="main_brand_item_warp">
              <li
                class="clearFix main_brand_item"
                v-for="brand in brands"
                :key="brand.id"
              >
                <span class="fl" style="width: 66px; height: 66px">
                  <img
                    :src="brand.logo_url"
                    class="scrollLoading lazy"
                    width="66"
                    height="66"
                  />
                </span>
                <div class="main_brandinfor fl">
                  <h2>{{ brand.name }}</h2>
                  <ul class="">
                    <li
                      :class="s.id == seriesId ? 'selection' : ''"
                      v-for="s in brand.series"
                      :key="s.id"
                      style="margin-bottom: 5px"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="changeSeries(s.id)"
                        >{{ s.name }}</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="fr dynamic_box">
          <a-skeleton
            :loading="loading.series"
            active
            :paragraph="{ rows: 15 }"
          >
            <div class="jxsindex_title_r clearFix" style="">
              {{ series.brand.name }}
            </div>
            <div class="pop_models">
              <ul>
                <li class="pop_models_item">
                  <div class="clearFix">
                    <div class="popcarimg fl">
                      <img :src="series.cover_url" />
                    </div>
                    <div class="fl popcar_infor">
                      <h2>{{ series.name }}</h2>
                      <p class="">
                        指导价：<span class="redcolor price">{{
                          series.price
                        }}</span>
                      </p>
                      <p>
                        级<span style="color: #fff">一</span>别：<span>{{
                          series.grade
                        }}</span>
                      </p>
                      <p id="fdj">
                        发动机：<span>{{ series.engine }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="popcarinfor">
                    <table
                      style="width: 100%; margin-bottom: 20px"
                      v-for="model in models"
                      :key="model.group"
                    >
                      <tbody>
                        <tr style="background-color: #dce4ff">
                          <th>{{ model.group }}</th>
                          <th>指导价</th>
                          <th></th>
                        </tr>

                        <tr v-for="item in model.list" :key="item.id">
                          <td class="carname">
                            {{ item.year }}款 {{ item.name }} 国VI
                          </td>
                          <td>{{ item.guide_price }}</td>
                          <td>
                            <router-link
                              :to="{
                                name: 'DealerQuote',
                                query: {
                                  bid: series.brand.id,
                                  sid: series.id,
                                },
                              }"
                              class="askprice"
                            >
                              询价/试驾
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
          </a-skeleton>
        </div>
      </div>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import DealerHeader from "@/components/dealer/DealerHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import { getDealerSeries } from "@/api/dealer";
import { getSeriesById } from "@/api/car";

export default {
  name: "DealerSeries",
  components: {
    DealerHeader,
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      id: 0,
      seriesId: 0,
      brands: [],
      series: { brand: {} },
      models: [],
      loading: {
        brand: true,
        series: true,
      },
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    let seriesId = this.$route.query.seriesId;
    if (seriesId != undefined && seriesId != "") {
      this.seriesId = parseInt(seriesId);
      this.getSeriesData();
    }
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getDealerSeries(this.id).then((resp) => {
        this.brands = resp.data;
        if (this.seriesId == 0) {
          this.seriesId = this.brands[0]["series"][0].id;
          this.getSeriesData();
        }
      });
    },
    getSeriesData() {
      this.models = [];
      this.loading.series = true;
      getSeriesById(this.seriesId).then((resp) => {
        this.series = resp.data;
        this.models = resp.data.models;
        this.loading.series = false;
      });
    },
    changeSeries(sid) {
      this.seriesId = sid;
      this.models = [];
      this.getSeriesData();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dcs-warp {
  .main_brand_box {
    .main_brand {
      width: 300px;
      border: solid 1px #c6c6c6;
      min-height: 280px;
      overflow: hidden;
      .jxsindex_title {
        padding-left: 25px;
        font-size: 20px;
        line-height: 34px;
        height: 34px;
        margin: 10px auto;
        color: #333;
        letter-spacing: 0px;
      }
      .main_brand_item_warp {
        .main_brand_item {
          padding: 10px 25px;
          border-bottom: 1px solid #dadada;
          overflow: hidden;

          &:last-child {
            border-bottom: none;
          }

          .main_brandinfor {
            padding-left: 15px;
            h2 {
              font-size: 16px;
            }

            ul {
              li {
                border-bottom: none;
                padding: 0;
                cursor: pointer;
                a {
                  color: #333;
                }
              }
              li.selection {
                a {
                  color: #dd2a2a;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dynamic_box {
  width: 874px;
  box-sizing: border-box;
  .pop_models {
    border: 1px solid #dadada;
    .pop_models_item {
      padding: 20px;
      .popcarimg {
        text-align: left;
        padding: 5px;
        img {
          width: 230px;
          height: 153px;
        }
      }

      .popcar_infor {
        padding: 4px 0 0 50px;
        h2 {
          font-size: 24px;
          line-height: 46px;
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          line-height: 32px;
          margin-bottom: 0;
        }

        span.price {
          font-size: 20px;
          line-height: 34px;
        }
      }

      .popcarinfor {
        padding: 5px;
        a.askprice {
          border-radius: 5px;
          font-size: 14px;
          background-color: #a32222;
          color: #fff;
          line-height: 32px;
          padding: 0 10px;
          display: inline-block;
          cursor: pointer;
        }
        table {
          tr {
            line-height: 45px;
            color: #6b6b6b;
            font-size: 18px;
            td {
              padding-right: 30px;
              padding-left: 20px;
              color: #666;
              font-size: 14px;
              line-height: 50px;
              height: 50px;
            }
            td.carname {
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            th {
              font-size: 16px;
              color: #333;
              padding-left: 20px;
              line-height: 50px;
              height: 50px;
            }
          }

          tr:nth-of-type(odd) {
            background: #ecf0ff;
          }
        }
      }
    }
  }
}
.redcolor {
  color: #e02b31;
}
.jxsindex_title_r {
  padding-left: 20px;
  font-size: 24px;
  background-color: #a32222;
  color: #fff;
  line-height: 60px;
  height: 60px;
}
</style>

