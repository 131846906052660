<template>
  <div class="container">
    <DealerHeader current="news" />
    <div class="main-container">
      <!-- 面包屑 -->
      <div class="w1200">
        <div style="padding: 20px 0 35px">
          当前位置：
          <router-link :to="{ name: 'DealerIndex' }">店铺首页</router-link>
          &gt;
          <router-link :to="{ name: 'DealerNews' }">店内动态</router-link>
          &gt;
          <span>动态详情</span>
        </div>
      </div>

      <!-- 公司简介 -->
      <div class="w1200 clearFix about-container">
        <div class="fl" style="width: 890px">
          <div class="about_company">
            <h2 class="company_name">
              {{ news.title }}
              <p style="text-align: center">
                <span style="font-size: 16px; font-weight: normal"
                  >来源：<span style="color:#777;">{{dealer.name}}</span></span
                >&nbsp;&nbsp;
                <span style="font-size: 16px; font-weight: normal"
                  >时间：<span style="color:#777;">{{ news.published_at }}</span></span
                >&nbsp;&nbsp;
                <span style="font-size: 16px; font-weight: normal"
                  >点击：<span style="color:#777;">{{ parseInt(news.pv) + 5000 }}</span></span
                >
              </p>
            </h2>
            <div v-html="news.content"></div>
          </div>
        </div>
        <div class="fr main_brand_box">
          <!-- 主营品牌 -->
          <DealerMainBrand />
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import DealerHeader from "@/components/dealer/DealerHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import DealerMainBrand from "@/components/dealer/MainBrand";

import { getDealerInfo, getDealerNewsById } from "@/api/dealer";

export default {
  name: "Index",
  components: {
    DealerHeader,
    ServiceIntro,
    Footer,
    DealerMainBrand,
  },
  data() {
    return {
      id: 0,
      nid: 0,
      dealer: {},
      series: [],
      news: {},
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.nid = this.$route.params.nid;
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getDealerInfo(this.id).then((resp) => {
        this.dealer = resp.data;
      });

      getDealerNewsById(this.nid).then((resp) => {
        this.news = resp.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.about_company {
  padding: 25px;
  width: 870px;
  box-shadow: 1px 2px 6px 0px rgba(102, 99, 94, 0.35);
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  line-height: 32px;
  h2.company_name {
    font-size: 22px;
    color: #333;
    margin: 5px 0px 30px;
    text-align: center;
    border-bottom: 1px solid #c6c6c6;
    padding-bottom: 20px;
  }
}
.about-container {
  min-height: 350px;
}
</style>

