var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scar-container w1200"},[_c('ul',{staticClass:"flexL scar__options"},[_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('1')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') 15px 8px no-repeat',
        })}),_c('p',[_vm._v("微型车")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('2')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -69px 8px no-repeat',
        })}),_c('p',[_vm._v("小型车")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('3')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -241px 8px no-repeat',
        })}),_c('p',[_vm._v("紧凑型")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('4')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -241px 8px no-repeat',
        })}),_c('p',[_vm._v("中型车")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('5')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -327px 8px no-repeat',
        })}),_c('p',[_vm._v("中大型")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('6')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -411px 8px no-repeat',
        })}),_c('p',[_vm._v("大型车")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('6')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -837px 8px no-repeat',
        })}),_c('p',[_vm._v("超大型")])]),_c('li',{staticClass:"flexR resultSUV",on:{"click":function($event){return _vm.goSelectCar('7,8,9,10,11')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -500px 8px no-repeat',
        })}),_c('p',[_vm._v("SUV")])]),_c('li',{staticClass:"flexR resultSUV",on:{"click":function($event){return _vm.goSelectCar('12,13,14,15')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -587px 8px no-repeat',
        })}),_c('p',[_vm._v("MPV")]),_c('div',{staticClass:"resultNav2",staticStyle:{"left":"auto","right":"undefinedpx","min-with":"80px"}})]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('16')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -674px 8px no-repeat',
        })}),_c('p',[_vm._v("跑车")])]),_c('li',{staticClass:"flexR",on:{"click":function($event){return _vm.goSelectCar('100')}}},[_c('span',{style:({
          background:
            'url(' +
            require('@/assets/carIconImg.png') +
            ') -760px 8px no-repeat',
        })}),_c('p',[_vm._v("新能源")])])]),_c('div',{staticClass:"scar__recommend"},[_c('ul',{staticClass:"scar__recommend__ul"},_vm._l((_vm.cars),function(item,i){return _c('li',{key:i,staticClass:"flexBC"},[_c('div',{staticClass:"flexBC scar__recommend__level"},[_c('label',[_vm._v(_vm._s(item.level_name))]),_c('div',[_c('a-icon',{attrs:{"type":"right-circle"}})],1)]),_c('div',{staticClass:"flexL scar__recommend__series"},_vm._l((item.list),function(series){return _c('span',{key:series.id,staticClass:"scar-name__item"},[_c('router-link',{attrs:{"to":{ name: 'Series', params: { id: series.id } }}},[_vm._v(_vm._s(series.name)+" ")])],1)}),0),_c('router-link',{attrs:{"to":{ name: 'Cars', query: { level: item.level_id } }}},[_c('div',{staticClass:"classArrows"},[_vm._v("更多"),_c('a-icon',{attrs:{"type":"right"}})],1)])],1)}),0),_c('ul',{staticClass:"flexBC scar_recommend__brands",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.brands),function(item){return _c('li',{key:item.id},[_c('router-link',{staticClass:"flexR",attrs:{"to":{ name: 'Cars', query: { bid: item.id } }}},[_c('img',{attrs:{"src":item.logo_url}}),_c('p',[_vm._v(_vm._s(item.name))])])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }