<template>
  <div class="container">
    <PageHeader current="groupon" :headerCityChange="headerCityChange" />
    <div class="main-container">
      <div class="w1200" style="width: 980px">
        <a-breadcrumb separator=">" class="w-breadcrumb" style="margin: 15px 0">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Groupon' }">团购活动</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 详情 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="detail-wrapper">
        <a-skeleton
          :loading="loading"
          active
          :paragraph="{ rows: 12 }"
          :title="false"
        >
          <div class="contentbuy-box">
            <div class="title clearfix">
              <div class="logo fl">
                <img :src="groupon.brand.logo_url" width="50" height="50" />
              </div>
              <div class="des fl">
                <h1 id="_m">
                  【{{ cur_city }}】{{ groupon.title }}【{{
                    groupon.start_date
                  }}-{{ groupon.end_date }}】
                </h1>
                <p></p>
              </div>
            </div>
            <div class="contentbuy-list clearfix">
              <div class="fl pic pic1">
                <img :src="cur_series.img_url" width="100%" />
                <div class="pic-bottom">
                  <p>
                    <strong>活动时间：</strong
                    ><span class="J_date"
                      >{{ groupon.start_date }}-{{ groupon.end_date }}</span
                    >
                  </p>
                  <p>
                    <strong>活动地点：</strong
                    ><span class="J_address">{{ groupon.address }}</span>
                  </p>
                  <div class="box-hover">
                    <p><strong>活动价格：</strong>现场公布</p>
                  </div>
                  <!-- <p class="countdown-time">
                    <strong>倒&nbsp;计&nbsp;时：</strong>
                    <span
                      class="time J_countdown"
                      ><a-statistic-countdown :value="deadline" format="D 天 H 时 m 分 s 秒" /></span
                    >
                  </p> -->
                </div>
              </div>
              <div class="mod-enrollbuy mod-enrollbuy2 fr">
                <div class="enrollbuy-box">
                  <div class="fast-top clearfix">
                    <p class="p1 fl">
                      本期询价：<span class="span1">{{
                        groupon.current_people
                      }}</span
                      >人
                    </p>
                    <p class="p2 fl">
                      累计询价：<span class="span1">{{
                        groupon.total_people
                      }}</span
                      >人
                    </p>
                    <p class="fl">
                      好评率：<span class="span1">{{ groupon.zan_rate }}%</span>
                    </p>
                  </div>
                  <div class="fast-top clearfix">
                    <p class="padding-t">
                      <strong>团购保障：</strong>
                      <span><i class="g-icon"></i>低价保证</span>
                      <span><i class="g-icon"></i>车源保证</span>
                      <span><i class="g-icon"></i>完全免费</span>
                      <span><i class="g-icon"></i>优先提车</span>
                    </p>
                  </div>
                  <div class="fast-top last g-mt20 clearfix">
                    <div class="clearfix">
                      <span class="span2 fl">选择车型：</span>
                      <div
                        class="car-style J_car-style fl clearfix"
                        style="
                          min-height: 46px;
                          overflow: hidden;
                          display: block;
                        "
                      >
                        <a
                          :class="item.id == cur_sid ? 'on show' : 'show'"
                          style="display: inline-block"
                          href="javascript:voide(0);"
                          v-for="(item, i) in series"
                          :key="item.id"
                          @click="changeSeries(i)"
                          >{{ item.name
                          }}<i class="g-icon" v-if="item.id == cur_sid"></i
                        ></a>
                      </div>
                    </div>
                    <!-- <p class="color-p fl J_colorP">
                    已选择<span>兰德酷路泽中东版</span>，尽快询价，即刻了解最新优惠！
                  </p> -->
                  </div>
                  <div class="fast-box g-pt10">
                    <a-form
                      :form="form"
                      :label-col="{ span: 3 }"
                      :wrapper-col="{ span: 20 }"
                      @submit="handleSubmit"
                    >
                      <a-form-item label="您的姓名">
                        <a-input
                          size="large"
                          class="top-input"
                          labelAlign="left"
                          placeholder="如张先生(女士)"
                          v-decorator="[
                            'name',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Please input your note!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                      <a-form-item label="您的手机">
                        <a-input
                          size="large"
                          class="top-input"
                          labelAlign="left"
                          placeholder="请填写11位数的手机号码"
                          v-decorator="[
                            'phone',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: 'Please input your note!',
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                      <a-form-item :wrapper-col="{ span: 20, offset: 3 }">
                        <a-button
                          type="primary"
                          html-type="submit"
                          class="submit-btn"
                          :loading="submitLoading"
                        >
                          获取团购价
                        </a-button>
                      </a-form-item>
                    </a-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-skeleton>
      </div>

      <div class="group-wrapper">
        <div class="groups-l fl" id="J_groupsTab">
          <div class="mod-theme">
            <div class="mod-title"><h3>最新询价</h3></div>
            <div class="theme-box">
              <div
                class="side-c clearfix change-bg"
                id="J_infoAnimateList"
                style="position: relative"
              >
                <a-skeleton
                  :loading="askLoading"
                  active
                  :paragraph="{ rows: 8 }"
                  :title="false"
                >
                  <div class="list-box" style="position: relative">
                    <dl style="width: 100%">
                      <dd
                        class="clearfix bg-color"
                        style=""
                        v-for="(item, i) in askPeoples"
                        :key="i"
                      >
                        <div
                          class="item fl"
                          v-for="people in item"
                          :key="people.phone"
                        >
                          <span class="name"> {{ people.name }} </span>
                          <span class="tel">{{ people.phone }}</span>

                          <router-link
                            :to="{ name: 'Series', params: { id: people.sid } }"
                            class="style"
                            >{{ people.sname }}
                          </router-link>

                          <span class="place">{{ cur_city }}</span>
                          <span class="t-text"
                            >{{ people.diff_min }}分钟前</span
                          >
                        </div>
                      </dd>
                    </dl>
                  </div>
                </a-skeleton>
              </div>
            </div>
          </div>
          <div class="mod-style g-mt10">
            <div class="mod-title"><h3>大众 - 团购车型</h3></div>
            <div class="style-box">
              <div class="style-title clearfix">
                <span class="w180 name">车型</span>
                <span class="w80">热度</span>
                <span class="w100">指导价</span>
                <span class="w100">市场优惠</span>
                <span class="w100">本期询价</span>
                <span class="w120">咨询</span>
              </div>
              <ul>
                <li class="clearfix" v-for="(item, i) in series" :key="item.id">
                  <span class="w180 name">
                    <router-link
                      :to="{ name: 'Series', params: { id: item.id } }"
                      class="clearfix"
                    >
                      <div class="pic">
                        <img :src="item.img_url" width="74" height="56" />
                      </div>
                      <div class="name-car">{{ item.name }}</div>
                    </router-link>
                  </span>
                  <span class="w80">
                    <div class="follow-bar">
                      <a-progress
                        :percent="item.heat"
                        strokeColor="#ff4200"
                        :show-info="false"
                      />
                    </div>
                  </span>
                  <span class="w100 through">{{ item.price }}</span>
                  <span class="w100" v-if="item.discount == '到店面议'">{{
                    item.discount
                  }}</span>
                  <span class="w100 green" v-else
                    ><i class="g-icon"></i>{{ item.discount }}</span
                  >
                  <span class="w100"
                    ><div class="current">
                      <em>{{ item.people }}</em
                      >人已询价
                    </div></span
                  >
                  <span class="w120 btn"
                    ><a
                      href="javascript:;"
                      class="J_buyPriceNews mg-r"
                      @click="changeSeries(i, true)"
                      >团购价</a
                    ></span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="mod-policie g-mt10">
            <div class="mod-title"><h3>活动亮点</h3></div>
            <div class="policie-text">
              <ul class="clearfix list-paddingleft-2">
                <li>
                  <p><span>厂家让利</span>经销商冲量询价更优惠！</p>
                </li>
                <li>
                  <p><span>询价享利</span>专员及时回访解答，省时省力省心！</p>
                </li>
                <li>
                  <p><span>车源保障</span>优先提车，信誉可靠，杜绝车财两空！</p>
                </li>
                <li>
                  <p>
                    <span>现场看车</span
                    >解答车牌、车险、车型、保养、VIP式购车体验！
                  </p>
                </li>
                <li>
                  <p>
                    <span>好车标准</span
                    >车厂直提；依法享受“三包”待遇；支持全国联保！
                  </p>
                </li>
                <li>
                  <p>
                    <span>分期购车</span>低首付、低月供、灵活还款、购车无负担！
                  </p>
                </li>
              </ul>
            </div>
            <div class="policie-bottom">
              保证：本次活动承诺不收取任何费用，不泄露用户信息！
            </div>
          </div>
        </div>
        <div class="groups-r fr">
          <div class="mod-interested">
            <div class="mod-title">
              <h3>{{ cur_city }}热门团购会</h3>
            </div>
            <ul>
              <li v-for="item in hots" :key="item.id">
                <router-link
                  :to="{ name: 'GrouponDetail', params: { id: item.id } }"
                >
                  <div class="pic">
                    <img
                      width="100%"
                      :src="item.cover_url"
                      style="width: 230px; height: 183px"
                    />
                  </div>
                  <h3>{{ item.title }}</h3>
                  <p>
                    本期已询价<em>{{ item.current_people }}</em
                    >人
                  </p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import { scrollTo } from "@/utils/scroll-to";
import { getAskpricePeoples, getGrouponById, getGroupons } from "@/api/groupon";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
  },
  route: {
    canReuse: false,
  },
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      askPeoples: [],
      cur_city: "北京",
      cur_cid: 100,
      id: 0,
      groupon: { brand: {} },
      series: [],
      cur_series: {},
      cur_sid: 0,
      hots: [],
      loading: true,
      askLoading: true,
      submitLoading: false,
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getCityId();
    this.getData();
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.id = this.$route.params.id;
      this.getData(true);
    },
  },
  beforeDestroy() {},
  methods: {
    getData(isScroll) {
      this.loading = true;
      this.askLoading = true;
      getAskpricePeoples().then((resp) => {
        this.askPeoples = resp.data;
        this.askLoading = false;
      });
      getGrouponById(this.id).then((resp) => {
        this.groupon = resp.data;
        this.series = resp.data.series;
        this.cur_sid = this.series[0].id;
        this.cur_series = this.series[0];
        this.loading = false;
        this.deadline = new Date() + this.groupon.diff_seconds * 1000;
      });
      getGroupons({ cid: this.cur_cid, limit: 7 }).then((resp) => {
        let defaultImg = "https://img.shengxingdt.com/default/defaultCar.jpeg";
        resp.data.map((item) => {
          item.cover_url = defaultImg;
          if (item.series.length != 0) {
            item.cover_url = item.series[0].img_url;
          }

          if (item.id != this.id && this.hots.length < 7) {
            this.hots.push(item);
          }
        });
      });

      if (isScroll) {
        scrollTo(0, 500);
      }
    },
    changeSeries(index, isScroll) {
      this.cur_sid = this.series[index].id;
      this.cur_series = this.series[index];
      if (isScroll) {
        scrollTo(0, 500);
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      const h = this.$createElement;
      this.form.validateFields((err) => {
        if (!err) {
          var name = this.form.getFieldValue("name");
          var phone = this.form.getFieldValue("phone");
          if (!name) {
            return that.$message.warning("请填写姓名");
          }
          if (!phone) {
            return that.$message.warning("请填写 11 位手机号");
          }

          if (!/^1[345789]\d{9}$/.test(phone)) {
            return that.$message.warning(
              "手机号格式错误，请填写正确的手机号码"
            );
          }

          if (that.submitLoading) {
            return;
          }
          that.submitLoading = true;
          let sec = 500 + parseInt(Math.random() * 500);
          setTimeout(() => {
            that.$success({
              title: "提交成功",
              content: h("div", {}, [h("p", "稍后我们工作人员会联系您")]),
            });
            that.submitLoading = false
          }, sec);
        }
      });
    },
    getCityId() {
      let curCity = localStorage.getItem("_cur_city");
      if (curCity) {
        let curCityData = JSON.parse(curCity);
        this.cur_cid = curCityData.c_id;
        this.cur_city = curCityData.c;
        return curCityData.c_id;
      }

      return 100;
    },
    headerCityChange() {
      this.$router.push({
        name: 'Groupon'
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.main-container {
  background-color: #f5f5f5;
}
.detail-wrapper {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  .contentbuy-box {
    background-color: #fff;
    .title {
      background-color: #fff;
      border-bottom: 0;
      padding: 15px 20px;
      position: relative;
      .logo {
        width: 50px;
        height: 50px;
        img {
          vertical-align: top;
        }
      }
      .des {
        h1 {
          font-size: 24px;
          color: #333;
          margin-left: 15px;
          line-height: 50px;
        }
      }
    }

    .contentbuy-list {
      border-top: 1px solid #e4e4e4;
      padding: 16px 0 12px 20px;
      overflow: hidden;
      .pic {
        width: 410px;
        margin-top: -16px;
        img {
          display: block;
          padding: 14px 0;
        }

        .pic-bottom {
          color: #333;
          margin-top: -10px;
          p {
            padding: 2px 0;
            margin-bottom: 0;
          }
        }
      }

      .mod-enrollbuy {
        width: 520px;
        .fast-top {
          color: #333;
          border-bottom: 1px dotted #ddd;
          padding-bottom: 12px;
          padding-right: 20px;
          position: relative;
          .padding-t {
            padding-top: 14px;
            span {
              padding-right: 22px;
              i {
                background-position: -186px -211px;
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 4px;
                position: relative;
                top: 3px;
                left: 0;
              }
            }
          }
          .car-style {
            width: 430px;
            overflow: hidden;
            a {
              display: inline-block;
              border: 1px solid #dcdcdc;
              padding: 0 7px;
              text-align: center;
              color: #333;
              margin-right: 8px;
              margin-bottom: 10px;
              position: relative;
              float: left;
              height: 32px;
              line-height: 28px;
            }
            a.on {
              border: 2px solid #ff4200;
              height: 32px;
              line-height: 28px;
              color: #ff4200;
              padding: 0 6px;
              i {
                background-position: -185px -62px;
                width: 14px;
                height: 14px;
                display: inline-block;
                position: absolute;
                top: 15px;
                right: 0;
              }
            }
          }
          .span1 {
            font-weight: 500;
            color: #ff4200;
            font-size: 20px;
          }
          .span2 {
            display: block;
            height: 32px;
            line-height: 32px;
            font-weight: 700;
          }
          .p1 {
            font-weight: 700;
            width: 178px;
          }
          .p2 {
            color: #333;
            width: 198px;
          }
        }

        .fast-top.last {
          padding-right: 12px;
          margin-top: 15px;
        }
        .fast-box {
          margin-top: 8px;
          padding-right: 20px;
          li {
            margin-bottom: 14px;
            li span {
              width: 80px;
              color: #333;
              font-size: 14px;
              line-height: 40px;
              height: 40px;
              float: left;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
.groups-r {
  width: 270px;
  overflow: hidden;
}
.group-wrapper {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  .groups-l {
    width: 698px;
  }
}
.mod-theme {
  background: #fff;
  font-size: 16px;
}
.mod-title {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e3e3e3;
  border-left: 2px solid #ff4200;
  background-color: #fff;
  h3 {
    color: #333;
    font-size: 18px;
    padding-left: 18px;
    float: left;
    margin-bottom: 0px;
  }
}
.theme-box {
  border: 0;
  .side-c {
    .list-box {
      height: 220px;
      overflow: hidden;
      .item {
        width: 50%;
        padding-left: 15px;
      }
      dl {
        margin-bottom: 0;
        dd {
          height: 44px;
          color: #333;
          font-size: 14px;
          line-height: 44px;
          overflow: hidden;
          margin-bottom: 0;
          .name {
            width: 52px;
            // padding-left: 8px;
            overflow: hidden;
            float: left;
            // padding-left: 20px;
          }
          .tel {
            width: 70px;
            float: left;
          }

          .style {
            width: 84px;
            display: inline-block;
            color: #ff4200;
            overflow: hidden;
            float: left;
            height: 44px;
          }

          .place {
            width: 54px;
            display: inline-block;
            float: left;
          }
          .t-text {
            float: left;
            color: #999;
          }
        }
      }
    }
  }
}

.mod-style {
  background: #fff;
}

.style-title {
  height: 46px;
  background: #f9f9f9;
  line-height: 46px;
  span {
    // display: inline-block;
    float: left;
    text-align: center;
  }
  span.name {
    font-weight: 700;
    padding-left: 20px;
    text-align: left;
    color: #333;
  }
}
.w180 {
  width: 180px;
}
.w80 {
  width: 80px;
}
.w100 {
  width: 100px;
}
.w120 {
  width: 120px;
}
.style-box {
  font-size: 14px;
  span.green {
    i {
      position: relative;
      top: 0.5px;
      display: inline-block;
      margin-right: 3px;
      background-position: -288px -209px;
      width: 9px;
      height: 12px;
    }
  }
  li {
    line-height: 56px;
    height: 56px;
    .follow-bar {
      width: 40px;

      display: inline-block;
    }
    span.btn {
      a {
        display: inline-block;
        text-align: center;
        height: 32px;
        line-height: 32px;
        width: 76px;
        color: #fff;
        font-size: 14px;
        background-color: #ff4200;
        border-radius: 2px;
      }
    }
    span {
      height: 56px;
      overflow: hidden;
      float: left;
      color: #999;
      text-align: center;
      .current {
        color: #666;
        font-size: 14px;
        em {
          font-weight: 700;
          color: #ff4200;
          font-style: normal;
        }
      }

      a {
        .pic {
          display: inline-block;
          float: left;
          height: 56px;
        }

        .name-car {
          display: inline-block;
          line-height: 56px;
          float: left;
          height: 56px;
          overflow: hidden;
          width: 100px;
          margin-left: 5px;
          text-align: left;
        }
      }
    }

    span.through {
      text-decoration: line-through;
    }
  }
}

.g-mt10 {
  margin-top: 10px;
}
span.green {
  color: #4fb947 !important;
}

.g-icon {
  background: url("https://s.img16888.com/dist/images/buy4/icon.png") no-repeat
    0 0;
}

.mod-interested {
  li {
    position: relative;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #e3e3e3;
    overflow: hidden;
    background: #fff;
    a {
      display: block;
      padding-bottom: 10px;
    }
    .pic {
      margin: -15px 20px 0;
    }

    h3 {
      height: 22px;
      line-height: 22px;
      color: #333;
      font-weight: 700;
      overflow: hidden;
      font-size: 14px;
      margin-top: -10px;
      position: relative;
      z-index: 1;
    }

    p {
      color: #666;
      line-height: 24px;
      height: 24px;
      margin-bottom: 0;
      em {
        font-size: 16px;
        font-weight: 700;
        color: #ff4200;
        font-style: normal;
      }
    }
  }
}

.top-input {
  border: 2px solid #ff4200;
  border-radius: 2px;
  background: none;
  outline: none;
  margin-left: 5px;
}
input.top-input:focus {
  outline: none;
  box-shadow: none;
}
.submit-btn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background-color: #ff4f12;
  color: #fff;
  font-size: 22px;
  border-radius: 2px;
  border: 0;
  outline: 0;
  margin-left: 5px;
}

.mod-policie {
  background: #fff;
  .policie-text {
    padding: 0 20px;
    margin: 10px 0 4px;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    ul {
      font-size: 16px;
      color: #333;
      li {
        padding: 5px 0 5px 30px;
        background: url("https://s.img16888.com/dist/images/buy3/detail2.png")
          no-repeat -221px -2px;
        p {
          margin-bottom: 0%;
        }
        span {
          margin-right: 18px;
          font-weight: 700;
          color: #ff4200;
        }
      }
    }
  }

  .policie-bottom {
    padding: 0 20px;
    padding-bottom: 10px;
    line-height: 24px;
    font-size: 12px;
    color: #999;
  }
}
</style>

