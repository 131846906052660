<template>
  <div class="header">
    <div class="w1200 flexBC" style="margin-top: 15px">
      <div class="flexL">
        <div class="header__logo">
          <a href="/">
            <img src="@/assets/logo.png" alt="" />
          </a>
        </div>
        <div class="select-city">
          <div class="city_se_now flexR">
            <span class="select-city__now">{{ curCity }}</span>
            <a-icon
              type="down"
              class="city-select__icon"
              @click="changeCityVisiable(true)"
              v-if="!cityVisiable"
            />
            <a-icon
              type="up"
              class="city-select__icon"
              @click="changeCityVisiable(false)"
              v-if="cityVisiable"
            />
          </div>
          <div class="dds-box" v-show="cityVisiable">
            <ul id="changeCity">
              <li>
                <div class="add-list">
                  <span @click="changeCity(nationwide, nationwide.children[0])">全国</span>
                </div>
              </li>
              <li v-for="city in cities" v-bind:key="city.value">
                <div class="add-list-container">
                  <span style="display: inline-block; width: 100%"
                    ><a href="javascript:;" style="color: #333">{{
                      city.label
                    }}</a></span
                  >
                  <div class="add-list">
                    <span
                      v-for="c in city.children"
                      v-bind:key="c.value"
                      @click="changeCity(city, c)"
                      >{{ c.label }}</span
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="h-search">
        <div class="h-search__container flexL">
          <input
            autocomplete="off"
            class="h-search__text fl"
            placeholder="查找心仪车型"
            type="text"
            name="h-search__input"
            v-model="keyword"
          />
          <input
            class="h-search__submit"
            value="搜索"
            type="button"
            id="search_button"
            @click="search"
          />
        </div>
      </div>
      <div class="help-tel flexR" style="flex-direction: column">
        <!-- <p class="time">服务时段：周一至周日8:30~18:00</p>
        <p class="tel-no flexL">15117971875</p> -->
      </div>
    </div>
    <div class="h-nav">
      <div class="w1200 flexL">
        <div class="h-nav__container">
          <ul id="navitems-group1 flexL">
            <li :class="current === 'index' ? 'current' : ''">
              <router-link :to="{ name: 'Index' }">首页</router-link>
            </li>
            <li :class="current === 'news' ? 'current' : ''">
              <router-link :to="{ name: 'News' }">资讯</router-link>
            </li>
            <li :class="current === 'newcarnews' ? 'current' : ''">
              <router-link :to="{ name: 'NewCarNews' }">新车</router-link>
            </li>
            <li :class="current === 'cars' ? 'current' : ''">
              <router-link :to="{ name: 'Cars' }">特惠品牌</router-link>
            </li>
            <li :class="current === 'groupon' ? 'current' : ''">
              <router-link :to="{ name: 'Groupon' }">团购活动</router-link>
            </li>
            <li :class="current === 'quote' ? 'current' : ''">
              <router-link :to="{ name: 'QuotePage' }">报价</router-link>
            </li>
            <li :class="current === 'dealer' ? 'current' : ''">
              <router-link :to="{ name: 'Dealers' }">经销商</router-link>
            </li>
            <li :class="current === 'carimg' ? 'current' : ''">
              <router-link :to="{ name: 'CarImgs' }">车图</router-link>
            </li>
            <li :class="current === 'aboutus' ? 'current' : ''">
              <router-link :to="{ name: 'AboutUs' }">关于我们</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityData from "@/data/city";
import { getRegionFromIP } from "@/api/region";
export default {
  name: "PageHeader",
  data() {
    return {
      keyword: "",
      cityVisiable: false,
      cities: cityData,
      curCity: "加载中",
      nationwide: {
        label: "全国",
        value: 0,
        children: [
          {
            label: "全国",
            value: 0,
          },
        ],
      },
    };
  },
  props: {
    current: {
      type: String,
      default: "",
      required: false,
    },
    headerCityChange: {
      type: Function,
      default: null,
      required: false,
    },
  },
  mounted() {},
  created() {
    let data = localStorage.getItem("_cur_city");
    console.log(data);
    if (data) {
      let d = JSON.parse(data);
      console.log(d.c);
      try {
        this.curCity = d.c;
      } catch (e) {
        console.log(e);
      }
    } else {
      this.getRegionByIp();
    }
  },
  computed: {},
  methods: {
    getRegionByIp() {
      getRegionFromIP().then((resp) => {
        this.curCity = resp.data.city_name;
        try {
          let value = JSON.stringify({
            p: resp.data.province_name,
            p_id: resp.data.province_id,
            c: resp.data.city_name,
            c_id: resp.data.city_id,
          });
          localStorage.setItem("_cur_city", value);
        } catch (e) {
          console.log(e);
        }
      });
    },
    search() {
      // this.$router.push({ name: "Search", query: { keyword: this.keyword } });

      let url = this.$router.resolve({
        path: "/search",
        query: {
          keyword: this.keyword,
        },
      });

      window.open(url.href, "_blank");
    },
    changeCityVisiable(type) {
      this.cityVisiable = type;
    },
    changeCity(city, c) {
      console.log(city, c);
      let provinceName = city.label;
      let cityName = c.label;

      let value = JSON.stringify({
        p: provinceName,
        p_id: city.value,
        c: cityName,
        c_id: c.value,
      });

      this.curCity = cityName;

      localStorage.setItem("_cur_city", value);
      this.cityVisiable = false;
      if (this.headerCityChange) {
        this.headerCityChange();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border: none;
  .header__logo {
    padding: 0;
    a {
      text-decoration: none;
      color: #333;
      outline: none;
      img {
        border: 0;
        width: auto;
        height: 50px;
      }
    }
  }
  .select-city {
    padding-right: 0;
    line-height: normal;
    margin-left: 20px;
    position: relative;
    .select-city__now {
      max-width: 5em;
      display: inline-block;
      overflow: hidden;
      height: 19px;
      padding-right: 5px;
      font-size: 14px;
      cursor: pointer;
    }
    .city-select__icon {
      margin-top: 2px;
      cursor: pointer;
    }
  }
  .h-search {
    padding-top: 0;
    .h-search__container {
      width: 450px;
      height: 46px;
      border-radius: 23px;
      border: solid 2px #f33e3e;
      .h-search__text {
        flex: 1;
        background: none;
        padding: 0 0 0 10px;
        border: none;
        height: 42px;
        line-height: 42px;
        width: 390px;
        font-size: 14px;
        outline: none;
      }
      .h-search__submit {
        width: 100px;
        background-image: none;
        height: 100%;
        border-radius: 0 20px 20px 0;
        background: #f33e3e;
        font-size: 16px;
        border: 0;
        cursor: pointer;
        outline: 0;
        color: #fff;
      }
    }
  }
  .h-nav {
    height: 54px;
    background-color: #303030;
    width: 100%;
    margin-top: 20px;
    .h-nav__container {
      float: left;
      position: relative;
      z-index: 2;
      ul {
        float: left;
        & > li {
          padding: 0;
          border-bottom: none;
          color: #fff;
          padding: 0 34px;
          height: 54px;
          font-size: 16px;
          line-height: 54px;
          float: left;
          a {
            color: #fff;
          }
        }
        li.current {
          background-color: #f33e3e;
        }
      }
    }
  }

  .help-tel {
    margin-top: 0;
    p.time {
      color: #858585;
      line-height: 20px;
      margin-bottom: 0;
    }

    .tel-no {
      font-size: 26px;
      color: #333;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}

.dds-box {
  width: 700px;
  border: 1px solid #e6e6e6;
  background: #fff;
  position: absolute;
  padding: 0px 10px;
  top: 30px;
  left: 0px;
  z-index: 20;
  overflow: hidden;
  ul {
    width: 700px;
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 5px;
    li {
      font-size: 14px;
      line-height: 40px;
      color: #646464;
      border-bottom: 1px dashed #e6e6e6;
      overflow: hidden;
      span {
        padding: 0 5px;
        cursor: pointer;
        color: #717171;
      }
    }
  }

  .add-list-container {
    overflow: hidden;
    // float: right;
    // width: 490px;
  }

  .add-list {
    // float: right;
    // width: 400px;
    text-align: justify;
    span {
      display: inline-block;
    }
  }
}
</style>
