<template>
  <div class="container">
    <PageHeader current="dealer" :headerCityChange="headerCityChange" />
    <div class="main-container">
      <div class="dealerAdvTop flexBC w1200">
        <div class="advEnter">
          <div class="enterImg">
            <img
              src="http://img.shengxingdt.com/web/dealerRegisterLeftBanner.jpg?x-oss-process=style/default.jpg"
              style="width: 360px"
            />
            <!-- <p>
              已成功入驻
              <b style="color: #e82215" class="f16">11730</b> 家经销商
            </p> -->
          </div>
          <div class="enterBtn flexR" style="flex-direction: column">
            <router-link
              :to="{ name: 'DealerLogin' }"
              class="login"
              target="_blank"
              style="display: block"
            >
              经销商登录
            </router-link>
            <router-link
              :to="{ name: 'DealerRegister' }"
              class="enter flexR"
              target="_blank"
              >经销商入驻</router-link
            >
          </div>
        </div>
        <div class="reBanner">
          <router-link :to="{ name: 'DealerRegister' }">
            <img
              src="http://img.shengxingdt.com/web/dealerRegisterBanner.jpg?x-oss-process=style/default.jpg"
            />
          </router-link>
        </div>
      </div>

      <!-- 品牌顶部 -->
      <div class="search_brands">
        <div class="w1200 clearFix">
          <div class="fl brands_select clearFix">
            <div class="name_brands fl">品牌</div>
            <div class="brands_content fr">
              <div class="brands_a_z">
                <a
                  href="javascript:void(0)"
                  style="margin-right: 5px"
                  @click="changeLetter(null)"
                  ><span
                    style="width: auto; padding: 0 10px"
                    :class="selected.letter == '' ? 'cur' : ''"
                    >全部</span
                  ></a
                >
                <a
                  href="javascript:void(0)"
                  v-for="brand in brands"
                  v-bind:key="brand.letter"
                  @click="changeLetter(brand)"
                >
                  <span :class="selected.letter == brand.letter ? 'cur' : ''">{{
                    brand.letter
                  }}</span>
                </a>
              </div>

              <div class="brands_realuts" style="display: block">
                <a
                  href="javascript:void(0);"
                  v-for="item in letterBrands"
                  v-bind:key="item.id"
                  @click="changeBrand(item)"
                  ><span :class="selected.brandId == item.id ? 'cur' : ''">{{
                    item.name
                  }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="recommend_jxs">
        <div class="w1200 clearFix">
          <p class="recommend_title">
            <span>推荐经销商</span>
          </p>

          <ul class="jxs_result" v-if="dealers.length > 0">
            <li
              class="clearFix"
              v-for="dealer in dealers"
              v-bind:key="dealer.id"
            >
              <div class="jxs_r_img">
                <img :src="dealer.img_url" />
              </div>
              <div class="jxs_r_content">
                <h4>{{ dealer.name }}</h4>

                <p>
                  <span>主营品牌：</span>{{ dealer.sale_brands }} 共
                  {{ dealer.sale_brand_cnt }} 个在售品牌
                </p>
                <p>
                  <span>地<span style="color: transparent">一一</span>址：</span
                  >{{ dealer.address }}
                </p>
                <p><span>销售电话：</span>{{ dealer.sell_phone }}</p>
              </div>
              <div class="jxs_r_btn">
                <router-link
                  target="_blank"
                  class="q_xjj"
                  :to="{
                    name: 'DealerQuote',
                    params: {
                      id: dealer.id,
                    },
                    query: {
                      bid: dealer.brand.id,
                    },
                  }"
                  >询价/试驾</router-link
                >
                <router-link
                  target="_blank"
                  class="q_xjj"
                  :to="{
                    name: 'DealerIndex',
                    params: {
                      id: dealer.id,
                    },
                  }"
                >
                  进入店铺</router-link
                >
              </div>
            </li>
          </ul>

          <NoData
            text="该品牌下的经销商暂未开通，请看看其他品牌吧！"
            v-if="dealers.length == 0"
          />

          <div class="page-container" v-if="total > 20">
            <a-pagination
              :current="page"
              :total="total"
              :defaultPageSize="limit"
              @change="onChange"
            />
          </div>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import BrandsData from "@/data/brands";
import NoData from "@/components/NoData";
import { getDealers } from "@/api/dealer";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
    NoData,
  },
  data() {
    return {
      selected: {
        letter: "",
        brandId: 0,
        brandIds: [],
      },
      brands: [],
      letterBrands: [],
      dealers: [],
      page: 1,
      limit: 20,
      total: 0,
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.initData();
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    initData() {
      let letters = []; // 数据列表
      let brandMap = {}; // 每一个索引指向一个数组，这个数组存储该字母索引的朋友项

      BrandsData.forEach((brand) => {
        const letter = brand.letter;
        brandMap[letter] || (brandMap[letter] = []);
        brandMap[letter].push(brand);
      });

      for (let letter in brandMap) {
        letters.push({
          letter: letter,
          brands: brandMap[letter],
        });
      }

      // 按首字母排序
      // 字符串比较不能用减号得出大小，返回都是NaN
      // 因此这里需要通过小于号比较
      letters.sort((a, b) => {
        return a.letter <= b.letter ? -1 : 1;
      });

      this.brands = letters;
    },

    changeLetter(brandGroup) {
      if (brandGroup == null) {
        this.letterBrands = [];
        this.selected.letter = "";
        this.selected.brandId = 0;
        this.selected.brandIds = [];
      } else {
        this.selected.letter = brandGroup.letter;
        this.selected.brandId = 0;
        this.letterBrands = brandGroup.brands;
        this.selected.brandIds = [];
        brandGroup.brands.map((item) => {
          this.selected.brandIds.push(item.id);
        });
      }
      this.getData();
      console.log(this.selected);
    },

    changeBrand(brand) {
      this.selected.brandId = brand.id;
      this.selected.brandIds = [brand.id];
      this.getData();
    },

    getData() {
      getDealers({
        brand_ids: this.selected.brandIds,
        cid: this.getCityId(),
        page: this.page,
        limit: this.limit,
      }).then((resp) => {
        this.dealers = resp.data.list;
        this.total = resp.data.total;
      });
    },

    onChange(current) {
      this.page = current;
      this.getData();
    },

    getCityId() {
      let curCity = localStorage.getItem("_cur_city");

      console.log("ssss", curCity);

      if (curCity) {
        console.log(">>>", curCity);
        let curCityData = JSON.parse(curCity);

        return curCityData.c_id;
      }

      return 110100;
    },
    headerCityChange() {
      console.log('headerCityChange >>>>')
      this.getCityId()
      this.getData()
    }
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.f16 {
  font-size: 16px;
}
.dealerAdvTop {
  margin-bottom: 15px;
  margin-top: 15px;
  align-items: flex-start;
  .advEnter {
    width: 359px;
    height: 360px;
    background-color: #f7f7f7;
    border: solid 1px #dadada;
    .enterImg {
      position: relative;
      margin: -1px;
      & > p {
        position: absolute;
        bottom: 15px;
        width: 100%;
        text-align: center;
        left: 0;
      }
    }

    .enterBtn {
      margin-top: 20px;
      a.login {
        width: 311px;
        height: 50px;
        line-height: 50px;
        background-color: #dd2a2a;
        border-radius: 6px;
        display: block;
        text-align: center;
        color: #fff;
        font-size: 18px;
      }
      a.enter {
        color: #dd2a2a;
        font-size: 18px;
        text-decoration: underline;
        margin-top: 15px;
        &::before {
          content: "";
          background-image: url(//img.kchezhan.com/images/lzweb/delericons.png);
          width: 20px;
          height: 26px;
          display: inline-block;
          background-position: 0px 0px;
          margin-right: 10px;
        }
      }
    }
  }

  .reBanner {
    img {
      width: 820px;
      height: 360px;
    }
  }
}

.search_brands {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px 0px rgb(102, 99, 94);
  margin-bottom: 20px;
  padding: 20px 0;
  overflow: hidden;

  span.cur {
    background-color: #e42a2a;
    color: #fff;
    border-radius: 3px;
  }
  .brands_select {
    .name_brands {
      width: 75px;
      font-size: 16px;
      text-align: left;
    }

    .brands_content {
      width: 945px;
      .brands_a_z {
        margin-bottom: 30px;
        a {
          color: #333;
        }
        span {
          display: inline-block;
          width: 30px;
          font-size: 16px;
          cursor: pointer;
          margin-right: 2px;
          text-align: center;
        }
      }
    }

    .brands_realuts {
      a {
        color: #333;
      }
      span {
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        margin-right: 2px;
        text-align: center;
        width: auto;
        padding: 0 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.recommend_jxs {
  padding: 35px 0;
  p.recommend_title {
    border-bottom: 1px solid #999;
    position: relative;
    span {
      font-size: 18px;
      border-bottom: 3px solid #e42a2a;
      line-height: 27px;
      display: inline-block;
      position: absolute;
      bottom: -1px;
    }
  }

  ul.jxs_result {
    li {
      box-sizing: border-box;
      padding: 20px;
      width: 1200px;
      height: 200px;
      background-color: #ffffff;
      border-left: solid 1px #ecf0ff;
      border-right: solid 1px #ecf0ff;
      .jxs_r_img {
        margin-right: 20px;
        float: left;
        width: 242px;
        height: 160px;
        line-height: 160px;
        overflow: hidden;
        text-align: center;
        background-color: #fff;
        img {
          max-width: 242px;
          max-height: 160px;
          vertical-align: middle;
        }
      }

      .jxs_r_content {
        float: left;
        width: 720px;
        h4 {
          margin-bottom: 40px;
          font-size: 18px;
          font-weight: normal;
          line-height: 28px;
          letter-spacing: 0px;
        }

        p {
          font-size: 14px;
          color: #666;
        }
      }

      .jxs_r_btn {
        padding-top: 10px;

        a.q_xjj {
          background-color: #e42a2a;
          border: solid 1px #be0d0d;
          color: #fff;
          margin: 14px 0;
          font-size: 14px;
          display: inline-block;
          width: 140px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
    margin: 25px 0;
    li:nth-of-type(odd) {
      background-color: #ecf0ff;
    }
  }
}
</style>

