<template>
  <div class="container">
    <PageHeader current="aboutus" />
    <div class="main-container">
      <div class="w1200 f6 clearFix">
        <div class="fl about_left">
          <IntroNav current="question" />
        </div>
        <div class="fr about_right">
          <div class="c-box">
            <div class="question">
              <ul>
                <li class="clearFix">
                  <div class="fl num_q">1</div>
                  <div class="fl con">
                    <h4>
                      1、晟行汽车特卖现在什么价格？
                      为什么不在网站上公开特卖价格？
                    </h4>
                    <p>
                      答：人数决定价格，特卖报名活动截止前一天，晟行汽车团长根据特卖报名人数、市场情况和各个4s店竞价谈判后，选出价格优惠的4s店合作，同时短信通知您当批特卖所选4s店的地址！您按照要求到特卖现场后，价格由4s店人员当场公布（注：不在网站公开或者QQ、电话咨询中透漏当期特卖价）
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">2</div>
                  <div class="fl con">
                    <h4>2、晟行汽车特卖在哪家4S店？</h4>
                    <p>
                      答：晟行汽车所选择的都是正规4s店，特卖地点不固定，哪家4S店给的特卖价格优惠就去哪家4S店（侧重选择资信良好、现车充足、交通便利的4s店）。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">3</div>
                  <div class="fl con">
                    <h4>3、分期付款还能享受特卖价格吗？</h4>
                    <p>答：分期付款与一次性付款购车一样，同样享受特卖价格。</p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">4</div>
                  <div class="fl con">
                    <h4>4、买车的店离我家太远，今后保养可怎么办呢？</h4>
                    <p>
                      答：买车只是一次性行为，而维修保养则是长期行为。新车在所有4S店保养服务都是一样的，所以您的爱车只需在离家最近的4S店保养即可。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">5</div>
                  <div class="fl con">
                    <h4>5、特卖价格便宜了，汽车质量有问题吗？</h4>
                    <p>
                      答：特卖汽车的质量和个人在4S店购车的质量是一样的，自晟行汽车成立以来，从未出现过一个客户投诉有假冒伪劣产品现象。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">6</div>
                  <div class="fl con">
                    <h4>6、参加晟行汽车有哪些好处？</h4>
                    <p>
                      答：（1）价格更实惠。团结就是力量，特卖价肯定比个人单独购买更低。一般来说优惠会在几千到万元间，个别车型甚至会有一、二万的优惠幅度。
                      <br />（2）省时省力省心。省时——免去多家4S店反复砍价、往返看车的劳顿。省力——最实惠的价格拒绝吃亏，购车一次成功。省心——对于团友来说，特卖有一群人共同了解和决策，价格信息互通，集体维护权益。而对于经销商来说：薄利多销售汽车冲量可以多拿返利。
                      <br />（3）售前服务。特卖的出现在一定程度上填补了目前汽车市场"售前服务"的空白。买车相对于购买其他商品要复杂得多。比如，关于汽车的知识、保险、贷款等，普通人很难了解。因此，在信息上，买卖双方是不对称的。而特卖可以弥补这个不对称，提供专业的汽车服务，分享最新信息和专业经验，可帮助团友更高程度地了解意向汽车的性能和价值。
                      <br />（4）交友增趣。在整个特卖活动过程中，不同行业的团友们一起交流车市信息和购车心得，一起特卖汽车，让大家既得到实惠又交了朋友，大大拓展人际关系和增益生活乐趣。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">7</div>
                  <div class="fl con">
                    <h4>7、参加晟行汽车的特卖与单独买车有何不同？</h4>
                    <p>
                      答：通过晟行汽车特卖与自己单独去买有三同四不同<br />
                      三同：<br />
                      （1）同样的车 都是4S店的车<br />
                      （2）同样的店 都是同一家4S店<br />
                      （3）同样的售后服务 都是享受厂家提供的统一售后服务<br />
                      四不同：<br />
                      （1）车价更低
                      参加晟行汽车特卖网组织可以一步到位享受到当日全城低价。<br />
                      （2）节省时间，轻松自在，晟行汽车组织大部分主流车型的购车活动，无需再去四处走动看车、更无须货比三家拼命砍价，加入晟行汽车特卖网一次搞定。<br />
                      （3）享受双重服务
                      晟行汽车会员购车除了可以享受车商提供的服务外，还可以享受汽车后市场的特卖，请您关注晟行汽车官方微信。（微信号：汽车电商）<br />
                      （4）认识更多朋友，晟行汽车特卖网每次都会组织少则十多名多则近百位的车主一起购车，轻松加入粒子特卖，认知更多志同道合的朋友。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">8</div>
                  <div class="fl con">
                    <h4>8、汽车特卖能比展厅买车便宜多少呢？</h4>
                    <p>
                      答：参加特卖一般比自己去展厅买车要便宜，原理如下：<br />
                      A价：第一次到4S店一般客户能谈到的优惠；<br />
                      B价：第二次到4S店一般客户能谈到的优惠；<br />
                      C价：第三次或多次到4S店一般客户能谈到的优惠；<br />
                      D价：很会谈价格、且对市场行情熟悉的车友在4S店能谈到的优惠；<br />
                      E价：4S店展厅的优惠；<br />
                      F价：参加特卖不用劳神得到的特卖优惠<br />
                      说明：<br />
                      （1）一般来说，不懂车的客户在A价或B价被销售员拿下；<br />
                      （2）会谈价的客户一般在C和D价会签订协议；<br />
                      （3）E价很少会被客户触及到；<br />
                      （4）当然不排除个别网友通过其他渠道拿到个别特价车型的优惠价。<br />
                      （5）特别提醒的是：目前汽车销售环节的利润已日趋薄弱，通过晟行汽车特卖网买车也不会再有前几年动辄数千的差价了，不过我们能保证晟行汽车团员轻松拿到当日低价，且享受我们提供的后续增服值务。
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import IntroNav from "@/components/IntroNav";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
    IntroNav,
  },
  data() {
    return {};
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {},
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  .about_left {
    width: 320px;
    padding: 40px;
  }
  .about_right {
    min-height: 400px;
    width: 880px;
    background: #fff;
    padding: 40px;
    .question {
      ul {
        li {
          margin-bottom: 15px;
        }
      }
      .num_q {
        background-image: url("../../assets/icons/qa-icon.png");
        background-position: center center;
        background-repeat: no-repeat;
        width: 39px;
        height: 37px;
        line-height: 37px;
        color: #fff;
        font-size: 24px;
        padding-left: 13px;
      }
      .con {
        width: 760px;
        padding-left: 10px;
      }
    }
  }
}
</style>

