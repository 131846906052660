<template>
  <div class="container">
    <PageHeader current="quote" />
    <div class="main-container">
      <div class="w1200 w-breadcrumb">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item> <router-link :to="{ name: 'Index' }">首页</router-link> </a-breadcrumb-item>
          <a-breadcrumb-item> 报价 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="w1200" style="min-height: 1100px; height: auto">
        <div class="fl">
          <brand-select @changeBrand="changeBrand"> </brand-select>
        </div>
        <div class="fr r_bj">
          <dl class="scar-options mt30">
            <dt><span>按条件选车</span></dt>

            <dd class="flexL">
              <span class="f999">级别：</span>
              <div class="flexL">
                <ul class="flexL">
                  <li
                    v-for="item in levels"
                    v-bind:key="item.id"
                    :class="item.id === selected.level ? 'on' : ''"
                  >
                    <span
                      class="scar-item__span"
                      @click="selectCar('level', item)"
                      >{{ item.name }}</span
                    >
                  </li>
                </ul>
              </div>
            </dd>
            <dd class="flexL">
              <span class="f999">价格：</span>
              <div class="flexL">
                <ul class="flexL">
                  <li
                    v-for="item in prices"
                    v-bind:key="item.id"
                    :class="item.id === selected.price ? 'on' : ''"
                  >
                    <span
                      class="scar-item__span"
                      @click="selectCar('price', item)"
                      >{{ item.name }}</span
                    >
                  </li>
                </ul>
              </div>
            </dd>
          </dl>
          <p class="recommend_title">
            <span>车系列表</span>
          </p>
          <div class="car-results">
            <ul class="carslist clearFix" v-if="results.length > 0">
              <li v-for="item in results" v-bind:key="item.id">
                <router-link :to="{ name: 'Series', params: { id: item.id } }">
                  <img :src="item.cover_url" />
                </router-link>
                <p class="carsname">
                  <router-link
                    :to="{ name: 'Series', params: { id: 1 } }"
                    class="font-16"
                  >
                    {{ item.name }}
                  </router-link>
                </p>
                <p>
                  <router-link
                    :to="{ name: 'Series', params: { id: item.id } }"
                    style="color: #e42a2a; font-size: 14px"
                  >
                    {{ item.price }}</router-link
                  >
                </p>
              </li>
            </ul>
            <NoData v-if="results.length === 0" />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import BrandSelect from "@/components/BrandSelect";
import CarsData from "@/data/cars";
import { getSearchCars } from "@/api/car";
import NoData from "@/components/NoData";

export default {
  name: "QuotePage",
  components: {
    PageHeader,
    Footer,
    BrandSelect,
    NoData,
  },
  data() {
    return {
      levels: CarsData.levels,
      prices: CarsData.prices,
      opBrands: CarsData.brands,
      page: 1,
      limit: 20,
      results: [],
      total: 0,
      selected: {
        level: "",
        price: 0,
        brandId: 0,
        min: 0,
        max: 0,
      },
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      let formData = {
        page: this.page,
        limit: this.limit,
        levels: this.selected.level,
        min_price: this.selected.min,
        max_price: this.selected.max,
        bid: this.selected.brandId,
      };
      getSearchCars(formData).then((response) => {
        this.results = response.data.list;
        this.total = response.data.count;
      });
    },
    changeBrand(res) {
      this.selected.brandId = res.id;
      this.getData();
    },
    getContainer() {
      return this.$refs.alphabetContainer;
    },
    selectCar(option, obj) {
      this.page = 1;
      if (option === "level") {
        this.selected.level = obj.id;
      }
      if (option === "price") {
        this.selected.price = obj.id;
        this.selected.min = obj.min;
        this.selected.max = obj.max;
      }

      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.font-16 {
  font-size: 16px;
  color: #333;
}
.w-breadcrumb {
  margin: 10px auto;
  font-size: 14px;
  padding: 0 10px;
}
.main-container {
  overflow: hidden;
  margin-top: 20px;
  .r_bj {
    width: 880px;
  }

  .scar-options {
    margin-top: 30px;

    .scar-item__span {
      cursor: pointer;
    }
    & > dt {
      border-bottom: 2px solid #e7e7e7;
      & > span {
        font-size: 20px;
        position: relative;
        padding-bottom: 10px;
        display: inline-block;
        cursor: pointer;
      }
      & > span::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #f33e3e;
        bottom: -2px;
        left: 0;
        z-index: 1;
      }
    }

    & > dd {
      border-bottom: 1px solid #e7e7e7;
      padding: 10px 0;
      ul {
        margin-bottom: 0px;
        li {
          padding: 0 5px;
          cursor: default;
          margin: 10px;
          white-space: nowrap;
          position: relative;
          &.on {
            color: #fff;
            background-color: #f7891b;
            border-radius: 2px;
            height: auto;
          }
        }
      }
    }
  }

  .recommend_title {
    border-bottom: 1px solid #999;
    position: relative;
    margin-top: 10px;
    height: 40px;

    span {
      font-size: 20px;
      border-bottom: 2px solid #e42a2a;
      line-height: 27px;
      display: inline-block;
      position: absolute;
      bottom: -1px;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  .car-results {
    ul.carslist {
      li {
        width: 25%;
        float: left;
        text-align: center;
        margin: 10px 0;
        img {
          width: 190px;
          height: 138px;
        }
        p {
          line-height: 24px;
          color: #e42a2a;
          margin: 0;
        }
        .carname {
          font-size: 16px;
          color: #333;
          a {
            line-height: 24px;
            color: #333;
            display: block;
          }
        }
      }
    }
  }
}

fl {
  float: left;
}
fr {
  float: right;
  display: inline;
}
</style>

