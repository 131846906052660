export default [{
        "id": 1,
        "name": "奥迪",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/33.png",
        "letter": "A"
    },
    {
        "id": 2,
        "name": "阿尔法·罗密欧",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/34.png",
        "letter": "A"
    },
    {
        "id": 3,
        "name": "阿斯顿·马丁",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/35.png",
        "letter": "A"
    },
    {
        "id": 4,
        "name": "ARCFOX极狐",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/272.png",
        "letter": "A"
    },
    {
        "id": 10,
        "name": "AUXUN傲旋",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/378.png",
        "letter": "A"
    },
    {
        "id": 20,
        "name": "爱驰",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/327.png",
        "letter": "A"
    },
    {
        "id": 24,
        "name": "本田",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/14.png",
        "letter": "B"
    },
    {
        "id": 25,
        "name": "奔驰",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/36.png",
        "letter": "B"
    },
    {
        "id": 26,
        "name": "宝马",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/15.png",
        "letter": "B"
    },
    {
        "id": 27,
        "name": "别克",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/38.png",
        "letter": "B"
    },
    {
        "id": 28,
        "name": "比亚迪",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/75.png",
        "letter": "B"
    },
    {
        "id": 29,
        "name": "保时捷",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/40.png",
        "letter": "B"
    },
    {
        "id": 30,
        "name": "标致",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/13.png",
        "letter": "B"
    },
    {
        "id": 31,
        "name": "宝骏",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/120.png",
        "letter": "B"
    },
    {
        "id": 32,
        "name": "宾利",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/39.png",
        "letter": "B"
    },
    {
        "id": 33,
        "name": "北京",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/27.png",
        "letter": "B"
    },
    {
        "id": 34,
        "name": "奔腾",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/95.png",
        "letter": "B"
    },
    {
        "id": 35,
        "name": "BEIJING汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/173.png",
        "letter": "B"
    },
    {
        "id": 36,
        "name": "北汽制造",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/154.png",
        "letter": "B"
    },
    {
        "id": 37,
        "name": "北汽新能源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/208.png",
        "letter": "B"
    },
    {
        "id": 38,
        "name": "北汽昌河",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/79.png",
        "letter": "B"
    },
    {
        "id": 41,
        "name": "比克汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/440.png",
        "letter": "B"
    },
    {
        "id": 44,
        "name": "比德文汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/387.png",
        "letter": "B"
    },
    {
        "id": 50,
        "name": "宝骐汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/351.png",
        "letter": "B"
    },
    {
        "id": 51,
        "name": "宝沃",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/231.png",
        "letter": "B"
    },
    {
        "id": 58,
        "name": "长安",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/76.png",
        "letter": "C"
    },
    {
        "id": 59,
        "name": "长安欧尚",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/163.png",
        "letter": "C"
    },
    {
        "id": 60,
        "name": "长城",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/77.png",
        "letter": "C"
    },
    {
        "id": 61,
        "name": "长安凯程",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/294.png",
        "letter": "C"
    },
    {
        "id": 64,
        "name": "车驰汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/366.png",
        "letter": "C"
    },
    {
        "id": 67,
        "name": "成功汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/196.png",
        "letter": "C"
    },
    {
        "id": 71,
        "name": "长安跨越",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/299.png",
        "letter": "C"
    },
    {
        "id": 74,
        "name": "大众",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/1.png",
        "letter": "D"
    },
    {
        "id": 75,
        "name": "东风风行",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/165.png",
        "letter": "D"
    },
    {
        "id": 76,
        "name": "东风风神",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/113.png",
        "letter": "D"
    },
    {
        "id": 77,
        "name": "东风风光",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/259.png",
        "letter": "D"
    },
    {
        "id": 78,
        "name": "DS",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/169.png",
        "letter": "D"
    },
    {
        "id": 79,
        "name": "东风",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/32.png",
        "letter": "D"
    },
    {
        "id": 80,
        "name": "东风小康",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/142.png",
        "letter": "D"
    },
    {
        "id": 81,
        "name": "东南",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/81.png",
        "letter": "D"
    },
    {
        "id": 83,
        "name": "东风新能源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/447.png",
        "letter": "D"
    },
    {
        "id": 84,
        "name": "东风御风",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/451.png",
        "letter": "D"
    },
    {
        "id": 85,
        "name": "东风富康",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/406.png",
        "letter": "D"
    },
    {
        "id": 86,
        "name": "大乘汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/341.png",
        "letter": "D"
    },
    {
        "id": 89,
        "name": "大运",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/405.png",
        "letter": "D"
    },
    {
        "id": 101,
        "name": "东风·瑞泰特",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/326.png",
        "letter": "D"
    },
    {
        "id": 104,
        "name": "丰田",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/3.png",
        "letter": "F"
    },
    {
        "id": 105,
        "name": "福特",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/8.png",
        "letter": "F"
    },
    {
        "id": 106,
        "name": "法拉利",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/42.png",
        "letter": "F"
    },
    {
        "id": 107,
        "name": "福田",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/96.png",
        "letter": "F"
    },
    {
        "id": 108,
        "name": "枫叶汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/416.png",
        "letter": "F"
    },
    {
        "id": 109,
        "name": "飞碟汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/434.png",
        "letter": "F"
    },
    {
        "id": 110,
        "name": "菲亚特",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/11.png",
        "letter": "F"
    },
    {
        "id": 114,
        "name": "福迪",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/141.png",
        "letter": "F"
    },
    {
        "id": 117,
        "name": "福汽启腾",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/197.png",
        "letter": "F"
    },
    {
        "id": 119,
        "name": "广汽传祺",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/82.png",
        "letter": "G"
    },
    {
        "id": 120,
        "name": "广汽埃安",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/313.png",
        "letter": "G"
    },
    {
        "id": 121,
        "name": "观致",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/152.png",
        "letter": "G"
    },
    {
        "id": 122,
        "name": "广汽集团",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/329.png",
        "letter": "G"
    },
    {
        "id": 129,
        "name": "高合汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/383.png",
        "letter": "G"
    },
    {
        "id": 130,
        "name": "广汽蔚来",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/376.png",
        "letter": "G"
    },
    {
        "id": 133,
        "name": "国机智骏",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/369.png",
        "letter": "G"
    },
    {
        "id": 143,
        "name": "哈弗",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/181.png",
        "letter": "H"
    },
    {
        "id": 144,
        "name": "红旗",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/91.png",
        "letter": "H"
    },
    {
        "id": 145,
        "name": "海马",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/86.png",
        "letter": "H"
    },
    {
        "id": 146,
        "name": "黄海",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/97.png",
        "letter": "H"
    },
    {
        "id": 156,
        "name": "宏远汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/446.png",
        "letter": "H"
    },
    {
        "id": 158,
        "name": "海格",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/150.png",
        "letter": "H"
    },
    {
        "id": 159,
        "name": "恒天",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/164.png",
        "letter": "H"
    },
    {
        "id": 166,
        "name": "华凯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/245.png",
        "letter": "H"
    },
    {
        "id": 169,
        "name": "汉腾汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/267.png",
        "letter": "H"
    },
    {
        "id": 170,
        "name": "IMSA英飒",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/429.png",
        "letter": "I"
    },
    {
        "id": 178,
        "name": "吉利汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/25.png",
        "letter": "J"
    },
    {
        "id": 179,
        "name": "捷豹",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/44.png",
        "letter": "J"
    },
    {
        "id": 180,
        "name": "Jeep",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/46.png",
        "letter": "J"
    },
    {
        "id": 181,
        "name": "捷达",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/358.png",
        "letter": "J"
    },
    {
        "id": 182,
        "name": "捷途",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/319.png",
        "letter": "J"
    },
    {
        "id": 183,
        "name": "江淮",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/84.png",
        "letter": "J"
    },
    {
        "id": 184,
        "name": "金杯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/83.png",
        "letter": "J"
    },
    {
        "id": 185,
        "name": "江铃",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/119.png",
        "letter": "J"
    },
    {
        "id": 186,
        "name": "几何汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/373.png",
        "letter": "J"
    },
    {
        "id": 187,
        "name": "钧天",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/356.png",
        "letter": "J"
    },
    {
        "id": 188,
        "name": "金冠汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/419.png",
        "letter": "J"
    },
    {
        "id": 189,
        "name": "金龙",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/145.png",
        "letter": "J"
    },
    {
        "id": 190,
        "name": "九龙",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/151.png",
        "letter": "J"
    },
    {
        "id": 192,
        "name": "金旅",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/175.png",
        "letter": "J"
    },
    {
        "id": 194,
        "name": "江铃集团新能源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/270.png",
        "letter": "J"
    },
    {
        "id": 198,
        "name": "凯迪拉克",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/47.png",
        "letter": "K"
    },
    {
        "id": 199,
        "name": "凯翼",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/214.png",
        "letter": "K"
    },
    {
        "id": 200,
        "name": "克莱斯勒",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/9.png",
        "letter": "K"
    },
    {
        "id": 202,
        "name": "开瑞",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/101.png",
        "letter": "K"
    },
    {
        "id": 207,
        "name": "卡威",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/199.png",
        "letter": "K"
    },
    {
        "id": 209,
        "name": "开沃汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/213.png",
        "letter": "K"
    },
    {
        "id": 211,
        "name": "雷克萨斯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/52.png",
        "letter": "L"
    },
    {
        "id": 212,
        "name": "领克",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/279.png",
        "letter": "L"
    },
    {
        "id": 213,
        "name": "路虎",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/49.png",
        "letter": "L"
    },
    {
        "id": 214,
        "name": "林肯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/51.png",
        "letter": "L"
    },
    {
        "id": 215,
        "name": "兰博基尼",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/48.png",
        "letter": "L"
    },
    {
        "id": 216,
        "name": "劳斯莱斯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/54.png",
        "letter": "L"
    },
    {
        "id": 217,
        "name": "理想汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/345.png",
        "letter": "L"
    },
    {
        "id": 218,
        "name": "零跑汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/318.png",
        "letter": "L"
    },
    {
        "id": 219,
        "name": "路特斯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/50.png",
        "letter": "L"
    },
    {
        "id": 220,
        "name": "理念",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/124.png",
        "letter": "L"
    },
    {
        "id": 221,
        "name": "LITE",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/335.png",
        "letter": "L"
    },
    {
        "id": 224,
        "name": "凌宝汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/420.png",
        "letter": "L"
    },
    {
        "id": 225,
        "name": "雷诺",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/10.png",
        "letter": "L"
    },
    {
        "id": 226,
        "name": "LUMMA",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/441.png",
        "letter": "L"
    },
    {
        "id": 227,
        "name": "龙程汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/444.png",
        "letter": "L"
    },
    {
        "id": 231,
        "name": "陆风",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/88.png",
        "letter": "L"
    },
    {
        "id": 233,
        "name": "Lorinser",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/118.png",
        "letter": "L"
    },
    {
        "id": 236,
        "name": "陆地方舟",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/204.png",
        "letter": "L"
    },
    {
        "id": 237,
        "name": "雷丁",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/215.png",
        "letter": "L"
    },
    {
        "id": 258,
        "name": "迈莎锐",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/374.png",
        "letter": "M"
    },
    {
        "id": 249,
        "name": "马自达",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/58.png",
        "letter": "M"
    },
    {
        "id": 250,
        "name": "名爵",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/20.png",
        "letter": "M"
    },
    {
        "id": 251,
        "name": "玛莎拉蒂",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/57.png",
        "letter": "M"
    },
    {
        "id": 252,
        "name": "MINI",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/56.png",
        "letter": "M"
    },
    {
        "id": 253,
        "name": "迈凯伦",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/129.png",
        "letter": "M"
    },
    {
        "id": 267,
        "name": "哪吒汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/309.png",
        "letter": "N"
    },
    {
        "id": 275,
        "name": "欧拉",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/331.png",
        "letter": "O"
    },
    {
        "id": 276,
        "name": "讴歌",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/60.png",
        "letter": "O"
    },
    {
        "id": 281,
        "name": "朋克汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/449.png",
        "letter": "P"
    },
    {
        "id": 285,
        "name": "Polestar极星",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/308.png",
        "letter": "P"
    },
    {
        "id": 286,
        "name": "起亚",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/62.png",
        "letter": "Q"
    },
    {
        "id": 287,
        "name": "奇瑞",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/26.png",
        "letter": "Q"
    },
    {
        "id": 288,
        "name": "启辰",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/122.png",
        "letter": "Q"
    },
    {
        "id": 290,
        "name": "骐铃汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/210.png",
        "letter": "Q"
    },
    {
        "id": 291,
        "name": "全球鹰",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/219.png",
        "letter": "Q"
    },
    {
        "id": 292,
        "name": "乔治·巴顿",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/222.png",
        "letter": "Q"
    },
    {
        "id": 293,
        "name": "前途",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/235.png",
        "letter": "Q"
    },
    {
        "id": 294,
        "name": "庆铃汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/312.png",
        "letter": "Q"
    },
    {
        "id": 295,
        "name": "日产",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/63.png",
        "letter": "R"
    },
    {
        "id": 296,
        "name": "荣威",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/19.png",
        "letter": "R"
    },
    {
        "id": 297,
        "name": "R汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/438.png",
        "letter": "R"
    },
    {
        "id": 298,
        "name": "瑞麒",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/103.png",
        "letter": "R"
    },
    {
        "id": 299,
        "name": "如虎",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/174.png",
        "letter": "R"
    },
    {
        "id": 302,
        "name": "RAM",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/452.png",
        "letter": "R"
    },
    {
        "id": 305,
        "name": "容大智造",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/337.png",
        "letter": "R"
    },
    {
        "id": 308,
        "name": "瑞驰新能源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/296.png",
        "letter": "R"
    },
    {
        "id": 309,
        "name": "斯柯达",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/67.png",
        "letter": "S"
    },
    {
        "id": 310,
        "name": "三菱",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/68.png",
        "letter": "S"
    },
    {
        "id": 311,
        "name": "上汽大通MAXUS",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/155.png",
        "letter": "S"
    },
    {
        "id": 312,
        "name": "斯巴鲁",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/65.png",
        "letter": "S"
    },
    {
        "id": 313,
        "name": "思皓",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/330.png",
        "letter": "S"
    },
    {
        "id": 314,
        "name": "smart",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/45.png",
        "letter": "S"
    },
    {
        "id": 315,
        "name": "思铭",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/162.png",
        "letter": "S"
    },
    {
        "id": 316,
        "name": "SWM斯威汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/269.png",
        "letter": "S"
    },
    {
        "id": 317,
        "name": "SERES赛力斯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/325.png",
        "letter": "S"
    },
    {
        "id": 318,
        "name": "SRM鑫源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/306.png",
        "letter": "S"
    },
    {
        "id": 319,
        "name": "神州",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/426.png",
        "letter": "S"
    },
    {
        "id": 320,
        "name": "速达",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/433.png",
        "letter": "S"
    },
    {
        "id": 321,
        "name": "SONGSAN MOTORS",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/436.png",
        "letter": "S"
    },
    {
        "id": 326,
        "name": "上喆",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/402.png",
        "letter": "S"
    },
    {
        "id": 341,
        "name": "特斯拉",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/133.png",
        "letter": "T"
    },
    {
        "id": 342,
        "name": "腾势",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/161.png",
        "letter": "T"
    },
    {
        "id": 347,
        "name": "天美汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/400.png",
        "letter": "T"
    },
    {
        "id": 353,
        "name": "天际汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/339.png",
        "letter": "T"
    },
    {
        "id": 362,
        "name": "五菱汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/114.png",
        "letter": "W"
    },
    {
        "id": 363,
        "name": "沃尔沃",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/70.png",
        "letter": "W"
    },
    {
        "id": 364,
        "name": "WEY",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/283.png",
        "letter": "W"
    },
    {
        "id": 365,
        "name": "蔚来",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/284.png",
        "letter": "W"
    },
    {
        "id": 366,
        "name": "五十铃",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/167.png",
        "letter": "W"
    },
    {
        "id": 367,
        "name": "威马汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/291.png",
        "letter": "W"
    },
    {
        "id": 369,
        "name": "WALD",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/445.png",
        "letter": "W"
    },
    {
        "id": 371,
        "name": "威麟",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/102.png",
        "letter": "W"
    },
    {
        "id": 375,
        "name": "现代",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/12.png",
        "letter": "X"
    },
    {
        "id": 376,
        "name": "雪佛兰",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/71.png",
        "letter": "X"
    },
    {
        "id": 377,
        "name": "小鹏汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/275.png",
        "letter": "X"
    },
    {
        "id": 378,
        "name": "新宝骏",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/396.png",
        "letter": "X"
    },
    {
        "id": 379,
        "name": "雪铁龙",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/72.png",
        "letter": "X"
    },
    {
        "id": 380,
        "name": "星途",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/350.png",
        "letter": "X"
    },
    {
        "id": 381,
        "name": "晓奥汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/439.png",
        "letter": "X"
    },
    {
        "id": 384,
        "name": "新特汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/324.png",
        "letter": "X"
    },
    {
        "id": 385,
        "name": "英菲尼迪",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/73.png",
        "letter": "Y"
    },
    {
        "id": 386,
        "name": "依维柯",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/144.png",
        "letter": "Y"
    },
    {
        "id": 387,
        "name": "野马汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/111.png",
        "letter": "Y"
    },
    {
        "id": 388,
        "name": "驭胜",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/263.png",
        "letter": "Y"
    },
    {
        "id": 389,
        "name": "野马新能源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/398.png",
        "letter": "Y"
    },
    {
        "id": 390,
        "name": "一汽凌河",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/399.png",
        "letter": "Y"
    },
    {
        "id": 392,
        "name": "一汽",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/110.png",
        "letter": "Y"
    },
    {
        "id": 393,
        "name": "远程汽车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/382.png",
        "letter": "Y"
    },
    {
        "id": 397,
        "name": "云度",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/286.png",
        "letter": "Y"
    },
    {
        "id": 398,
        "name": "宇通客车",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/298.png",
        "letter": "Y"
    },
    {
        "id": 401,
        "name": "银隆新能源",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/375.png",
        "letter": "Y"
    },
    {
        "id": 403,
        "name": "中华",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/22.png",
        "letter": "Z"
    },
    {
        "id": 404,
        "name": "中兴",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/74.png",
        "letter": "Z"
    },
    {
        "id": 405,
        "name": "中国重汽VGV",
        "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/393.png",
        "letter": "Z"
    }
]