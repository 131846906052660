export default {
    levels: [{
        "id": "",
        "name": "不限"
    }, {
        "id": "1",
        "name": "微型车"
    }, {
        "id": "2",
        "name": "小型车"
    }, {
        "id": "3",
        "name": "紧凑型车"
    }, {
        "id": "4",
        "name": "中型车"
    }, {
        "id": "5",
        "name": "中大型车"
    }, {
        "id": "7,8,9,10,11",
        "name": "SUV"
    }, {
        "id": "12,13,14,15",
        "name": "MPV"
    }, {
        "id": "16",
        "name": "跑车"
    }, {
        "id": "100",
        "name": "新能源"
    }],
    prices: [{
        "id": 0,
        "name": "不限",
        "min": 0,
        "max": 0
    }, {
        "id": 1,
        "name": "8万以下",
        "min": 0,
        "max": 80000
    }, {
        "id": 2,
        "name": "8-12万",
        "min": 80000,
        "max": 120000
    }, {
        "id": 3,
        "name": "12-18万",
        "min": 120000,
        "max": 180000
    }, {
        "id": 4,
        "name": "18-25万",
        "min": 180000,
        "max": 250000
    }, {
        "id": 5,
        "name": "25-40万",
        "min": 250000,
        "max": 400000
    }, {
        "id": 6,
        "name": "40-80万",
        "min": 400000,
        "max": 800000
    }, {
        "id": 7,
        "name": "80万以上",
        "min": 800000,
        "max": 0
    }],
    brands: [{
            "id": 0,
            "name": "不限",
        }, {
            "id": 1,
            "name": "奥迪",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/33.png",
            "letter": "A"
        },
        {
            "id": 3,
            "name": "阿斯顿·马丁",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/35.png",
            "letter": "A"
        },
        {
            "id": 4,
            "name": "ARCFOX极狐",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/272.png",
            "letter": "A"
        },
        {
            "id": 10,
            "name": "AUXUN傲旋",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/378.png",
            "letter": "A"
        },
        {
            "id": 20,
            "name": "爱驰",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/327.png",
            "letter": "A"
        },
        {
            "id": 24,
            "name": "本田",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/14.png",
            "letter": "B"
        },
        {
            "id": 25,
            "name": "奔驰",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/36.png",
            "letter": "B"
        },
        {
            "id": 26,
            "name": "宝马",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/15.png",
            "letter": "B"
        },
        {
            "id": 27,
            "name": "别克",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/38.png",
            "letter": "B"
        },
        {
            "id": 28,
            "name": "比亚迪",
            "logo_url": "http://auto-platform-api.test/uploads/images/brand/75.png",
            "letter": "B"
        }
    ]
}