<template>
  <div class="container">
    <PageHeader current="aboutus" />
    <div class="main-container">
      <div class="w1200 f6 clearFix">
        <div class="fl about_left">
          <IntroNav current="agreement" />
        </div>
        <div class="fr about_right">
          <div class="c-box">
            晟行科技及其关联公司（如无特殊约定，以下统称“晟行”）收集用户的个人信息的最终目的是为了向您提供更好的产品、服务，优化并丰富您的用户体验。晟行将按照本声明的规定合理使用并保护用户的个人信息，据此制订《个人信息保护声明》如下：<br />
            <p>一、个人信息的收集</p>
            <p>
              1、晟行向用户收集的个人信息指的是能够单独或与其他信息结合识别您个人身份的信息，这些信息包括：①车型②联系方式③您在页面上留下的其他信息（车牌号、里程数、卖车意向等）④在您上载到网站的内容中包含的任何个人信息。
            </p>
            <p>
              2、以上个人信息均是用户自愿提供。您有权拒绝提供，但如果您拒绝提供某些个人信息，您将可能无法使用晟行提供的服务、产品，或者可能对您使用服务或产品造成一定的影响。
            </p>
            <p>
              3、对于不满18岁的用户，须在其法定监护人已经阅读本声明并且许可的情况下，通过网站提交个人信息。
            </p>
            <p>二、个人信息的使用</p>
            <p>
              1、我们会根据本声明为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
            </p>
            <p>
              2、您同意，晟行可以通过以下方式对您个人信息进行使用和分享（包含对于个人信息的存储和处理）：
            </p>
            <p>（1）我们和关联公司使用；</p>
            <p>（2）我们向相关第三方服务商分享并由其使用；</p>
            <p>
              （3）我们及关联公司及相关第三方服务商为满足您的需求，可能通过您提供的信息与您联系；
            </p>
            <p>
              （4）我们及关联公司及相关第三方服务商可能定期或不定期向您发送有关产品、服务或相关活动的信息，您同意接收上述信息，您同意免除上述个人信息的接收和/或使用方在按照本法律声明所述情况下进行信息披露和使用而导致的或可能导致的所有索赔、责任和损失。
            </p>
            <p>
              3、用户明确同意使用本平台服务的使用所存在的风险将完全由您自己承担，因用户使用晟行服务而产生的一切后果也由您自己承担，晟行对您不承担任何责任。
            </p>
            <p>三、联系我们</p>
            <p>
              如您对本声明或您个人信息的相关事宜有任何问题、意见或建议，您可随时拨打我们的客服电话：15117971875，或与我们的在线客服取得联系。
            </p>
          </div>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import IntroNav from "@/components/IntroNav";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
    IntroNav,
  },
  data() {
    return {};
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {},
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  .about_left {
    width: 320px;
    padding: 40px;
  }
  .about_right {
    min-height: 400px;
    width: 880px;
    background: #fff;
    padding: 40px;
  }
}
</style>

