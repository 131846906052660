export default {
    "series": [{
            "level_name": "紧凑型车",
            "level_id": "3",
            "list": [{
                    "id": 587,
                    "name": "宝来"
                },
                {
                    "id": 588,
                    "name": "高尔夫"
                },
                {
                    "id": 589,
                    "name": "速腾"
                },
                {
                    "id": 593,
                    "name": "高尔夫·嘉旅"
                },
                {
                    "id": 627,
                    "name": "桑塔纳"
                },
                {
                    "id": 628,
                    "name": "朗逸"
                },
                {
                    "id": 630,
                    "name": "凌渡"
                },
                {
                    "id": 796,
                    "name": "卡罗拉"
                },
                {
                    "id": 832,
                    "name": "雷凌"
                },
                {
                    "id": 841,
                    "name": "福克斯"
                },
                {
                    "id": 842,
                    "name": "福克斯Active"
                },
                {
                    "id": 843,
                    "name": "福睿斯"
                }
            ]
        },
        {
            "level_name": "中型车",
            "level_id": "4",
            "list": [{
                    "id": 71,
                    "name": "雅阁"
                },
                {
                    "id": 88,
                    "name": "INSPIRE"
                },
                {
                    "id": 280,
                    "name": "标致508"
                },
                {
                    "id": 594,
                    "name": "迈腾"
                },
                {
                    "id": 596,
                    "name": "一汽-大众CC"
                },
                {
                    "id": 631,
                    "name": "帕萨特"
                },
                {
                    "id": 798,
                    "name": "亚洲龙"
                },
                {
                    "id": 834,
                    "name": "凯美瑞"
                },
                {
                    "id": 844,
                    "name": "金牛座"
                },
                {
                    "id": 845,
                    "name": "蒙迪欧"
                },
                {
                    "id": 2175,
                    "name": "名图"
                },
                {
                    "id": 2177,
                    "name": "索纳塔"
                }
            ]
        },
        {
            "level_name": "SUV",
            "level_id": "7,8,9,10,11",
            "list": [{
                    "id": 74,
                    "name": "冠道"
                },
                {
                    "id": 92,
                    "name": "本田UR-V"
                },
                {
                    "id": 199,
                    "name": "宝马X4 M"
                },
                {
                    "id": 286,
                    "name": "标致5008"
                },
                {
                    "id": 600,
                    "name": "探岳"
                },
                {
                    "id": 602,
                    "name": "探岳X"
                },
                {
                    "id": 638,
                    "name": "途观L"
                },
                {
                    "id": 640,
                    "name": "途观X"
                },
                {
                    "id": 838,
                    "name": "汉兰达"
                },
                {
                    "id": 848,
                    "name": "锐界"
                },
                {
                    "id": 870,
                    "name": "撼路者"
                },
                {
                    "id": 2184,
                    "name": "胜达"
                }
            ]
        }
    ],
    "brands": [{
            "id": 24,
            "name": "本田",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/14.png"
        },
        {
            "id": 26,
            "name": "宝马",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/15.png"
        },
        {
            "id": 30,
            "name": "标致",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/13.png"
        },
        {
            "id": 74,
            "name": "大众",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/1.png"
        },
        {
            "id": 104,
            "name": "丰田",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/3.png"
        },
        {
            "id": 105,
            "name": "福特",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/8.png"
        },
        {
            "id": 110,
            "name": "菲亚特",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/11.png"
        },
        {
            "id": 200,
            "name": "克莱斯勒",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/9.png"
        },
        {
            "id": 225,
            "name": "雷诺",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/10.png"
        },
        {
            "id": 250,
            "name": "名爵",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/20.png"
        },
        {
            "id": 287,
            "name": "奇瑞",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/26.png"
        },
        {
            "id": 296,
            "name": "荣威",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/19.png"
        },
        {
            "id": 375,
            "name": "现代",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/12.png"
        },
        {
            "id": 403,
            "name": "中华",
            "logo_url": "http://wapi.shengxingdt.com/uploads/images/brand/22.png"
        }
    ]
}