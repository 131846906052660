import request from '@/utils/request'

export function getIndexNews(params) {
    return request({
        url: '/index/news',
        method: 'get',
        params
    })
}

export function getAllNews(params) {
    return request({
        url: '/news',
        method: 'get',
        params
    })
}

export function getNewDetail(id, params) {
    return request({
        url: `/news/${id}`,
        method: 'get',
        params
    })
}