<template>
  <div class="container">
    <PageHeader current="groupon" :headerCityChange="headerCityChange" />
    <div class="main-container">
      <div class="w1200" style="width: 980px">
        <a-breadcrumb separator=">" class="w-breadcrumb" style="margin: 15px 0">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 团购活动 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <div class="groupon-brandlist">
        <div class="wrapper groupon-wrapper"  v-if="list.length > 0">
          <a-skeleton
            :loading="loading"
            active
            :paragraph="{ rows: 3 }"
            :title="false"
          >
            <ul class="clearfix">
              <li v-for="brand in topBrands" :key="brand.id">
                <router-link
                  :to="{
                    name: 'GrouponDetail',
                    params: { id: brand.groupon_id },
                  }"
                >
                  <img :src="brand.logo_url" width="40" height="40" />
                  <p>{{ brand.name }}</p>
                </router-link>
              </li>
              <li class="last" v-show="topBrands.length >= 14">
                <a
                  href="javascript:;"
                  class="J_barndMore"
                  @click="more = !more"
                >
                  <p>更多<i class="icon"></i></p>
                </a>
              </li>
            </ul>
            <div class="more-brand-pop clearfix" v-show="more">
              <div class="sub-detail fl">
                <dl
                  class="clearfix"
                  v-for="b in brands.slice(0, 9)"
                  :key="b.id"
                >
                  <dt>{{ b.letter }}</dt>
                  <dd class="clearfix">
                    <router-link
                      :to="{
                        name: 'GrouponDetail',
                        params: { id: bb.groupon_id },
                      }"
                      v-for="bb in b.brands"
                      :key="bb.id"
                    >
                      {{ bb.name }}
                    </router-link>
                  </dd>
                </dl>
              </div>
              <div class="sub-detail last fl">
                <dl
                  class="clearfix"
                  v-for="b in brands.slice(9, 18)"
                  :key="b.id"
                >
                  <dt>{{ b.letter }}</dt>
                  <dd class="clearfix">
                    <router-link
                      :to="{
                        name: 'GrouponDetail',
                        params: { id: bb.groupon_id },
                      }"
                      v-for="bb in b.brands"
                      :key="bb.id"
                      >{{ bb.name }}
                    </router-link>
                  </dd>
                </dl>
              </div>
              <!-- <a class="more-brand-close" href="javascript:;">x</a> -->
            </div>
          </a-skeleton>
        </div>
      </div>
      <div class="wrapper">
        <div class="title mod-title clearfix">
          <h3>
            <i class="border"></i>品牌专场团购会
            <p>
              <b>HOT!</b>共有<span>{{ list.length }}</span
              >场团购会，本期已报名<span>{{ totalPeople }}</span
              >人
            </p>
          </h3>
          <!--<a href="/cust.html" class="more">查看全部团购品牌>></a>-->
        </div>
        <div class="buy-box">
          <a-skeleton
            :loading="loading"
            active
            :paragraph="{ rows: 14 }"
            :title="false"
          >
            <ul class="clearfix" v-if="list.length > 0">
              <li v-for="item in list" :key="item.id">
                <router-link
                  :to="{ name: 'GrouponDetail', params: { id: item.id } }"
                >
                  <div class="pic">
                    <img :src="item.brand.logo_url" />
                  </div>
                  <div class="name">{{ item.title }}</div>
                </router-link>
                <div class="style-list">
                  <router-link
                    :to="{ name: 'Series', params: { id: s.id } }"
                    v-for="s in item.series"
                    :key="s.id"
                  >
                    <span>{{ s.name }}</span>
                  </router-link>
                </div>
                <div class="bottom">
                  <p>
                    已报名<em> {{ item.current_people }}</em
                    >人
                  </p>
                  <p>{{ item.start_date }}-{{ item.end_date }}</p>
                  <router-link
                    :to="{ name: 'GrouponDetail', params: { id: item.id } }"
                    ><span>详情</span>
                  </router-link>
                </div>
              </li>
            </ul>
            <div class="" v-if="list.length ==0">
                <NoData text="本地暂未开通团购活动" />
            </div>
          </a-skeleton>
        </div>
      </div>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import NoData from '@/components/NoData'
import { getGroupons } from "@/api/groupon";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
    NoData
  },
  data() {
    return {
      list: [],
      brands: [],
      totalPeople: 0,
      topBrands: [],
      more: false,
      loading: true,
      cur_city: "北京",
      cur_cid: 100,
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getCityId();
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      this.loading = true;
      getGroupons({cid: this.cur_cid}).then((resp) => {
        this.list = resp.data;
        this.initBrands(this.list);
        this.loading = false;
      });
    },
    initBrands() {
      let letters = []; // 数据列表
      let brandMap = {}; // 每一个索引指向一个数组，这个数组存储该字母索引的朋友项

      let allBrandMap = {};
      this.list.forEach((item) => {
        this.totalPeople += item.current_people;
        item.brand.groupon_id = item.id;
        allBrandMap[item.brand.id] = item.brand;
      });

      for (let id in allBrandMap) {
        const letter = allBrandMap[id].letter;
        brandMap[letter] || (brandMap[letter] = []);
        brandMap[letter].push(allBrandMap[id]);
        if (this.topBrands.length < 14) {
          this.topBrands.push(allBrandMap[id]);
        }
      }

      for (let letter in brandMap) {
        letters.push({
          letter: letter,
          top: 0,
          height: 0,
          brands: brandMap[letter],
        });
      }

      // 按首字母排序
      // 字符串比较不能用减号得出大小，返回都是NaN
      // 因此这里需要通过小于号比较
      letters.sort((a, b) => {
        return a.letter <= b.letter ? -1 : 1;
      });

      this.brands = letters;

      console.log(this.topBrands);
    },

    getCityId() {
      let curCity = localStorage.getItem("_cur_city");
      if (curCity) {
        let curCityData = JSON.parse(curCity);
        this.cur_cid = curCityData.c_id;
        this.cur_city = curCityData.c;
        return curCityData.c_id;
      }

      return 100;
    },
    headerCityChange() {
      console.log('headerCityChange >>>>')
      this.getCityId()
      this.getData()
    }
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.main-container {
  background-color: #f5f5f5;
}
.groupon-brandlist {
  .groupon-wrapper {
    position: relative;
    z-index: 4;
    ul {
      overflow: hidden;
      background-color: #fff;
      padding: 0 9.5px;
      li {
        padding: 15.5px 0;
        width: 40px;
        float: left;
        width: 64px;
        padding: 16.5px 0;
        text-align: center;
        overflow: hidden;
        img {
          display: inline-block;
          width: 40px;
          height: 40px;
        }
        a {
          display: block;
          color: #666;
          font-size: 12px;
        }
        a:hover {
          color: #ff4f12;
        }
        p {
          line-height: 14px;
          margin-bottom: 0;
        }
      }
      li.last {
        p {
          padding: 20px 0;
        }
      }
    }
    .more-brand-pop {
      z-index: 10;
      top: 88px;
      left: 0;
      width: 100%;
      box-shadow: 1px 0 6px #f4f4f4;
      border-top: 0;
      position: absolute;
      background-color: #fff;
      min-height: 150px;
      border-bottom: 1px solid #f5f5f5;
      height: auto;
      padding-bottom: 5px;
      .sub-detail {
        width: 490px;
        padding: 15px 0 0;
        dl {
          margin-bottom: 0;
        }
        dt {
          color: #e46007;
          font-size: 14px;
          float: left;
          padding: 0 8px 0 28px;
          font-weight: 700;
          border-right: 1px solid #e3e3e3;
          height: 12px;
          line-height: 12px;
          margin: 2px 6px 2px 0;
          width: 10px;
          text-align: center;
          margin-bottom: 0;
        }
        dd {
          width: 435px;
          border-right: 0;
          float: left;
          font-size: 14px;
          margin-bottom: 0;
          a {
            padding: 0 8px;
            float: left;
            color: #333;
            margin-bottom: 8px;
            height: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
.wrapper {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  .mod-title {
    line-height: 70px;
    height: 70px;
    margin-top: 16px;
    h3 {
      color: #333;
      font-size: 24px;
      float: left;
      .border {
        display: inline-block;
        width: 3px;
        height: 24px;
        background-color: #ff4f12;
        position: relative;
        top: 3px;
        margin-right: 10px;
      }

      p {
        color: #666;
        font-size: 14px;
        display: inline-block;
        margin-left: 10px;
        height: 60px;
        b {
          font-size: 14px;
          color: #ff4f12;
          // font-weight: 100;
          margin-right: 4px;
        }
        span {
          color: #ff4f12;
        }
      }
    }
  }

  .buy-box {
    ul {
      width: 102%;
      overflow: hidden;
      li {
        width: 236px;
        height: 240px;
        background-color: #fff;
        float: left;
        margin-right: 12px;
        margin-bottom: 12px;
        .pic {
          width: 75px;
          height: 89px;
          margin: 0 auto;
          padding-top: 14px;
          img {
            width: 75px;
            height: 75px;
            border: 0;
          }
        }
        .name {
          line-height: 40px;
          color: #333;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          height: 40px;
          overflow: hidden;
        }
        .style-list {
          text-align: center;
          line-height: 20px;
          font-size: 14px;
          padding: 0 20px;
          margin-bottom: 8px;
          height: 40px;
          overflow: hidden;
          span {
            color: #4484d5;
            padding: 0 5px;
            font-weight: 600;
          }
        }
        .bottom {
          border-top: 1px solid #ededed;
          position: relative;
          padding: 8px 15px;
          p {
            line-height: 24px;
            font-size: 14px;
            color: #666;
            margin-bottom: 0;
            em {
              color: #ff4f12;
              font-style: normal;
            }
          }
          a {
            display: block;
          }
          span {
            position: absolute;
            right: 15px;
            top: 18px;
            background-color: #ff4f12;
            line-height: 32px;
            height: 32px;
            width: 56px;
            text-align: center;
            color: #fff;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
</style>

