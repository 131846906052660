<template>
  <div class="n-head" style="border-bottom: none">
    <div class="header" style="padding-top: 0px">
      <div class="w1200 clearFix" style="height: 75px">
        <div class="fl logo" style="padding: 0">
          <router-link :to="{ name: 'DealerIndex' }">
            <img style="width: 90px; height: 90px" :src="brand.logo_url" />
          </router-link>
        </div>
        <div class="jxs_title flexL">
          <h2>{{ dealer.name }}</h2>
          <p class="jxs_infor">
            电话：<span class="tel">{{ dealer.sell_phone }}</span
            ><span class="time">24小时</span>
            <span class="area">{{ dealer.area }}</span>
          </p>
        </div>
      </div>
      <div id="jxs_navbox" class="clearFix">
        <ul id="" class="w1200 jxs_nav clearFix">
          <li class="jxs_index fl">
            <router-link
              :to="{ name: 'DealerIndex' }"
              :class="current == 'index' ? 'on' : ''"
              >首页</router-link
            >
          </li>
          <li class="jxs_cxbj fl">
            <router-link
              :to="{ name: 'DealerSeries' }"
              :class="current == 'series' ? 'on' : ''"
              >车型报价</router-link
            >
          </li>
          <li class="jxs_cxbj fl">
            <router-link
              :to="{ name: 'DealerNews' }"
              :class="current == 'news' ? 'on' : ''"
              >店内动态</router-link
            >
          </li>
          <li class="jxs_xjsj fl">
            <router-link
              :to="{ name: 'DealerQuote' }"
              :class="current == 'quote' ? 'on' : ''"
              >询价/试驾</router-link
            >
          </li>
          <li class="jxs_gsjs fl">
            <router-link
              :to="{ name: 'DealerInfo' }"
              :class="current == 'about' ? 'on' : ''"
              >公司介绍</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getDealerInfo } from "@/api/dealer";
export default {
  name: "TopMenu",
  data() {
    return {
      id: 0,
      dealer: {},
      brand: {},
    };
  },
  props: {
    current: {
      type: String,
      default: "",
      required: true,
    },
  },
  mounted() {},
  computed: {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    getData() {
      getDealerInfo(this.id).then((resp) => {
        this.dealer = resp.data;
        this.brand = this.dealer.brands[0];
        console.log("----", this.brand);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .jxs_title {
    padding-left: 30px;
    border-left: 1px solid #c1c1c1;
    margin-top: 15px;
    margin-left: 24px;
    overflow: hidden;
    h2 {
      font-size: 30px;
      font-weight: normal;
      color: #333;
      margin-right: 50px;
      line-height: 60px;
    }
    p.jxs_infor {
      line-height: 16px;
      font-size: 14px;
      margin-top: 5px;
      .tel {
        color: #cc2727;
        padding: 0 5px;
      }
      span {
        display: inline-block;
      }
      .area {
        background-color: #cc2727;
        color: #fff;
        line-height: 18px;
        padding: 0 3px;
        border-radius: 3px;
        margin-right: 5px;
      }
    }
  }

  #jxs_navbox {
    box-shadow: none;
    background-color: #e02b31;
    border-top: none;
    // overflow: hidden;
    height: 50px;
    clear: both;
    ul.jxs_nav {
      li {
        float: left;
        a {
          margin: 0;
          padding: 0 35px;
          display: inline-block;
          font-size: 16px;
          height: 50px;
          line-height: 50px;
          font-weight: 600;

          color: #fff;
        }
        a.on:hover,
        a.on {
          color: #e02b31;
          background-color: #fff;
          position: relative;
          font-weight: 700;
        }
        a.on::after {
          position: absolute;
          content: "";
          display: inline-block;
          width: 100%;
          height: 4px;
          background-color: #e02b31;
          top: -4px;
          left: 0;
        }
      }
    }
  }
}
</style>
