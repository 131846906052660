export default [{
        "label": "北京市",
        "value": 11,
        "children": [{
            "label": "北京市",
            "value": 100
        }]
    },
    {
        "label": "天津市",
        "value": 12,
        "children": [{
            "label": "天津市",
            "value": 102
        }]
    },
    {
        "label": "河北省",
        "value": 13,
        "children": [{
                "label": "石家庄市",
                "value": 104
            },
            {
                "label": "唐山市",
                "value": 105
            },
            {
                "label": "秦皇岛市",
                "value": 106
            },
            {
                "label": "邯郸市",
                "value": 107
            },
            {
                "label": "邢台市",
                "value": 108
            },
            {
                "label": "保定市",
                "value": 109
            },
            {
                "label": "张家口市",
                "value": 110
            },
            {
                "label": "承德市",
                "value": 111
            },
            {
                "label": "沧州市",
                "value": 112
            },
            {
                "label": "廊坊市",
                "value": 113
            },
            {
                "label": "衡水市",
                "value": 114
            }
        ]
    },
    {
        "label": "山西省",
        "value": 14,
        "children": [{
                "label": "太原市",
                "value": 116
            },
            {
                "label": "大同市",
                "value": 117
            },
            {
                "label": "阳泉市",
                "value": 118
            },
            {
                "label": "长治市",
                "value": 119
            },
            {
                "label": "晋城市",
                "value": 120
            },
            {
                "label": "朔州市",
                "value": 121
            },
            {
                "label": "晋中市",
                "value": 122
            },
            {
                "label": "运城市",
                "value": 123
            },
            {
                "label": "忻州市",
                "value": 124
            },
            {
                "label": "临汾市",
                "value": 125
            },
            {
                "label": "吕梁市",
                "value": 126
            }
        ]
    },
    {
        "label": "内蒙古自治区",
        "value": 15,
        "children": [{
                "label": "呼和浩特市",
                "value": 128
            },
            {
                "label": "包头市",
                "value": 129
            },
            {
                "label": "乌海市",
                "value": 130
            },
            {
                "label": "赤峰市",
                "value": 131
            },
            {
                "label": "通辽市",
                "value": 132
            },
            {
                "label": "鄂尔多斯市",
                "value": 133
            },
            {
                "label": "呼伦贝尔市",
                "value": 134
            },
            {
                "label": "巴彦淖尔市",
                "value": 135
            },
            {
                "label": "乌兰察布市",
                "value": 136
            },
            {
                "label": "兴安盟",
                "value": 137
            },
            {
                "label": "锡林郭勒盟",
                "value": 138
            },
            {
                "label": "阿拉善盟",
                "value": 139
            }
        ]
    },
    {
        "label": "辽宁省",
        "value": 21,
        "children": [{
                "label": "沈阳市",
                "value": 141
            },
            {
                "label": "大连市",
                "value": 142
            },
            {
                "label": "鞍山市",
                "value": 143
            },
            {
                "label": "抚顺市",
                "value": 144
            },
            {
                "label": "本溪市",
                "value": 145
            },
            {
                "label": "丹东市",
                "value": 146
            },
            {
                "label": "锦州市",
                "value": 147
            },
            {
                "label": "营口市",
                "value": 148
            },
            {
                "label": "阜新市",
                "value": 149
            },
            {
                "label": "辽阳市",
                "value": 150
            },
            {
                "label": "盘锦市",
                "value": 151
            },
            {
                "label": "铁岭市",
                "value": 152
            },
            {
                "label": "朝阳市",
                "value": 153
            },
            {
                "label": "葫芦岛市",
                "value": 154
            }
        ]
    },
    {
        "label": "吉林省",
        "value": 22,
        "children": [{
                "label": "长春市",
                "value": 156
            },
            {
                "label": "吉林市",
                "value": 157
            },
            {
                "label": "四平市",
                "value": 158
            },
            {
                "label": "辽源市",
                "value": 159
            },
            {
                "label": "通化市",
                "value": 160
            },
            {
                "label": "白山市",
                "value": 161
            },
            {
                "label": "松原市",
                "value": 162
            },
            {
                "label": "白城市",
                "value": 163
            },
            {
                "label": "延边朝鲜族自治州",
                "value": 164
            }
        ]
    },
    {
        "label": "黑龙江省",
        "value": 23,
        "children": [{
                "label": "哈尔滨市",
                "value": 166
            },
            {
                "label": "齐齐哈尔市",
                "value": 167
            },
            {
                "label": "鸡西市",
                "value": 168
            },
            {
                "label": "鹤岗市",
                "value": 169
            },
            {
                "label": "双鸭山市",
                "value": 170
            },
            {
                "label": "大庆市",
                "value": 171
            },
            {
                "label": "伊春市",
                "value": 172
            },
            {
                "label": "佳木斯市",
                "value": 173
            },
            {
                "label": "七台河市",
                "value": 174
            },
            {
                "label": "牡丹江市",
                "value": 175
            },
            {
                "label": "黑河市",
                "value": 176
            },
            {
                "label": "绥化市",
                "value": 177
            },
            {
                "label": "大兴安岭地区",
                "value": 178
            }
        ]
    },
    {
        "label": "上海市",
        "value": 31,
        "children": [{
            "label": "上海市",
            "value": 180
        }]
    },
    {
        "label": "江苏省",
        "value": 32,
        "children": [{
                "label": "南京市",
                "value": 182
            },
            {
                "label": "无锡市",
                "value": 183
            },
            {
                "label": "徐州市",
                "value": 184
            },
            {
                "label": "常州市",
                "value": 185
            },
            {
                "label": "苏州市",
                "value": 186
            },
            {
                "label": "南通市",
                "value": 187
            },
            {
                "label": "连云港市",
                "value": 188
            },
            {
                "label": "淮安市",
                "value": 189
            },
            {
                "label": "盐城市",
                "value": 190
            },
            {
                "label": "扬州市",
                "value": 191
            },
            {
                "label": "镇江市",
                "value": 192
            },
            {
                "label": "泰州市",
                "value": 193
            },
            {
                "label": "宿迁市",
                "value": 194
            }
        ]
    },
    {
        "label": "浙江省",
        "value": 33,
        "children": [{
                "label": "杭州市",
                "value": 196
            },
            {
                "label": "宁波市",
                "value": 197
            },
            {
                "label": "温州市",
                "value": 198
            },
            {
                "label": "嘉兴市",
                "value": 199
            },
            {
                "label": "湖州市",
                "value": 200
            },
            {
                "label": "绍兴市",
                "value": 201
            },
            {
                "label": "金华市",
                "value": 202
            },
            {
                "label": "衢州市",
                "value": 203
            },
            {
                "label": "舟山市",
                "value": 204
            },
            {
                "label": "台州市",
                "value": 205
            },
            {
                "label": "丽水市",
                "value": 206
            },
            {
                "label": "舟山群岛新区",
                "value": 207
            }
        ]
    },
    {
        "label": "安徽省",
        "value": 34,
        "children": [{
                "label": "合肥市",
                "value": 209
            },
            {
                "label": "芜湖市",
                "value": 210
            },
            {
                "label": "蚌埠市",
                "value": 211
            },
            {
                "label": "淮南市",
                "value": 212
            },
            {
                "label": "马鞍山市",
                "value": 213
            },
            {
                "label": "淮北市",
                "value": 214
            },
            {
                "label": "铜陵市",
                "value": 215
            },
            {
                "label": "安庆市",
                "value": 216
            },
            {
                "label": "黄山市",
                "value": 217
            },
            {
                "label": "滁州市",
                "value": 218
            },
            {
                "label": "阜阳市",
                "value": 219
            },
            {
                "label": "宿州市",
                "value": 220
            },
            {
                "label": "六安市",
                "value": 221
            },
            {
                "label": "亳州市",
                "value": 222
            },
            {
                "label": "池州市",
                "value": 223
            },
            {
                "label": "宣城市",
                "value": 224
            }
        ]
    },
    {
        "label": "福建省",
        "value": 35,
        "children": [{
                "label": "福州市",
                "value": 226
            },
            {
                "label": "厦门市",
                "value": 227
            },
            {
                "label": "莆田市",
                "value": 228
            },
            {
                "label": "三明市",
                "value": 229
            },
            {
                "label": "泉州市",
                "value": 230
            },
            {
                "label": "漳州市",
                "value": 231
            },
            {
                "label": "南平市",
                "value": 232
            },
            {
                "label": "龙岩市",
                "value": 233
            },
            {
                "label": "宁德市",
                "value": 234
            }
        ]
    },
    {
        "label": "江西省",
        "value": 36,
        "children": [{
                "label": "南昌市",
                "value": 236
            },
            {
                "label": "景德镇市",
                "value": 237
            },
            {
                "label": "萍乡市",
                "value": 238
            },
            {
                "label": "九江市",
                "value": 239
            },
            {
                "label": "新余市",
                "value": 240
            },
            {
                "label": "鹰潭市",
                "value": 241
            },
            {
                "label": "赣州市",
                "value": 242
            },
            {
                "label": "吉安市",
                "value": 243
            },
            {
                "label": "宜春市",
                "value": 244
            },
            {
                "label": "抚州市",
                "value": 245
            },
            {
                "label": "上饶市",
                "value": 246
            }
        ]
    },
    {
        "label": "山东省",
        "value": 37,
        "children": [{
                "label": "济南市",
                "value": 248
            },
            {
                "label": "青岛市",
                "value": 249
            },
            {
                "label": "淄博市",
                "value": 250
            },
            {
                "label": "枣庄市",
                "value": 251
            },
            {
                "label": "东营市",
                "value": 252
            },
            {
                "label": "烟台市",
                "value": 253
            },
            {
                "label": "潍坊市",
                "value": 254
            },
            {
                "label": "济宁市",
                "value": 255
            },
            {
                "label": "泰安市",
                "value": 256
            },
            {
                "label": "威海市",
                "value": 257
            },
            {
                "label": "日照市",
                "value": 258
            },
            {
                "label": "莱芜市",
                "value": 259
            },
            {
                "label": "临沂市",
                "value": 260
            },
            {
                "label": "德州市",
                "value": 261
            },
            {
                "label": "聊城市",
                "value": 262
            },
            {
                "label": "滨州市",
                "value": 263
            },
            {
                "label": "菏泽市",
                "value": 264
            }
        ]
    },
    {
        "label": "河南省",
        "value": 41,
        "children": [{
                "label": "郑州市",
                "value": 266
            },
            {
                "label": "开封市",
                "value": 267
            },
            {
                "label": "洛阳市",
                "value": 268
            },
            {
                "label": "平顶山市",
                "value": 269
            },
            {
                "label": "安阳市",
                "value": 270
            },
            {
                "label": "鹤壁市",
                "value": 271
            },
            {
                "label": "新乡市",
                "value": 272
            },
            {
                "label": "焦作市",
                "value": 273
            },
            {
                "label": "濮阳市",
                "value": 274
            },
            {
                "label": "许昌市",
                "value": 275
            },
            {
                "label": "漯河市",
                "value": 276
            },
            {
                "label": "三门峡市",
                "value": 277
            },
            {
                "label": "南阳市",
                "value": 278
            },
            {
                "label": "商丘市",
                "value": 279
            },
            {
                "label": "信阳市",
                "value": 280
            },
            {
                "label": "周口市",
                "value": 281
            },
            {
                "label": "驻马店市",
                "value": 282
            },
            {
                "label": "河南省直辖",
                "value": 283
            },
            {
                "label": "济源市",
                "value": 284
            }
        ]
    },
    {
        "label": "湖北省",
        "value": 42,
        "children": [{
                "label": "武汉市",
                "value": 286
            },
            {
                "label": "黄石市",
                "value": 287
            },
            {
                "label": "十堰市",
                "value": 288
            },
            {
                "label": "宜昌市",
                "value": 289
            },
            {
                "label": "襄阳市",
                "value": 290
            },
            {
                "label": "鄂州市",
                "value": 291
            },
            {
                "label": "荆门市",
                "value": 292
            },
            {
                "label": "孝感市",
                "value": 293
            },
            {
                "label": "荆州市",
                "value": 294
            },
            {
                "label": "黄冈市",
                "value": 295
            },
            {
                "label": "咸宁市",
                "value": 296
            },
            {
                "label": "随州市",
                "value": 297
            },
            {
                "label": "恩施土家族苗族自治州",
                "value": 298
            },
            {
                "label": "湖北省直辖",
                "value": 299
            },
            {
                "label": "仙桃市",
                "value": 300
            },
            {
                "label": "潜江市",
                "value": 301
            },
            {
                "label": "天门市",
                "value": 302
            },
            {
                "label": "神农架林区",
                "value": 303
            }
        ]
    },
    {
        "label": "湖南省",
        "value": 43,
        "children": [{
                "label": "长沙市",
                "value": 305
            },
            {
                "label": "株洲市",
                "value": 306
            },
            {
                "label": "湘潭市",
                "value": 307
            },
            {
                "label": "衡阳市",
                "value": 308
            },
            {
                "label": "邵阳市",
                "value": 309
            },
            {
                "label": "岳阳市",
                "value": 310
            },
            {
                "label": "常德市",
                "value": 311
            },
            {
                "label": "张家界市",
                "value": 312
            },
            {
                "label": "益阳市",
                "value": 313
            },
            {
                "label": "郴州市",
                "value": 314
            },
            {
                "label": "永州市",
                "value": 315
            },
            {
                "label": "怀化市",
                "value": 316
            },
            {
                "label": "娄底市",
                "value": 317
            },
            {
                "label": "湘西土家族苗族自治州",
                "value": 318
            }
        ]
    },
    {
        "label": "广东省",
        "value": 44,
        "children": [{
                "label": "广州市",
                "value": 320
            },
            {
                "label": "韶关市",
                "value": 321
            },
            {
                "label": "深圳市",
                "value": 322
            },
            {
                "label": "珠海市",
                "value": 323
            },
            {
                "label": "汕头市",
                "value": 324
            },
            {
                "label": "佛山市",
                "value": 325
            },
            {
                "label": "江门市",
                "value": 326
            },
            {
                "label": "湛江市",
                "value": 327
            },
            {
                "label": "茂名市",
                "value": 328
            },
            {
                "label": "肇庆市",
                "value": 329
            },
            {
                "label": "惠州市",
                "value": 330
            },
            {
                "label": "梅州市",
                "value": 331
            },
            {
                "label": "汕尾市",
                "value": 332
            },
            {
                "label": "河源市",
                "value": 333
            },
            {
                "label": "阳江市",
                "value": 334
            },
            {
                "label": "清远市",
                "value": 335
            },
            {
                "label": "东莞市",
                "value": 336
            },
            {
                "label": "中山市",
                "value": 337
            },
            {
                "label": "潮州市",
                "value": 338
            },
            {
                "label": "揭阳市",
                "value": 339
            },
            {
                "label": "云浮市",
                "value": 340
            }
        ]
    },
    {
        "label": "广西壮族自治区",
        "value": 45,
        "children": [{
                "label": "南宁市",
                "value": 342
            },
            {
                "label": "柳州市",
                "value": 343
            },
            {
                "label": "桂林市",
                "value": 344
            },
            {
                "label": "梧州市",
                "value": 345
            },
            {
                "label": "北海市",
                "value": 346
            },
            {
                "label": "防城港市",
                "value": 347
            },
            {
                "label": "钦州市",
                "value": 348
            },
            {
                "label": "贵港市",
                "value": 349
            },
            {
                "label": "玉林市",
                "value": 350
            },
            {
                "label": "百色市",
                "value": 351
            },
            {
                "label": "贺州市",
                "value": 352
            },
            {
                "label": "河池市",
                "value": 353
            },
            {
                "label": "来宾市",
                "value": 354
            },
            {
                "label": "崇左市",
                "value": 355
            }
        ]
    },
    {
        "label": "海南省",
        "value": 46,
        "children": [{
                "label": "海口市",
                "value": 357
            },
            {
                "label": "三亚市",
                "value": 358
            },
            {
                "label": "三沙市",
                "value": 359
            },
            {
                "label": "儋州市",
                "value": 360
            },
            {
                "label": "海南省直辖",
                "value": 361
            },
            {
                "label": "五指山市",
                "value": 362
            },
            {
                "label": "琼海市",
                "value": 363
            },
            {
                "label": "文昌市",
                "value": 364
            },
            {
                "label": "万宁市",
                "value": 365
            },
            {
                "label": "东方市",
                "value": 366
            },
            {
                "label": "定安县",
                "value": 367
            },
            {
                "label": "屯昌县",
                "value": 368
            },
            {
                "label": "澄迈县",
                "value": 369
            },
            {
                "label": "临高县",
                "value": 370
            },
            {
                "label": "白沙黎族自治县",
                "value": 371
            },
            {
                "label": "昌江黎族自治县",
                "value": 372
            },
            {
                "label": "乐东黎族自治县",
                "value": 373
            },
            {
                "label": "陵水黎族自治县",
                "value": 374
            },
            {
                "label": "保亭黎族苗族自治县",
                "value": 375
            },
            {
                "label": "琼中黎族苗族自治县",
                "value": 376
            }
        ]
    },
    {
        "label": "重庆市",
        "value": 50,
        "children": [{
                "label": "重庆市",
                "value": 378
            },
            {
                "label": "重庆市直辖",
                "value": 379
            }
        ]
    },
    {
        "label": "四川省",
        "value": 51,
        "children": [{
                "label": "成都市",
                "value": 381
            },
            {
                "label": "自贡市",
                "value": 382
            },
            {
                "label": "攀枝花市",
                "value": 383
            },
            {
                "label": "泸州市",
                "value": 384
            },
            {
                "label": "德阳市",
                "value": 385
            },
            {
                "label": "绵阳市",
                "value": 386
            },
            {
                "label": "广元市",
                "value": 387
            },
            {
                "label": "遂宁市",
                "value": 388
            },
            {
                "label": "内江市",
                "value": 389
            },
            {
                "label": "乐山市",
                "value": 390
            },
            {
                "label": "南充市",
                "value": 391
            },
            {
                "label": "眉山市",
                "value": 392
            },
            {
                "label": "宜宾市",
                "value": 393
            },
            {
                "label": "广安市",
                "value": 394
            },
            {
                "label": "达州市",
                "value": 395
            },
            {
                "label": "雅安市",
                "value": 396
            },
            {
                "label": "巴中市",
                "value": 397
            },
            {
                "label": "资阳市",
                "value": 398
            },
            {
                "label": "阿坝藏族羌族自治州",
                "value": 399
            },
            {
                "label": "甘孜藏族自治州",
                "value": 400
            },
            {
                "label": "凉山彝族自治州",
                "value": 401
            }
        ]
    },
    {
        "label": "贵州省",
        "value": 52,
        "children": [{
                "label": "贵阳市",
                "value": 403
            },
            {
                "label": "六盘水市",
                "value": 404
            },
            {
                "label": "遵义市",
                "value": 405
            },
            {
                "label": "安顺市",
                "value": 406
            },
            {
                "label": "毕节市",
                "value": 407
            },
            {
                "label": "铜仁市",
                "value": 408
            },
            {
                "label": "黔西南布依族苗族自治州",
                "value": 409
            },
            {
                "label": "黔东南苗族侗族自治州",
                "value": 410
            },
            {
                "label": "黔南布依族苗族自治州",
                "value": 411
            }
        ]
    },
    {
        "label": "云南省",
        "value": 53,
        "children": [{
                "label": "昆明市",
                "value": 413
            },
            {
                "label": "曲靖市",
                "value": 414
            },
            {
                "label": "玉溪市",
                "value": 415
            },
            {
                "label": "保山市",
                "value": 416
            },
            {
                "label": "昭通市",
                "value": 417
            },
            {
                "label": "丽江市",
                "value": 418
            },
            {
                "label": "普洱市",
                "value": 419
            },
            {
                "label": "临沧市",
                "value": 420
            },
            {
                "label": "楚雄彝族自治州",
                "value": 421
            },
            {
                "label": "红河哈尼族彝族自治州",
                "value": 422
            },
            {
                "label": "文山壮族苗族自治州",
                "value": 423
            },
            {
                "label": "西双版纳傣族自治州",
                "value": 424
            },
            {
                "label": "大理白族自治州",
                "value": 425
            },
            {
                "label": "德宏傣族景颇族自治州",
                "value": 426
            },
            {
                "label": "怒江傈僳族自治州",
                "value": 427
            },
            {
                "label": "迪庆藏族自治州",
                "value": 428
            }
        ]
    },
    {
        "label": "西藏自治区",
        "value": 54,
        "children": [{
                "label": "拉萨市",
                "value": 430
            },
            {
                "label": "日喀则市",
                "value": 431
            },
            {
                "label": "昌都市",
                "value": 432
            },
            {
                "label": "林芝市",
                "value": 433
            },
            {
                "label": "山南市",
                "value": 434
            },
            {
                "label": "那曲地区",
                "value": 435
            },
            {
                "label": "阿里地区",
                "value": 436
            }
        ]
    },
    {
        "label": "陕西省",
        "value": 61,
        "children": [{
                "label": "西安市",
                "value": 438
            },
            {
                "label": "铜川市",
                "value": 439
            },
            {
                "label": "宝鸡市",
                "value": 440
            },
            {
                "label": "咸阳市",
                "value": 441
            },
            {
                "label": "渭南市",
                "value": 442
            },
            {
                "label": "延安市",
                "value": 443
            },
            {
                "label": "汉中市",
                "value": 444
            },
            {
                "label": "榆林市",
                "value": 445
            },
            {
                "label": "安康市",
                "value": 446
            },
            {
                "label": "商洛市",
                "value": 447
            },
            {
                "label": "西咸新区",
                "value": 448
            }
        ]
    },
    {
        "label": "甘肃省",
        "value": 62,
        "children": [{
                "label": "兰州市",
                "value": 450
            },
            {
                "label": "嘉峪关市",
                "value": 451
            },
            {
                "label": "金昌市",
                "value": 452
            },
            {
                "label": "白银市",
                "value": 453
            },
            {
                "label": "天水市",
                "value": 454
            },
            {
                "label": "武威市",
                "value": 455
            },
            {
                "label": "张掖市",
                "value": 456
            },
            {
                "label": "平凉市",
                "value": 457
            },
            {
                "label": "酒泉市",
                "value": 458
            },
            {
                "label": "庆阳市",
                "value": 459
            },
            {
                "label": "定西市",
                "value": 460
            },
            {
                "label": "陇南市",
                "value": 461
            },
            {
                "label": "临夏回族自治州",
                "value": 462
            },
            {
                "label": "甘南藏族自治州",
                "value": 463
            }
        ]
    },
    {
        "label": "青海省",
        "value": 63,
        "children": [{
                "label": "西宁市",
                "value": 465
            },
            {
                "label": "海东市",
                "value": 466
            },
            {
                "label": "海北藏族自治州",
                "value": 467
            },
            {
                "label": "黄南藏族自治州",
                "value": 468
            },
            {
                "label": "海南藏族自治州",
                "value": 469
            },
            {
                "label": "果洛藏族自治州",
                "value": 470
            },
            {
                "label": "玉树藏族自治州",
                "value": 471
            },
            {
                "label": "海西蒙古族藏族自治州",
                "value": 472
            }
        ]
    },
    {
        "label": "宁夏回族自治区",
        "value": 64,
        "children": [{
                "label": "银川市",
                "value": 474
            },
            {
                "label": "石嘴山市",
                "value": 475
            },
            {
                "label": "吴忠市",
                "value": 476
            },
            {
                "label": "固原市",
                "value": 477
            },
            {
                "label": "中卫市",
                "value": 478
            }
        ]
    },
    {
        "label": "新疆维吾尔自治区",
        "value": 65,
        "children": [{
                "label": "乌鲁木齐市",
                "value": 480
            },
            {
                "label": "克拉玛依市",
                "value": 481
            },
            {
                "label": "吐鲁番市",
                "value": 482
            },
            {
                "label": "哈密市",
                "value": 483
            },
            {
                "label": "哈密市",
                "value": 484
            },
            {
                "label": "昌吉回族自治州",
                "value": 485
            },
            {
                "label": "博尔塔拉蒙古自治州",
                "value": 486
            },
            {
                "label": "巴音郭楞蒙古自治州",
                "value": 487
            },
            {
                "label": "阿克苏地区",
                "value": 488
            },
            {
                "label": "克孜勒苏柯尔克孜自治州",
                "value": 489
            },
            {
                "label": "喀什地区",
                "value": 490
            },
            {
                "label": "和田地区",
                "value": 491
            },
            {
                "label": "伊犁哈萨克自治州",
                "value": 492
            },
            {
                "label": "塔城地区",
                "value": 493
            },
            {
                "label": "阿勒泰地区",
                "value": 494
            },
            {
                "label": "新疆自治区直辖",
                "value": 495
            },
            {
                "label": "石河子市",
                "value": 496
            },
            {
                "label": "阿拉尔市",
                "value": 497
            },
            {
                "label": "图木舒克市",
                "value": 498
            },
            {
                "label": "五家渠市",
                "value": 499
            },
            {
                "label": "北屯市",
                "value": 500
            },
            {
                "label": "铁门关市",
                "value": 501
            },
            {
                "label": "双河市",
                "value": 502
            },
            {
                "label": "可克达拉市",
                "value": 503
            },
            {
                "label": "昆玉市",
                "value": 504
            }
        ]
    }
]