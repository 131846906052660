<template>
  <div class="container">
    <PageHeader current="carimg" />
    <div class="main-container">
      <div class="car-img w1200">
        <a-breadcrumb separator=">" class="w-breadcrumb">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 精美车图 </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="box-left">
          <ImageBrandSelect :isOpenQuote="true" />
        </div>
        <div class="box-right">
          <div class="filter-bar">
            <h3>图片库</h3>
          </div>
          <div class="swiper-slide">
            <a-carousel autoplay>
              <div v-for="(item, k) in topImgs" v-bind:key="k">
                <ul class="ul1">
                  <li v-for="s in item" v-bind:key="s.id">
                    <router-link
                      :to="{ name: 'CarImgSeries', params: { id: s.id } }"
                    >
                      <img :src="s.img_url" />
                    </router-link>
                    <p>{{ s.name }}</p>
                  </li>
                </ul>
              </div>
            </a-carousel>
          </div>

          <!-- 热门品牌 -->
          <div class="hot-brand">
            <h4 class="m-title">热门品牌</h4>
            <ul class="hot-brand__ul">
              <li v-for="brand in hotBrands" v-bind:key="brand.id">
                <router-link
                  :to="{ name: 'CarImgBrand', params: { id: brand.id } }"
                >
                  <img :src="brand.logo_url" alt="" />
                  <p>{{ brand.name }}</p>
                </router-link>
              </li>
            </ul>
          </div>

          <!-- 图库 -->
          <div class="tuku-box">
            <div class="item">
              <h4 class="m-title">热门SUV</h4>
              <ul>
                <li
                  class="mb10"
                  v-for="(item, i) in hotImgs.suv"
                  v-bind:key="item.id"
                  :style="i % 3 == 2 ? { 'margin-right': '0px' } : {}"
                >
                  <router-link
                    :to="{ name: 'CarImgSeries', params: { id: item.id } }"
                  >
                    <img :src="item.img_url" style="display: inline" />
                    <p>{{ item.name }}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="tuku-box">
            <div class="item">
              <h4 class="m-title">热门中型车</h4>
              <ul>
                <li
                  class="mb10"
                  v-for="(item, i) in hotImgs.middle"
                  v-bind:key="item.id"
                  :style="i % 3 == 2 ? { 'margin-right': '0px' } : {}"
                >
                  <router-link
                    :to="{ name: 'CarImgSeries', params: { id: item.id } }"
                  >
                    <img :src="item.img_url" style="display: inline" />
                    <p>{{ item.name }}</p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import ImageBrandSelect from "@/components/ImageBrandSelect";
import { getCarsouelImages, getLevelImages } from "@/api/carimg";
import { getHotBrands } from "@/api/car";
import reData from "@/data/recommends";

export default {
  name: "Index",
  components: {
    PageHeader,
    Footer,
    ImageBrandSelect,
  },
  data() {
    return {
      topImgs: [],
      brands: reData,
      hotImgs: {
        suv: [],
        middle: [],
      },
      hotBrands: [],
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getCarsouelImages().then((resp) => {
        this.topImgs = resp.data;
      });
      getLevelImages().then((resp) => {
        this.hotImgs = resp.data;
      });

      getHotBrands().then((resp) => {
        this.hotBrands = resp.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  margin-top: 20px;
}

.w-breadcrumb {
  margin: 0px 0 15px;
}

.mb10 {
  margin-bottom: 15px;
}

.swiper-slide {
  ul {
    overflow: hidden;
    li {
      width: 256px;
      height: 193px;
      overflow: hidden;
      float: left;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.6s ease-out;
      }

      p {
        padding: 0 10px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40px;
        background: url(/images/newPchcw/shadeimg1.png);
        font-size: 16px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }
    li:first-child + li {
      margin-bottom: 10px;
    }
    li:first-child {
      width: 594px;
      height: 396px;
      margin-right: 10px !important;
      p {
        font-size: 20px;
        font-weight: bold;
        height: 60px;
        line-height: 60px;
      }
    }
  }
}

.car-img {
  .box-left {
    width: 300px;
    z-index: 10;
    float: left;
  }

  .box-right {
    width: 860px;
    float: right;

    .filter-bar {
      border-bottom: none;
      margin-bottom: 10px;
      overflow: hidden;
      vertical-align: middle;
      padding-bottom: 15px;
      padding-top: 5px;
      h3 {
        font-size: 28px;
        font-weight: bold;
      }
    }

    .hot-brand {
      .m-title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
        color: #3b3b3d;
        margin-bottom: 20px;
        line-height: 24px;
      }
      .hot-brand__ul {
        overflow: hidden;
        border-left: 1px solid #e1e2eb;
        border-top: 1px solid #e1e2eb;
        li {
          float: left;
          border-right: 1px solid #e1e2eb;
          border-bottom: 1px solid #e1e2eb;
          width: 106px;
          height: 110px;
          text-align: center;
          img {
            width: 60px;
            height: 60px;
            margin-top: 10px;
          }
          p {
            font-size: 14px;
            color: #3b3b3d;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 10px;
          }
        }
      }
    }

    .tuku-box {
      .item:first-child {
        .m-title {
          margin-top: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #3b3b3d;
          margin-bottom: 20px;
          line-height: 24px;
        }
      }

      .item {
        ul {
          overflow: hidden;
          li {
            width: 270px;
            height: 180px;
            margin-right: 25px;
            padding-bottom: 25px;
            position: relative;
            float: left;
            img {
              width: 270px;
              height: 180px;
            }
            p {
              position: absolute;
              left: 0;
              bottom: 0px;
              font-size: 16px;
              color: #fff;
              width: 95%;
              height: 40px;

              padding-left: 5%;
              background: url(/images/newPchcw/shadeimg1.png);
              background-size: 100% 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>

