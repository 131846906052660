<template>
  <div class="container">
    <div class="main-container">
      <div class="logo-area">
        <div class="logo-img">
          <router-link :to="{ name: 'Index' }">
            <img src="@/assets/logo.png" />
          </router-link>
        </div>
        <div class="tips-text">
          <img src="@/assets/icons/phone.png" />
          如有疑问，请电话咨询：15117971875
        </div>
      </div>

      <div class="part" style="height: 520px">
        <div class="show-img"></div>
        <div class="wrap login-part">
          <div class="login-tab">
            <ul>
              <li class="on login" data-id="login">
                <div>账号登录</div>
              </li>
            </ul>
          </div>

          <div class="login-form form">
            <a-form
              id="components-form-demo-normal-login"
              :form="form"
              class="login-form"
              @submit="handleSubmit"
            >
              <a-form-item>
                <a-input
                  size="large"
                  v-decorator="[
                    'userName',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入账号!',
                        },
                      ],
                    },
                  ]"
                  placeholder="账号"
                >
                  <a-icon
                    slot="prefix"
                    type="user"
                    style="color: rgba(0, 0, 0, 0.25)"
                  />
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input
                  size="large"
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入密码!',
                        },
                      ],
                    },
                  ]"
                  type="password"
                  placeholder="密码"
                >
                  <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.25)"
                  />
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-checkbox
                  v-decorator="[
                    'remember',
                    {
                      valuePropName: 'checked',
                      initialValue: true,
                    },
                  ]"
                >
                  7天免登录
                </a-checkbox>
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  class="sub-btn"
                  :loading="loading"
                >
                  登录
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";

export default {
  name: "Index",
  components: {
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {},
    handleSubmit(e) {
      e.preventDefault();
      if (this.loading) {
        return false;
      }

      var that = this;

      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          that.loading = true;
          console.log("Received values of form: ", values);
          setTimeout(function () {
            that.$message.warning("账号或密码错误");
            that.loading = false;
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.logo-area {
  width: 1180px;
  margin: auto;
  height: 100px;
  .logo-img {
    margin: auto;
    padding: 12px 0;
    float: left;
    img {
      width: 260px;
      height: 74px;
    }
  }

  .tips-text {
    color: #999;
    float: right;
    padding: 29px 0;
    line-height: 29px;
    img {
      margin-right: 5px;
      width: 20px;
    }
  }
}

.part {
  width: 1180px;
  margin: auto;
  border-top: 1px solid #ccc;
  line-height: 30px;
  text-align: center;
  .show-img {
    width: 1500px;
    height: 520px;
    margin: auto;
    background-image: url(http://img.shengxingdt.com/web/dealerLoginBg.png?x-oss-process=style/default.jpg);
    margin-left: -160px;
  }
  .wrap {
    width: 320px;
    border: 1px solid #ccc;
    margin: -475px 0 auto auto;
    background-color: #fff;
    height: 385px;
    padding: 20px;
    .login-tab {
      width: 100%;
      overflow: auto;
      margin-bottom: 20px;
      .on {
        border-bottom: 2px solid #04316c;
        color: #04316c;
      }
      li {
        width: 100%;
        height: 40px;
        margin-top: 0;
        font-size: 18px;
        text-align: center;
        padding-bottom: 12px;
        border-bottom: 2px solid #ccc;
        cursor: pointer;
      }
    }
  }

  .sub-btn {
    display: block;
    width: 100%;
    height: 50px;
    font-size: 20px;
    background-color: #04316c;
    border: none;
    cursor: pointer;
    color: #fff;
    float: left;
  }
}
</style>

