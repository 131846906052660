<template>
  <div class="container">
    <PageHeader current="carimg" />
    <div class="main-container">
      <div class="car-img w1200">
        <a-breadcrumb separator=">" class="w-breadcrumb">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ name: 'CarImgs' }"
              >精美车图</router-link
            ></a-breadcrumb-item
          >
          <a-breadcrumb-item> 车系列Î表 </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="box-left">
          <ImageBrandSelect :isOpenQuote="true" />
        </div>
        <div class="box-right">
          <!-- 顶部导航  -->
          <div class="filter-bar">
            <label>
              <img :src="brand.logo_url" />
              <span>{{ brand.name }}</span>
            </label>
          </div>

          <!-- 车系列表 -->
          <div class="car-img-series">
            <div
              class="cimg-series__item"
              v-for="fac in series"
              v-bind:key="fac.factory_id"
            >
              <h5 style="display: block">
                <span>{{ fac.factory_name }}</span>
              </h5>
              <ul class="car-item">
                <li
                  sids="4 "
                  style="display: list-item"
                  v-for="s in fac.series"
                  v-bind:key="s.id"
                >
                  <router-link
                    :to="{ name: 'CarImgSeries', params: { id: s.id } }"
                  >
                    <img class="lazy" :src="s.cover_url" />
                    <h6>{{ s.name }}</h6>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import ImageBrandSelect from "@/components/ImageBrandSelect";
import { getSeriesByBrandId } from "@/api/car";

export default {
  name: "Index",
  components: {
    PageHeader,
    Footer,
    ImageBrandSelect,
  },
  data() {
    return {
      id: 0,
      series: [],
      brand: {},
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getSeriesByBrandId(this.id).then((resp) => {
        this.series = resp.data.series;
        this.brand = resp.data.brand;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  margin-top: 20px;
  min-height: 850px;
}

.w-breadcrumb {
  margin: 0 0 15px;
}

.car-img {
  .box-left {
    width: 300px;
    z-index: 10;
    float: left;
  }

  .box-right {
    width: 860px;
    float: right;

    .filter-bar {
      border-bottom: none;
      margin-bottom: 10px;
      overflow: hidden;
      vertical-align: middle;
      padding-bottom: 15px;
      padding-top: 5px;
      label {
        img {
          width: 50px;
          height: 50px;
          float: left;
        }

        span {
          float: left;
          margin-left: 10px;
          font-size: 28px;
          font-weight: bold;
          color: #3b3b3d;
          line-height: 50px;
        }
      }
      h3 {
        font-size: 28px;
        font-weight: bold;
      }
    }

    .car-img-series {
      .cimg-series__item {
        h5 {
          margin-bottom: 20px;
          color: #f54a45;
          font-size: 16px;
          a {
            color: #f54a45;
            font-size: 16px;
          }
        }
        .car-item {
          overflow: hidden;
          font-size: 0;
          margin: 0 -15px;
          li {
            width: 192px;
            height: 200px;
            float: left;
            margin: 0 15px;
            img {
              width: 192px;
              height: 130px;
            }

            h6 {
              font-size: 16px;
              color: #3b3b3d;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: bold;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>

