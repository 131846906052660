var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('DealerHeader',{attrs:{"current":"index"}}),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"top_news"},[_c('div',{staticClass:"w1200 flexBC"},[_c('div',{staticClass:"t_newswrap"},[_c('div',{staticClass:"flexR"},[_c('img',{staticStyle:{"max-height":"280px"},attrs:{"src":_vm.dealer.img_url}})])]),_c('div',{staticClass:"dealer__news"},[(_vm.news.length > 0)?_c('dl',[_c('dt',{staticClass:"flexR"},[_c('router-link',{attrs:{"to":{
                  name: 'DealerNewsDetail',
                  params: {
                    id: _vm.id,
                    nid: _vm.news[0].id,
                  },
                }}},[_vm._v(_vm._s(_vm.news[0].title)+" ")])],1),_vm._l((_vm.news.slice(1, 6)),function(item){return _c('dd',{key:item.id,staticClass:"flexL"},[_c('router-link',{attrs:{"to":{
                  name: 'DealerNewsDetail',
                  params: {
                    id: _vm.id,
                    nid: item.id,
                  },
                }}},[(item.classify == 1)?_c('span',[_vm._v("[ 行业新闻 ]")]):_vm._e(),(item.classify == 2)?_c('span',[_vm._v("[ 促销优惠 ]")]):_vm._e()]),_c('router-link',{attrs:{"to":{
                  name: 'DealerNewsDetail',
                  params: {
                    id: _vm.id,
                    nid: item.id,
                  },
                }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2):_vm._e(),(_vm.total >= 7)?_c('router-link',{staticClass:"more flexR",attrs:{"to":{
              name: 'DealerNews',
            }}},[_vm._v(" 查看更多» ")]):_vm._e()],1),_c('div',{staticClass:"dealer-info__container"},[_c('div',{staticClass:"d-info__name flexBC"},[_c('div',{staticClass:"d-info__dn"},[_vm._v(_vm._s(_vm.dealer.name))]),_c('a',{staticClass:"d-info__askprice",attrs:{"href":"#md1"}},[_vm._v("询价/试驾")])]),_c('div',{staticClass:"di__box"},[_c('div',{staticClass:"messli flexL",staticStyle:{"align-items":"baseline"}},[_c('div',{staticStyle:{"width":"45px"}},[_vm._v("主营：")]),_c('div',{staticStyle:{"width":"240px","line-height":"20px"},attrs:{"title":"比亚迪/比亚迪新能源/"}},_vm._l((_vm.dealer.brands),function(b){return _c('a',{key:b.id,staticClass:"jxs_brand",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(b.name))])}),0)]),_c('div',{staticClass:"messli flexL",staticStyle:{"align-items":"baseline"}},[_c('div',{staticStyle:{"width":"45px"}},[_vm._v("级别：")]),_vm._v(" "+_vm._s(_vm.dealer.level)+" ")]),_c('div',{staticClass:"messli flexL",staticStyle:{"align-items":"baseline"}},[_c('div',{staticStyle:{"width":"45px"}},[_vm._v("电话：")]),_vm._v(" "+_vm._s(_vm.dealer.sell_phone)+" ")]),_c('div',{staticClass:"messli flexL",staticStyle:{"align-items":"baseline"}},[_c('div',{staticStyle:{"width":"45px"}},[_vm._v("地址：")]),_c('p',{staticStyle:{"width":"240px","line-height":"20px"}},[_vm._v(" "+_vm._s(_vm.dealer.address)+" ")])])])])])]),_c('div',{staticClass:"w1200"},[_c('div',{staticClass:"hot_cars"},[_c('h2',{staticClass:"jxs_t_index",attrs:{"id":"md1"}},[_vm._v("热销车型")]),_vm._l((_vm.hotSeries),function(item){return _c('div',{key:item.id,staticClass:"hot_cars_box"},[_c('div',{staticClass:"hot_cars_li clearFix"},[_c('div',{staticClass:"hotcars_l"},[_c('div',{staticClass:"hc_l_title"},[_c('div',{staticClass:"hc_l_con"},[_c('div',{staticClass:"hc_l_con_1"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('router-link',{attrs:{"to":{
                        name: 'DealerSeries',
                        params: { id: _vm.id },
                        query: { seriesId: item.id },
                      }}},[_vm._v("▶更多车型")])],1),_c('div',{staticClass:"hc_l_con_2"},[_c('span',{staticClass:"h_2_t"},[_vm._v("指导价：")]),_c('span',[_vm._v(_vm._s(item.price))])])])]),_c('div',{staticClass:"hc_l_detail"},[_c('div',{staticClass:"hc_l_lines"}),_c('div',{staticClass:"hc_l_d_box"},_vm._l((item.models),function(model){return _c('router-link',{key:model.id,attrs:{"to":{
                      name: 'DealerSeries',
                      params: { id: _vm.id },
                      query: { seriesId: item.id },
                    }}},[_c('p',[_vm._v(" "+_vm._s(model.year)+"款 "+_vm._s(model.name)+" "+_vm._s(model.env_standards)+" ")])])}),1)]),_c('div',{staticClass:"xjsj_index"},[_vm._v(" >>> "),_c('router-link',{staticClass:"xjsj_index_btn",attrs:{"to":{
                    name: 'DealerQuote',
                    query: {
                      bid: item.brand.id,
                      sid: item.id,
                    },
                  }}},[_vm._v(" 询价/试驾 ")]),_vm._v(" <<< ")],1)]),_c('div',{staticClass:"hotcars_r"},[_c('router-link',{staticClass:"hotcars_img",attrs:{"to":{
                  name: 'DealerSeries',
                  params: { id: _vm.id },
                  query: { seriesId: item.id },
                }}},[_c('div',{staticClass:"img_logo"},[_c('img',{attrs:{"src":item.brand.logo_url}})]),_c('span',{staticClass:"icon-extension temai",attrs:{"id":"icon-extension"}},[_vm._v("特卖")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%","height":"100%"}},[_c('img',{staticClass:"scrollLoading lazy",attrs:{"src":item.cover_url,"width":"590","height":"370"}})])])],1)])])}),_c('div',{staticClass:"jxs_more_cars"},[_c('router-link',{attrs:{"to":{
              name: 'DealerSeries',
              params: { id: _vm.id },
            }}},[_vm._v(" 全部车型 ")])],1)],2),_c('div',{staticClass:"jxs_main_brands"},[_c('h2',{staticClass:"jxs_t_index"},[_vm._v("主营品牌")]),_vm._l((_vm.series),function(brand){return _c('div',{key:brand.id,staticClass:"jxs_main_b_li"},[_c('div',{staticClass:"jxsmain_box"},[_c('div',{staticClass:"jxsmain_title"},[_c('div',{staticClass:"logo_name"},[_c('div',{staticClass:"logobg"},[_c('img',{staticClass:"scrollLoading lazy",attrs:{"src":brand.logo_url}})]),_vm._v(" "+_vm._s(brand.name)+" ")])]),_vm._m(0,true),_c('ul',{staticClass:"jxs_mbrandslist clearFix"},[_vm._l((brand.series.slice(0, 5)),function(item){return _c('li',{key:item.id},[_c('router-link',{staticStyle:{"display":"block"},attrs:{"to":{
                    name: 'DealerSeries',
                    params: { id: _vm.id },
                    query: { seriesId: item.id },
                  }}},[_c('div',{staticClass:"jxsbrands_img"},[_c('img',{staticClass:"scrollLoading lazy",attrs:{"src":item.cover_url,"width":"390","height":"245"}}),_c('div',{})]),_c('div',{staticClass:"jxs_brandsname"},[_c('div',{staticClass:"name_lines"},[_c('div',{staticClass:"name_bransjxs"},[_vm._v(_vm._s(item.name))])])])])],1)}),(brand.series.length >= 5)?_c('router-link',{attrs:{"to":{ name: 'DealerSeries', params: { id: _vm.id } }}},[_c('li',{staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"name_lines",staticStyle:{"border":"solid 3px #e0e0e0","margin-top":"135px"}},[_c('div',{staticClass:"name_bransjxs",staticStyle:{"background-color":"#fff","color":"#333"}},[_vm._v(" 更多车型 ")])])])]):_vm._e()],2)])])})],2),(_vm.news.length > 0)?_c('div',{staticClass:"jxs_main_news w1200"},[_c('h2',{staticClass:"jxs_t_index",staticStyle:{"margin-top":"20px"}},[_vm._v("店内动态")]),_c('ul',{staticClass:"jxs_news_list clearFix"},_vm._l((_vm.news.slice(0, 3)),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"to":{
                name: 'DealerNewsDetail',
                params: {
                  id: _vm.id,
                  nid: item.id,
                },
              }}},[_c('img',{staticClass:"topimg scrollLoading lazy",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":item.img_url}})]),_c('div',{staticClass:"news_detail"},[_c('router-link',{attrs:{"to":{
                  name: 'DealerNewsDetail',
                  params: {
                    id: _vm.id,
                    nid: item.id,
                  },
                }}},[_c('h4',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('router-link',{attrs:{"to":{
                  name: 'DealerNewsDetail',
                  params: {
                    id: _vm.id,
                    nid: item.id,
                  },
                }}},[_c('p',[_vm._v(" "+_vm._s(item.summary)+" ")])]),_c('div',{staticClass:"time_a clearFix"},[_c('span',{staticClass:"fl"},[_vm._v(_vm._s(item.published_at))]),_c('router-link',{staticClass:"fr",staticStyle:{"border":"3px solid rgb(102, 102, 102)","background-position":"11px -15px"},attrs:{"to":{
                    name: 'DealerNewsDetail',
                    params: {
                      id: _vm.id,
                      nid: item.id,
                    },
                  }}})],1)],1)],1)}),0),_c('div',{staticClass:"jxs_more_cars"},[_c('router-link',{attrs:{"to":{
              name: 'DealerNews',
              params: {
                id: _vm.id,
              },
            }}},[_vm._v(" 查看更多 ")])],1)]):_vm._e()]),_c('service-intro')],1),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"downsj_box"},[_c('div',{staticClass:"downsj"},[_vm._v("▼")])])}]

export { render, staticRenderFns }