<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
a {
  text-decoration: none;
  color: #333;
}

.summary-series__images-box {
  height: 200px;
}

.flexBC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flexR {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flexEnd {
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.fl {
  float: left;
  display: inline;
}

.fr {
  float: right;
}
.art-cont > p {
  font-size: 16px;
  color: #414244;
  line-height: 34px;
  text-indent: 2em;
  margin-bottom: 18px;
}
.art-cont > p img {
  display: block;
  margin: auto;
  max-width: 700px;
}
.art-cont img {
  width: 100%;
}

.city_popup  {
  width: 440px;
  height: 300px;
  .ant-cascader-menu {
    height: 300px;
  }

  .ant-cascader-menu:last-child {
    width: 280px;
  }
}
.brand_popup {
  height: 300px;
  .ant-cascader-menu {
    height: 300px;
  }
  .ant-cascader-menu:last-child {
    width: 140px;
  }
}
.alphabet-anchor {
  .ant-anchor-ink {
    display: none;
  }
}

</style>
