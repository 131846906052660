import Vue from 'vue'
import Router from 'vue-router'

//自定义页面
import Index from '@/views/index'
import Cars from '@/views/cars'
// import Series from '@/views/series'
import NewSeries from '@/views/newSeries'
import SeriesConfig from '@/views/series/config'

import News from '@/views/news'
import NewsDetail from '@/views/newsDetail'
// import Quote from '@/views/quote'
import Search from '@/views/search'
import City from '@/views/city'
import AboutUs from '@/views/intro/aboutus'
import SaleFlow from '@/views/intro/saleflow'
import Agreement from '@/views/intro/agreement'
import Question from '@/views/intro/question'
import ContactUS from '@/views/intro/contactus'

import CarImgs from '@/views/carimgs/index'
import QuotePage from '@/views/quote/index'
import Dealer from '@/views/dealer'
import CarImgBrand from '@/views/carimgs/brand'
import CarImgSeries from '@/views/carimgs/series'
import CarImgPreview from '@/views/carimgs/preview'
import AskPrice from '@/views/quote'
import NewCarNews from '@/views/newCarNews'
import TestDriver from '@/views/testDriver'

// 经销商
import DealerIndex from '@/views/dealers/index'
import DealerSeries from '@/views/dealers/series'
import DealerInfo from '@/views/dealers/info'
import DealerNews from '@/views/dealers/news'
import DealerNewsDetail from '@/views/dealers/detail'
import DealerRegister from '@/views/dealers/register'
import DealerLogin from '@/views/dealers/login'
import DealerQuote from '@/views/dealers/quote'

// 团购排期
import Groupon from '@/views/groupon/index'
import GrouponDetail from '@/views/groupon/detail'

//安装路由到vue
Vue.use(Router)

const routes = [{
    path: '/',
    name: 'Index',
    component: Index
}, {
    path: '/cars',
    name: 'Cars',
    component: Cars
}, {
    path: '/series/:id',
    name: 'Series',
    component: NewSeries
}, {
    path: '/news',
    name: 'News',
    component: News
}, {
    path: '/news/new-car',
    name: 'NewCarNews',
    component: NewCarNews
}, {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail
}, {
    path: '/quote',
    name: 'QuotePage',
    component: QuotePage
}, {
    path: '/test-driver',
    name: 'TestDriver',
    component: TestDriver
}, {
    path: '/quote/askprice',
    name: 'AskPrice',
    component: AskPrice
}, {
    path: '/search',
    name: 'Search',
    component: Search
}, {
    path: '/cities',
    name: 'City',
    component: City
}, {
    path: '/intro/aboutus',
    name: 'AboutUs',
    component: AboutUs
}, {
    path: '/intro/sale-flow',
    name: 'SaleFlow',
    component: SaleFlow
}, {
    path: '/intro/agreement',
    name: 'Agreement',
    component: Agreement
}, {
    path: '/intro/question',
    name: 'Question',
    component: Question
}, {
    path: '/intro/contactus',
    name: 'ContactUS',
    component: ContactUS
}, {
    path: '/car/images',
    name: 'CarImgs',
    component: CarImgs
}, {
    path: '/dealers',
    name: 'Dealers',
    component: Dealer
}, {
    path: '/car/images/brand/:id',
    name: 'CarImgBrand',
    component: CarImgBrand
}, {
    path: '/car/images/series/:id',
    name: 'CarImgSeries',
    component: CarImgSeries
}, {
    path: '/car/images/series/:id/preview',
    name: 'CarImgPreview',
    component: CarImgPreview
}, {
    path: '/dealer/register',
    name: 'DealerRegister',
    component: DealerRegister
}, {
    path: '/dealer/login',
    name: 'DealerLogin',
    component: DealerLogin
}, {
    path: '/dealer/:id',
    name: 'DealerIndex',
    component: DealerIndex
}, {
    path: '/dealer/:id/series',
    name: 'DealerSeries',
    component: DealerSeries
}, {
    path: '/dealer/:id/info',
    name: 'DealerInfo',
    component: DealerInfo
}, {
    path: '/dealer/:id/news',
    name: 'DealerNews',
    component: DealerNews
}, {
    path: '/dealer/:id/askprice',
    name: 'DealerQuote',
    component: DealerQuote
}, {
    path: '/dealer/:id/news/:nid',
    name: 'DealerNewsDetail',
    component: DealerNewsDetail
}, {
    path: '/groupon',
    name: 'Groupon',
    component: Groupon
}, {
    path: '/groupon/:id',
    name: 'GrouponDetail',
    component: GrouponDetail
}, {
    path: '/series/:id/config',
    name: 'SeriesConfig',
    component: SeriesConfig
}]

export default new Router({
    // mode: 'hash', //默认hash模式，hash模式有#；另外还有一种history模式，没有#符号
    mode: 'history',
    routes
})