<template>
  <div class="container">
    <PageHeader current="quote" />
    <div class="main-container">
      <div class="w1200">
        <div class="askPrice-tab">
          <div class="fl askPrice-quote ask-current">询问底价</div>
          <div class="fl askPrice-test-driver">
            <router-link :to="{ name: 'TestDriver', query: queryData }">预约试驾 </router-link>
          </div>
        </div>
        <div class="quote">
          <div class="ask_title">
            <h2>询问底价</h2>
          </div>
          <p style="color: #dd2a2a; text-align: center; margin-bottom: 20px">
            <span
              >简单填写以下信息，轻松了解车型底价
              (您的私人信息将会得到严格保密)</span
            >
          </p>
          <div class="askprice-wrapper" style="height: 300px">
            <div
              class="carimg-box"
              style="float: left; width: 400px; height: w200px"
            >
              <img :src="carimg" alt="" style="width: 400px; height: auto" />
            </div>
            <div class="ask_box" style="float: left; width: 800px">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item ref="name" label="询价城市" prop="name">
                  <a-cascader
                    class="quote__region"
                    :options="regions"
                    placeholder="选择城市"
                    size="large"
                    expand-trigger="hover"
                    popupClassName="city_popup"
                    v-model="form.region"
                  />
                </a-form-model-item>
                <a-form-model-item label="意向车型" prop="region">
                  <a-cascader
                    class="brand__select"
                    :options="brands"
                    placeholder="选择品牌"
                    size="large"
                    expand-trigger="hover"
                    popupClassName="brand_popup"
                    @change="onChange"
                    v-model="form.brand"
                  />
                  <a-select
                    class="brand__select"
                    size="large"
                    placeholder="请选择车系"
                    v-model="form.seriesId"
                    @change="change"
                  >
                    <a-select-opt-group
                      :label="item.factory_name"
                      v-for="item in series"
                      v-bind:key="item.factory_id"
                    >
                      <a-select-option
                        :value="s.id"
                        v-for="(s, i) in item.series"
                        :key="i"
                      >
                        {{ s.name }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item ref="name" label="您的姓名" prop="name">
                  <a-input
                    v-model="form.name"
                    size="large"
                    class="quote__input"
                  />
                </a-form-model-item>
                <a-form-model-item ref="name" label="手机号码" prop="name">
                  <a-input
                    v-model="form.phone"
                    size="large"
                    class="quote__input"
                  />
                </a-form-model-item>
              </a-form-model>
              <p style="color: #999; text-align: center; padding-right: 240px">
                点击提交即视为同意<router-link
                  :to="{ name: 'Agreement' }"
                  target="_blank"
                  >《个人信息保护声明》
                </router-link>
              </p>
            </div>
          </div>

          <div class="bm" style="text-align: center">
            <input
              class="quote__btn"
              value="获取底价"
              type="button"
              @click="submit"
            />
          </div>
        </div>
        <div class="hotprice_cars">
          <div class="w1200">
            <div class="hottitle">
              <p>热门车型</p>
              <div class="clear"></div>
            </div>
            <ul class="hotp_carsbox flexBC">
              <li v-for="item in hotSeries" v-bind:key="item.id">
                <div class="imgBox">
                  <a
                    href="javascript:void(0);"
                    style="
                      display: block;
                      text-align: center;
                      border-bottom: 1px solid #e6e6e6;
                    "
                    ><img
                      data-original=""
                      width="260"
                      height="173"
                      :src="item.cover_url"
                  /></a>
                </div>
                <dl class="flexBC" style="align-items: flex-end">
                  <dd>
                    <h4 class="hotp-series__name">
                      <a href="javascript:void(0)">{{ item.name }}特卖</a>
                    </h4>
                    <p class="lh150 text-gray-600">
                      特卖时间：{{ item.sale_time }}
                    </p>
                  </dd>
                  <dt>
                    <a href="javascript:void(0)" style="display: inline-block">
                      <img :src="item.brand.logo_url" width="55" height="55"
                    /></a>
                  </dt>
                </dl>
                <a
                  href="javascript:;"
                  @click="askPrice(item)"
                  class="askprice_btn"
                  >获取底价</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import { getHotSeries, getSeriesByBrandId, getSeriesByName } from "@/api/car";
import BrandsData from "@/data/brands";
import CityData from "@/data/city";
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import { scrollTo } from "@/utils/scroll-to";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      form: {
        region: [],
        brand: [],
        seriesId: null,
        name: "",
        phone: "",
      },
      regions: CityData,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      hotSeries: [],
      rules: {},
      series: [],
      carimg: "http://img.shengxingdt.com/default/defaultCar.jpeg",
      queryData: {},
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    if (this.$route.query.brand !== undefined && this.$route.query.brand) {
      this.form.brand = this.$route.query.brand.split(",");
      this.form.brand[1] = parseInt(this.form.brand[1]);
      this.queryData["brand"] = this.$route.query.brand;
      this.getSeriesData();
    }

    if (this.$route.query.series !== undefined && this.$route.query.series) {
      this.form.seriesId = this.$route.query.series;
      this.queryData["series"] = this.$route.query.series;
    }
    this.initData();
    this.getData();
    this.setRegion();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getHotSeries().then((response) => {
        this.hotSeries = response.data;
      });

      if (this.form.seriesId) {
        getSeriesByName({ name: this.form.seriesId }).then((resp) => {
          console.log(resp);
          this.carimg = resp.data.cover_url;
        });
      }
    },
    initData() {
      let letters = []; // 数据列表
      let brandMap = {}; // 每一个索引指向一个数组，这个数组存储该字母索引的朋友项

      BrandsData.forEach((brand) => {
        const letter = brand.letter;
        brandMap[letter] || (brandMap[letter] = []);
        brandMap[letter].push({
          label: brand.name,
          value: brand.id,
        });
      });

      for (let letter in brandMap) {
        letters.push({
          label: letter,
          value: letter,
          children: brandMap[letter],
        });
      }

      // 按首字母排序
      // 字符串比较不能用减号得出大小，返回都是NaN
      // 因此这里需要通过小于号比较
      letters.sort((a, b) => {
        return a.value <= b.value ? -1 : 1;
      });

      console.log(letters);

      this.brands = letters;
    },
    submit() {
      if (this.form.region.length == 0) {
        this.$message.warning("请选择城市");
        return;
      }

      if (this.form.brand.length == 0) {
        this.$message.warning("请选择品牌");
        return;
      }

      if (this.form.seriesId == 0) {
        this.$message.warning("请选择车系");
        return;
      }

      if (this.form.name == "") {
        this.$message.warning("请输入姓名");
        return;
      }

      if (this.form.phone == "") {
        this.$message.warning("请输入手机号码");
        return;
      }

      if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$message.warning("手机号格式错误");
        return;
      }

      var that = this;

      var timer = setTimeout(function () {
        that.$message.success("询价成功，稍后会有经销商与您联系！");
        clearTimeout(timer);
      }, 500);

      console.log(this.form);
    },
    getSeriesData() {
      console.log(this.form.brand.length);
      if (this.form.brand.length === 2) {
        getSeriesByBrandId(this.form.brand[1]).then((resp) => {
          this.series = resp.data.series;
        });
      } else {
        this.series = [];
        this.form.seriesId = null;
      }
    },
    onChange(value) {
      this.form.brand = value;
      console.log(this.form.brand[1]);
      this.getSeriesData();
    },
    askPrice(item) {
      this.form.brand = [item.brand.letter, item.brand.id];

      this.getSeriesData();
      this.form.seriesId = item.name;
      this.carimg = item.cover_url;
      scrollTo(0, 500);
      console.log(this.form);
    },

    setRegion() {
      try {
        let curCity = localStorage.getItem("_cur_city");

        console.log("ssss", curCity);

        if (curCity) {
          console.log(">>>", curCity);
          let curCityData = JSON.parse(curCity);
          this.form.region = [
            parseInt(curCityData.p_id),
            parseInt(curCityData.c_id),
          ];
          console.log(this.form);
          return;
        }
      } catch (e) {
        console.log(e);
      }
      this.form.region = [11, 100];
    },
    change(sid) {
      this.series.map((item) => {
        item.series.map((s) => {
          if (sid == s.id) {
            this.carimg = s.cover_url;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lh150 {
  line-height: 150%;
}

.askPrice-tab {
  border-bottom: 1px solid #ddd;
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
  font-size: 16px;
  .askPrice-quote {
    width: 100px;
    height: 50px;
    text-align: center;
  }
  .askPrice-test-driver {
    width: 100px;
    height: 50px;
    text-align: center;
    a {
      color: #333;
    }
  }
  div.ask-current {
    border-bottom: 2px solid #ea3434;
    color: #ea3434;
    font-weight: 600;
  }
}

.quote {
  padding-bottom: 40px;
  padding-top: 10px;
  zoom: 1;
  .ask_title {
    border-bottom: 1px dashed #e6e6e6;
    text-align: center;
    position: relative;
    height: 37px;
    margin-bottom: 35px;
    h2 {
      background-color: #fff;
      position: absolute;
      top: 20px;
      padding-right: 0;
      left: 520px;
      padding: 0 22px;
      font-size: 26px;
      display: inline-block;
      font-weight: normal;
    }
  }

  .bmseclet_box {
    & > li {
      padding: 10px 0;
      .bm_select {
        display: inline-block;
        text-align: left;
        color: #333;
        // float: left;
        line-height: 32px;
        padding-right: 8px;
        width: 95px;
      }
    }
  }

  // .ask_box {
  //   float: left;
  //   clear: both;
  //   overflow: hidden;
  // }

  .brand__select {
    width: 207px;
    margin-left: 23px;
  }
  .quote__input {
    width: 440px;
    box-sizing: border-box;
    margin-right: 0;
    font-size: 16px;

    color: #333;
    line-height: 52px;
    margin-left: 20px;
  }
  .quote__region {
    margin-left: 20px;
    width: 440px;
  }
  .quote__btn {
    width: 230px;
    height: 50px;
    line-height: 50px;
    background-color: #ea3434;
    box-shadow: inset 0px -5px 0px 0px #d72424;
    border-radius: 8px;
    transition: box-shadow 0.3s;
    color: #fff;
    font-size: 22px;
    border: 0;
    cursor: pointer;
  }
}

.hotprice_cars {
  padding-top: 30px;
  padding-bottom: 60px;
  dl {
    margin-top: 10px;
  }
  .hottitle {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    p {
      font-size: 26px;
      border-left: 3px solid #dd2a2a;
      padding-left: 5px;
      margin-bottom: 10px;
      color: #dd2a2a;
      display: inline-block;
    }
  }

  .hotp_carsbox {
    & > li {
      // background-color: #efefef;
      width: 24%;
      box-sizing: border-box;
      .hotp-series__name {
        font-size: 16px;
        a {
          color: #333;
        }
      }
    }
  }

  .askprice_btn {
    background-color: #f43b3b;
    color: #fff;
    width: 100%;
    display: block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
  }
}
.text-gray-600 {
  color: rgba(156, 163, 175, 1);
}

.ant-cascader-menus-content {
  width: 500px !important;
}
</style>

