<template>
  <div class="container">
    <PageHeader />
    <div class="main-container">
      <div class="w1200 w-breadcrumb">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ name: 'QuotePage' }">报价</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 车系详情 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="w1200" style="min-height: 1100px; height: auto">
        <div class="fl">
          <brand-select @changeBrand="changeBrand" :isOpenQuote="true">
          </brand-select>
        </div>
        <div class="fr r_bj">
          <!-- 车系顶部 -->
          <div class="clearFix" style="clear: both; overflow: hidden">
            <div class="fl bj_detailimg">
              <img :src="series.cover_url" />
            </div>
            <div class="fl car-info">
              <p class="f22 lh30px">{{ series.name }}</p>
              <p class="lh40px" style="font-size: 16px">
                厂商指导价：<span style="color: #e42a2a">{{
                  series.price
                }}</span>
              </p>
              <div class="f666" style="line-height: 26px">
                <p>级&nbsp;&nbsp;&nbsp;&nbsp;别：{{ series.grade }}</p>
                <p>车&nbsp;&nbsp;&nbsp;&nbsp;身：{{ series.car_structure }}</p>
                <p>发动机：{{ series.engine }}</p>
                <p>变速箱：{{ series.gearbox }}</p>
              </div>
              <div class="mt30">
                <router-link
                  target="_blank"
                  class="r_detailbtn search_pricebtn"
                  :to="{
                    name: 'AskPrice',
                    query: {
                      brand: series.brand.letter + ',' + series.brand.id,
                      series: series.name,
                    },
                  }"
                  style="color: #fff"
                >
                  获取底价
                </router-link>
              </div>
            </div>
          </div>

          <!-- 全部车款 -->
          <div class="recommend_title mb30 clearFix">
            <span class="fl title">全部车款</span>

            <div class="fr">
              <span class="odd_mr active">默认<em></em></span>
            </div>
          </div>
          <!-- 全部车款 -->
          <div class="popcarinfor mb60" id="dc_Table">
            <table
              style="width: 100%; margin-bottom: 20px"
              v-for="item in series.models"
              v-bind:key="item.group"
            >
              <tbody>
                <tr id="tou" style="background-color: #dce4ff">
                  <th>{{ item.group }}</th>
                  <th>厂商指导价</th>
                  <th>经销商报价</th>
                  <th>车型信息</th>
                </tr>
                <tr v-for="model in item.list" v-bind:key="model.id">
                  <td class="carname">{{ model.year }}款 {{ model.name }}</td>
                  <td>{{ model.guide_price }}</td>
                  <td>
                    暂无
                    <router-link
                      target="_blank"
                      class="askprice ml10"
                      :to="{
                        name: 'AskPrice',
                        query: {
                          brand: series.brand.letter + ',' + series.brand.id,
                          series: series.name,
                        },
                      }"
                      style="color: #fff"
                      >获取底价</router-link
                    >
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'SeriesConfig',
                        params: {
                          id: series.id,
                        },
                      }"
                      >配置</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 精美车图 -->
          <div
            class="recommend_title mb30 clearFix"
            style="margin-bottom: 20px"
          >
            <span class="fl title">精美车图</span>

            <div class="fr">
              <router-link
                target="_blank"
                :to="{ name: 'CarImgSeries', params: { id: series.id } }"
                class="odd_mr active"
                style="color: #333"
              >
                更多车图<i class="more-icon icon-arrow-ic-r"></i
              ></router-link>
            </div>
          </div>

          <div class="car-img">
            <!-- 车型图片 -->
            <div class="series__photo">
              <ul
                class="piclist"
                v-for="image in images"
                v-bind:key="image.classify"
              >
                <li
                  v-for="(item, i) in image.images"
                  v-bind:key="item.id"
                  :style="{ 'margin-right': i === 3 ? '0px' : '6px' }"
                >
                  <router-link
                    target="_blank"
                    :to="{ name: 'CarImgSeries', params: { id: series.id } }"
                    class="odd_mr active"
                  >
                    <img
                      class="lazy_pic"
                      :src="item.img_url"
                      style="display: inline"
                      placeholder="https://unsplash.it/1920/1080?image=10"
                    /><i v-if="i == 0">{{ image.classify_name }}</i>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- 经销商 -->
          <div
            class="recommend_title mb30 clearFix"
            style="margin-bottom: 20px"
          >
            <span class="fl title">经销商</span>

            <div class="fr">
              <router-link
                target="_blank"
                :to="{ name: 'Dealers' }"
                class="odd_mr active"
                style="color: #333"
              >
                更多
              </router-link>
            </div>
          </div>

          <ul class="jxs_list mb60">
            <li class="flexBC" v-for="dealer in dealers" v-bind:key="dealer.id">
              <div class="jxslist_l flexL">
                <img :src="dealer.img_url" width="260" height="180" />
                <div class="jxs_con ml20 lh30px">
                  <h3>{{ dealer.name }}</h3>
                  <p class="f666">促销：暂无</p>
                  <p class="f666">地址：{{ dealer.address }}</p>
                  <p class="f666">
                    <span>销售电话：</span>{{ dealer.sell_phone }}
                  </p>
                </div>
              </div>
              <div class="xjbtn mr30">
                <!-- <p class="lh40px">报价：暂无</p> -->

                <router-link
                  target="_blank"
                  class="askprice"
                  :to="{
                    name: 'DealerIndex',
                    params: {
                      id: dealer.id,
                    },
                  }"
                  style="color: #fff"
                >
                  进入店铺
                </router-link>
              </div>
            </li>
          </ul>

          <!-- 汽车资讯 -->
          <!-- <div
            class="recommend_title mb30 clearFix"
            style="margin-bottom: 20px"
          >
            <span class="fl title">汽车资讯</span>

            <div class="fr">
              <span class="odd_mr active">更多<em></em></span>
            </div>
          </div>
          <ul class="jxs_list mb60">
            <li>
              <div class="jxslist_l">
                <div class="jxs_con lh30px" style="width: auto">
                  <a
                    class="f18 f333 mb10"
                    href="/news/info/id/285657/news_type/7/"
                    target="_blank"
                    >绍兴购丰田卡罗拉优惠高达3000元 欢迎品鉴</a
                  >
                  <p class="f666">
                    <a href="/news/info/id/285657/news_type/7/" target="_blank"
                      >近日，粒子绍兴小编从绍兴佳禾丰田4S店处获悉，目前该店卡罗拉购车优惠3000元。</a
                    >
                  </p>
                </div>
              </div>
              <div class="text-right mr20">
                <p class="f999">时间：2021-03-22</p>
              </div>
            </li>
            <li>
              <div class="jxslist_l">
                <div class="jxs_con lh30px" style="width: auto">
                  <a
                    class="f18 f333 mb10"
                    href="/news/info/id/284904/news_type/7/"
                    target="_blank"
                    >绍兴丰田卡罗拉优惠高达3000元 欢迎垂询</a
                  >
                  <p class="f666">
                    <a href="/news/info/id/284904/news_type/7/" target="_blank"
                      >近日，粒子绍兴小编从绍兴佳禾丰田4S店处获悉，目前该店卡罗拉购车部分车型优惠3000元。</a
                    >
                  </p>
                </div>
              </div>
              <div class="text-right mr20">
                <p class="f999">时间：2021-02-03</p>
              </div>
            </li>
          </ul> -->
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import BrandSelect from "@/components/BrandSelect";
import { getSeriesById, getSeriesImagesById } from "@/api/car";
import { getDealers } from "@/api/dealer";

// import NoData from "@/components/NoData";

export default {
  name: "QuotePage",
  components: {
    PageHeader,
    Footer,
    BrandSelect,
    // NoData,
  },
  data() {
    return {
      id: 0,
      series: {},
      images: [],
      dealers: [],
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
    this.getImagesData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getSeriesById(this.id).then((response) => {
        this.series = response.data;
        this.sames = response.data.sames;
        // this.loading.series = false;
        // this.loading.models = false;
        // this.loading.same = false;
        this.getDealers();
      });
    },
    getDealers() {
      getDealers({ brand_ids: [this.series.brand.id], limit: 2 }).then(
        (resp) => {
          this.dealers = resp.data.list;
        }
      );
    },
    changeBrand(res) {
      this.selected.brandId = res.id;
      this.getData();
    },
    selectCar(option, obj) {
      this.page = 1;
      if (option === "level") {
        this.selected.level = obj.id;
      }
      if (option === "price") {
        this.selected.price = obj.id;
        this.selected.min = obj.min;
        this.selected.max = obj.max;
      }

      this.getData();
    },
    getImagesData() {
      getSeriesImagesById(this.id).then((resp) => {
        this.images = resp.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.jxs_list {
  li {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #cfd7f9;
    .jxsimg {
      display: block;
      width: 260px;
      height: 180px;
      overflow: hidden;
      img {
        width: 260px;
        height: 180px;
      }
    }

    .jxs_con {
      font-size: 18px;
      color: #333;
      margin-left: 20px;
      a {
        color: #333;
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }

    .askprice {
      border-radius: 5px;
      font-size: 14px;
      background-color: #e42a2a;
      color: #fff;
      line-height: 32px;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
    }
  }
  li:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.font-16 {
  font-size: 16px;
  color: #333;
}
.f666 {
  color: #666;
}
.main-container {
  overflow: hidden;
  margin-top: 20px;
  .r_bj {
    width: 880px;
    .bj_detailimg {
      img {
        width: 370px;
        height: 247px;
      }
    }

    .car-info {
      margin-left: 30px;
      p {
        margin-bottom: 0px;
      }
      a.search_pricebtn {
        color: #fff;
        background-color: #e42a2a;
        margin-right: 10px;
      }

      a.r_detailbtn {
        font-size: 16px;
        border: 1px solid #e42a2a;
        border-radius: 4px;
        height: 42px;
        line-height: 42px;
        padding: 0 30px;
        display: inline-block;
        cursor: pointer;
      }
    }

    .recommend_title {
      clear: both;
      overflow: hidden;
      border-bottom: 1px solid #999;
      position: relative;
      margin-top: 30px;
      margin-bottom: 10px;
      height: 30px;
      span.title {
        font-size: 18px;
        border-bottom: 3px solid #e42a2a;
        line-height: 27px;
        display: inline-block;
        position: absolute;
        bottom: -1px;
      }
    }

    .popcarinfor {
      padding: 10px 0px;
      // margin-bottom: 20px;
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      tr {
        line-height: 45px;
        color: #6b6b6b;
        font-size: 18px;
        th {
          font-size: 16px;
          color: #333;
          padding-left: 20px;
          line-height: 50px;
          height: 50px;
        }

        td {
          // padding-right: 30px;
          padding-left: 20px;
          color: #666;
          font-size: 14px;
          line-height: 50px;
          height: 50px;

          .askprice {
            border-radius: 5px;
            font-size: 14px;
            background-color: #e42a2a;
            color: #fff;
            line-height: 32px;
            padding: 0 10px;
            display: inline-block;
            cursor: pointer;
            margin-left: 10px;
          }
        }

        td.carname {
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}

.fl {
  float: left;
}
.fr {
  float: right;
  display: inline;
}
.clearFix {
  zoom: 1;
}
.lh30px {
  line-height: 30px;
}
.lh40px {
  line-height: 40px;
}
.f22 {
  font-size: 22px;
}
.mt30 {
  margin-top: 30px;
}
.w-breadcrumb {
  margin: 10px auto;
  font-size: 14px;
  padding: 0 10px;
}
.series__photo {
  margin-bottom: 20px;
  .piclist {
    overflow: hidden;
    & > li {
      width: 215px;
      height: 160px;
      margin-right: 6px;
      margin-bottom: 6px;
      float: left;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      i {
        position: absolute;
        font-size: 14px;
        color: #fff;
        background: #f54a45;
        padding: 2px 3px;
        left: 0;
        top: 0;
        line-height: 1.5;
      }
    }
  }
}
</style>

