<template>
  <div class="container">
    <PageHeader current="cars" />
    <div class="main-container">
      <div class="w1200 p15 scar-box">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item href="">
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item href=""> 特惠品牌 </a-breadcrumb-item>
        </a-breadcrumb>
        <dl class="scar-options mt30">
          <dt><span>按条件选车</span></dt>

          <dd class="flexL">
            <span class="f999">级别：</span>
            <div class="flexL">
              <ul class="flexL">
                <li
                  v-for="item in levels"
                  v-bind:key="item.id"
                  :class="item.id === selected.level ? 'on' : ''"
                >
                  <span
                    class="scar-item__span"
                    @click="selectCar('level', item)"
                    >{{ item.name }}</span
                  >
                </li>
              </ul>
            </div>
          </dd>
          <dd class="flexL">
            <span class="f999">价格：</span>
            <div class="flexL">
              <ul class="flexL">
                <li
                  v-for="item in prices"
                  v-bind:key="item.id"
                  :class="item.id === selected.price ? 'on' : ''"
                >
                  <span
                    class="scar-item__span"
                    @click="selectCar('price', item)"
                    >{{ item.name }}</span
                  >
                </li>
              </ul>
            </div>
          </dd>
          <dd class="flexL">
            <span class="f999">品牌：</span>
            <div class="flexBC" style="flex: 1">
              <ul class="flexL" style="max-width: 1070px; overflow: hidden">
                <li
                  v-for="item in opBrands"
                  v-bind:key="item.id"
                  :class="item.id === selected.brandId ? 'on' : ''"
                >
                  <span
                    class="scar-item__span"
                    @click="selectCar('brand', item)"
                    >{{ item.name }}</span
                  >
                </li>
              </ul>
              <div class="scar-brand__more">
                <span
                  class="flexR allsecs"
                  @click="brandsVisiable = !brandsVisiable"
                  >全部</span
                >
                <div class="allBrand scroll_bar" v-show="brandsVisiable">
                  <dl
                    class="flexL"
                    v-for="item in brands"
                    v-bind:key="item.letter"
                  >
                    <dt>{{ item.letter }}</dt>
                    <dd class="flexL" style="flex-wrap: wrap">
                      <span
                        v-for="brand in item.brands"
                        v-bind:key="brand.id"
                        @click="moreSelectCar('brand', brand)"
                        >{{ brand.name }}</span
                      >
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <div class="scar-search flexBC" v-if="confirmed.length > 0">
          <div class="flexL">
            <div class="f999">已选：</div>
            <div class="flexL scar-search__items">
              <div
                class="flexR"
                v-for="item in confirmed"
                v-bind:key="item.option"
              >
                {{ item.name
                }}<span class="ml5" style="cursor: pointer"
                  ><a-icon
                    type="close"
                    @click="clearCar(item.option)"
                    style="margin-left: 5px"
                /></span>
              </div>
            </div>
          </div>
          <div class="clearResult flexR" @click="clearAll()">
            <a-icon type="delete" /> <span class="f999 ml5">清空条件</span>
          </div>
        </div>
      </div>

      <div class="scar-results w1200">
        <div class="title">热门特卖车型</div>

        <div class="flexL resultlist" style="flex-wrap: wrap">
          <div
            class="sacr-result__item"
            v-for="item in results"
            v-bind:key="item.id"
            @click="changeFormFields(item)"

          >
            <!-- <router-link
              :to="{ name: 'Series', params: { id: item.id } }"
              class="scar-item__a"
            > -->
            <div class="scar-result__carimg">
              <img :src="item.cover_url" />
              <div class="scar-result__blogo flexR">
                <img :src="item.brand.logo_url" />
              </div>
            </div>
            <div
              class="scar-result__intro flexR lh150"
              style="flex-direction: column"
            >
              <p class="f16" style="color: #333">{{ item.name }}</p>
              <p style="color: #f33e3e">{{ item.price }}</p>
            </div>
            <!-- </router-link> -->
          </div>

          <div
            class=""
            style="margin: 50px auto 50px; text-align: center"
            v-if="results.length === 0"
          >
            <img
              src="@/assets/icons/noresult.png"
              style="width: auto; height: auto"
            /><br />
            <span style="font-size: 16px"
              >该条件暂无车型数据，换个条件试试吧 ∼</span
            >
          </div>
        </div>

        <div class="page-container" v-if="total > 20">
          <a-pagination
            :current="page"
            :total="total"
            :defaultPageSize="limit"
            @change="onChange"
          />
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>

      <!-- 活动弹窗 -->
      <div class="act-modal" v-show="activityModalVisiable">
        <div class="act-mask"></div>
        <div class="act-modal-content">
          <div class="act-close" @click="closeActModal">
            <a-icon type="close" />
          </div>
          <div class="act-modal__body">
            <div class="act-modal__lbox">
              <img src="@/assets/acts/xsth.png" class="atm-limg" width="500" />
            </div>
            <div class="act-modal__rbox">
              <div class="atmr__title">
                <img src="@/assets/acts/hdjxz.png" alt="" />
              </div>
              <div class="atmr__form">
                <a-form
                  :form="form"
                  :label-col="{ span: 5, style: { color: '#fff' } }"
                  :wrapper-col="{ span: 19 }"
                  @submit="handleSubmit"
                >
                  <a-form-item label="意向品牌" style="margin-bottom: 5px">
                    <a-input
                      v-decorator="[
                        'brand_name',
                        {
                          rules: [],
                        },
                      ]"
                      :disabled="true"
                    />
                  </a-form-item>
                  <a-form-item label="意向车系" style="margin-bottom: 5px">
                    <a-input
                      v-decorator="[
                        'series_name',
                        {
                          rules: [],
                        },
                      ]"
                      :disabled="true"
                    />
                  </a-form-item>
                  <a-form-item label="报名城市" style="margin-bottom: 5px">
                    <a-input
                      v-decorator="[
                        'city_name',
                        {
                          rules: [],
                        },
                      ]"
                      :disabled="true"
                    />
                  </a-form-item>
                  <a-form-item label="您的姓名" style="margin-bottom: 5px">
                    <a-input
                      v-decorator="[
                        'name',
                        {
                          rules: [],
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="您的电话" style="margin-bottom: 10px">
                    <a-input
                      v-decorator="[
                        'phone',
                        {
                          rules: [],
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item
                    :wrapper-col="{ span: 19, offset: 3 }"
                    style="margin-bottom: 10px"
                  >
                    <a-button
                      type="primary"
                      html-type="submit"
                      class="atm__submit"
                      :loading="submitLoading"
                    >
                      立即报名参与活动
                    </a-button>
                  </a-form-item>
                </a-form>
                <p style="text-align: center; font-size: 12px">
                  点击提交即视为同意
                  <router-link :to="{ name: 'Agreement' }" target="_blank" style="color:#666;"
                    >《个人信息保护声明》</router-link
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="act-modal__footer">
            <div class="atmf__l">
              <img src="@/assets/acts/hdfl.png" alt="" />
            </div>
            <div class="atmf__r">
              <p class="atmf__rp">
                <img src="@/assets/acts/li.png" />活动让利
                经销商冲量，参与活动价更优！
                <img src="@/assets/acts/li.png" class="ml15" />报名优惠
                专员及时回访解答，省时省钱省心
              </p>
              <p class="atmf__rp">
                <img src="@/assets/acts/li.png" />现场看车
                解答车牌、车险、车型、保养，VIP式购车体验！
                <img src="@/assets/acts/li.png" class="ml15" />好车标准
                车厂直提，依法享受“三包”待遇；支持全国联保
              </p>
              <p class="atmf__rp">
                <img src="@/assets/acts/li.png" />分期购车
                低首付、低月供、灵活还款，购车无负担
                <img src="@/assets/acts/li.png" class="ml15" />车源保障
                优先提车，信誉可靠，杜绝车财两空！
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { getSearchCars } from "@/api/car";
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import CarsData from "@/data/cars";
import BrandsData from "@/data/brands";
import Footer from "@/components/Footer";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      loading: false,
      levels: CarsData.levels,
      prices: CarsData.prices,
      opBrands: CarsData.brands,
      selected: {
        level: "",
        price: 0,
        brandId: 0,
        min: 0,
        max: 0,
      },
      brands: [],
      brandsVisiable: false,
      news: [],
      confirmed: [],
      total: 0,
      results: [],
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "activity" }),
      activityModalVisiable: false,
      cur_city: "北京",
      cur_cid: 100,
      submitLoading: false,
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    if (this.$route.query.level !== undefined) {
      this.selected.level = this.$route.query.level;
    }

    if (this.$route.query.bid !== undefined) {
      this.selected.brandId = this.$route.query.bid;
    }

    this.initData();
    this.getData();
    this.getCityId();
  },
  beforeDestroy() {},
  methods: {
    initData() {
      let letters = []; // 数据列表
      let brandMap = {}; // 每一个索引指向一个数组，这个数组存储该字母索引的朋友项

      BrandsData.forEach((brand) => {
        const letter = brand.letter;
        brandMap[letter] || (brandMap[letter] = []);
        brandMap[letter].push(brand);
      });

      for (let letter in brandMap) {
        letters.push({
          letter: letter,
          brands: brandMap[letter],
        });
      }

      // 按首字母排序
      // 字符串比较不能用减号得出大小，返回都是NaN
      // 因此这里需要通过小于号比较
      letters.sort((a, b) => {
        return a.letter <= b.letter ? -1 : 1;
      });

      this.brands = letters;
    },
    getData() {
      let formData = {
        page: this.page,
        limit: this.limit,
        levels: this.selected.level,
        min_price: this.selected.min,
        max_price: this.selected.max,
        bid: this.selected.brandId,
      };
      getSearchCars(formData).then((response) => {
        this.results = response.data.list;
        this.total = response.data.count;
      });
    },
    selectCar(option, obj) {
      this.page = 1;
      if (option === "level") {
        this.selected.level = obj.id;
      }
      if (option === "price") {
        this.selected.price = obj.id;
        this.selected.min = obj.min;
        this.selected.max = obj.max;
      }
      if (option === "brand") {
        this.selected.brandId = obj.id;
      }
      console.log(obj);
      var existed = false;

      for (let i = 0; i < this.confirmed.length; i++) {
        if (this.confirmed[i]["option"] == option) {
          existed = true;
          this.confirmed[i]["name"] = obj.name;
        }
      }

      if (!existed) {
        this.confirmed.push({
          option: option,
          name: obj.name,
        });
      }

      this.getData();
    },
    moreSelectCar(option, obj) {
      this.selectCar(option, obj);
      this.brandsVisiable = false;
    },
    clearCar(option) {
      var s = [];
      this.confirmed.forEach((item) => {
        if (item.option !== option) {
          s.push(item);
        }
      });
      switch (option) {
        case "price":
          this.selected.price = 0;
          break;
        case "level":
          this.selected.level = "";
          break;
        case "brand":
          this.selected.brandId = 0;
          break;
      }

      this.confirmed = s;
      this.page = 1;
      this.getData();
    },
    clearAll() {
      this.selected.level = "";
      this.selected.price = 0;
      this.selected.brandId = 0;
      this.selected.min = 0;
      this.selected.max = 0;
      this.confirmed = [];
      this.page = 1;
      this.getData();
    },
    onChange(current) {
      this.page = current;
      this.getData();
    },
    getCityId() {
      let curCity = localStorage.getItem("_cur_city");
      if (curCity) {
        let curCityData = JSON.parse(curCity);
        this.cur_cid = curCityData.c_id;
        this.cur_city = curCityData.c;
        return curCityData.c_id;
      }

      return 100;
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      const h = this.$createElement;
      this.form.validateFields((err) => {
        if (!err) {
          var name = this.form.getFieldValue("name");
          var phone = this.form.getFieldValue("phone");
          if (!name) {
            return that.$message.warning("请填写姓名");
          }
          if (!phone) {
            return that.$message.warning("请填写 11 位手机号");
          }

          if (!/^1[345789]\d{9}$/.test(phone)) {
            return that.$message.warning(
              "手机号格式错误，请填写正确的手机号码"
            );
          }

          if (that.submitLoading) {
            return;
          }
          that.submitLoading = true;
          let sec = 500 + parseInt(Math.random() * 500);
          setTimeout(() => {
            that.$success({
              title: "提交成功",
              content: h("div", {}, [h("p", "稍后我们工作人员会联系您")]),
            });
            that.submitLoading = false;
            that.activityModalVisiable = false;
          }, sec);
        }
      });
    },
    changeFormFields(obj) {
      this.form.setFieldsValue({
        brand_name: obj.brand.name,
        series_name: obj.name,
        city_name: this.cur_city,
      });

      this.activityModalVisiable = true;
    },
    closeActModal() {
      this.activityModalVisiable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  clear: both;
  width: 440px;
  margin: 20px auto 10px;
}
.scar-box {
  padding: 15px;
  .scar-options {
    margin-top: 30px;
    & > dt {
      border-bottom: 2px solid #e7e7e7;
      & > span {
        font-size: 20px;
        position: relative;
        padding-bottom: 10px;
        display: inline-block;
      }
      & > span::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #f33e3e;
        bottom: -2px;
        left: 0;
        z-index: 1;
      }
    }

    & > dd {
      border-bottom: 1px solid #e7e7e7;
      padding: 10px 0;
      ul {
        margin-bottom: 0px;
        li {
          padding: 0 5px;
          cursor: default;
          margin: 10px;
          white-space: nowrap;
          position: relative;
          &.on {
            color: #fff;
            background-color: #f7891b;
            border-radius: 2px;
            height: auto;
          }
        }
      }
    }
  }

  .scar-search {
    height: 80px;
    border: 1px solid #e7e7e7;
    padding: 0 15px;
    .scar-search__items {
      & > div {
        height: 30px;
        background-color: #fbf7f2;
        padding: 0 13px;
        color: #f7891b;
        margin: 0 10px;
      }
    }
  }
}
.scar-results {
  .title {
    font-size: 20px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .sacr-result__item {
    width: 216px;
    height: 230px;
    background-color: #fff;
    margin: 7px 12px;
    cursor: pointer;
    .scar-result__carimg {
      position: relative;
      & > img {
        width: 216px;
        height: 170px;
      }
      .scar-result__blogo {
        position: absolute;
        width: 50px;
        height: 40px;
        background-color: #fff;
        border-radius: 0px 4px 4px 0px;
        top: 5px;
        left: 0;
        padding: 0;
        & > img {
          height: 30px;
          width: 30px;
        }
      }
    }

    .scar-result__intro {
      line-height: 150%;
    }
  }
}
.scar-brand__more {
  display: inline-block;
  width: 70px;
  border: solid 1px #e7e7e7;
  height: 26px;
  color: #666;
  cursor: pointer;
  position: relative;
  .allBrand {
    position: absolute;
    width: 1200px;
    right: 0;
    height: 550px;
    background-color: #fff;
    z-index: 5;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-y: auto;
    box-shadow: 0px 4px 10px 0px;
    top: 30px;

    & > dl {
      padding: 25px 0;
      border-bottom: 1px solid #eeecec;
      & > dt {
        font-weight: bold;
        color: #f33e3e;
        font-size: 14px;
        margin-right: 30px;
      }
      & > dd {
        span {
          margin-right: 30px;
        }
      }
    }
  }
}
.scar-item__span {
  cursor: pointer;
}
.f999 {
  color: #999;
}
.f16 {
  font-size: 16px;
}
.clearResult {
  cursor: pointer;
  span {
    margin-left: 5px;
  }
}
.scar-item__a:hover {
  box-shadow: 1px 2px 10px 0px;
}

.act-modal {
  position: relative;

  .act-modal-content {
    position: fixed;
    box-sizing: border-box;
    width: 1000px;
    min-height: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    z-index: 11;
    background: url("../assets/acts/bg.png");

    .act-modal__body {
      display: flex;
      width: 960px;
      padding: 20px;
      height: 410px;
      .act-modal__lbox {
        display: flex;
        width: 550px;
        align-items: center;
        .atm-limg {
          width: 600px;
          height: 285px;
        }
      }
      .act-modal__rbox {
        width: 360px;
        .atmr__title {
          height: 50px;
          text-align: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 20px;
          img {
            height: 50px;
          }
        }
        .atmr__form {
          width: 360px;
          input {
            outline: none;
            margin-left: 5px;
            border: 1px solid #fff;
          }
          input:focus {
            outline: none;
            box-shadow: none;
          }
          .atm__submit {
            width: 100%;
            font-size: 16px;
            background: #ea6724;
            color: #fff;
            font-weight: 500;
            //   padding-top: 20px;
            //   padding-bottom: 20px;
            border: 1px solid #ea6724;
            height: 40px;
          }
        }
      }
    }
    .act-modal__footer {
      background: #61308a;
      width: 100%;
      height: 80px;
      .atmf__l {
        width: 220px;
        height: 80px;

        float: left;
        img {
          height: 40px;
          margin: 20px auto;
          margin-left: 50px;
        }
      }
      .atmf__r {
        float: left;
        padding: 10px 0;
        .atmf__rp {
          margin-bottom: 5px;
          font-weight: 500;
          color: #fff;
          font-size: 12px;
          img {
            height: 14px;
            margin-right: 3px;
          }
        }
      }
    }
  }
  .act-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  }

  .act-close {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 18px;
    cursor: pointer;
  }
}

.ml15 {
  margin-left: 15px;
}
</style>

