<template>
  <div class="service_intro">
        <ul class="w1200 flexBC">
          <li class="flexR">
            <span>
              <img src="https://img.kchezhan.com/images/lzweb/footI1.png" />
            </span>
            <p>正规经销商购车</p>
          </li>
          <li class="flexR">
            <span
              ><img src="//img.kchezhan.com/images/lzweb/footI2.png"
            /></span>
            <p>同城价格低</p>
          </li>
          <li class="flexR">
            <span
              ><img src="//img.kchezhan.com/images/lzweb/footI3.png"
            /></span>
            <p>不收取服务费</p>
          </li>
          <li class="flexR">
            <span
              ><img src="//img.kchezhan.com/images/lzweb/footI4.png"
            /></span>
            <p>线上询价购车</p>
          </li>
          <li class="flexR">
            <span
              ><img src="//img.kchezhan.com/images/lzweb/footI5.png"
            /></span>
            <p>协助售后服务</p>
          </li>
        </ul>
      </div>
</template>

<script>

export default {
  name: "ServiceIntro",
  data() {
    return {};
  },
  props: {
    
  },
  mounted() {},
  computed: {},
  methods: {
     
  },
};
</script>

<style lang="scss" scoped>
.service_intro {
  box-shadow: 0px -2px 6px 0px rgba(141, 141, 141, 0.1);
  margin-top: 20px;
  background: #efefef;
  clear: both;
  ul {
    border: 0;
    height: 220px;

    li {
      border: 0;
      flex-direction: column;
      flex: 1;

      height: 200px;
      & > span {
        border-radius: 10px;
        border: 0;
        background-image: unset;
        background-image: linear-gradient(-40deg, #fd2549 0%, #ff8750 100%),
          linear-gradient(#d62727, #d62727);
        background-size: 150% 150%;
        animation: tbgmove 2.5s infinite;
        -webkit-animation: tbgmove 2.5s infinite;
        width: 80px;
        height: 80px;
        img {
          display: inline;
          border: none;
          font-size: 0px;
        }
      }
      p {
        color: #333;
        font-size: 16px;
        margin-top: 20px;
      }
    }
  }
}
</style>
