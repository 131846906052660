import request from '@/utils/request'

export function getDealers(params) {
    return request({
        url: '/dealers',
        method: 'get',
        params
    })
}

export function getDealerInfo(id, params) {
    return request({
        url: `/dealer/${id}`,
        method: 'get',
        params
    })
}

export function getDealerHotSeries(id, params) {
    return request({
        url: `/dealer/${id}/series/hots`,
        method: 'get',
        params
    })
}

export function getDealerSeries(id, params) {
    return request({
        url: `/dealer/${id}/series`,
        method: 'get',
        params
    })
}

export function getDealerNews(id, params) {
    return request({
        url: `/dealer/${id}/news`,
        method: 'get',
        params
    })
}

export function getDealerNewsById(id, params) {
    return request({
        url: `/dealer/news/${id}`,
        method: 'get',
        params
    })
}