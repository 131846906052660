<template>
  <div class="container">
    <DealerHeader current="quote" />
    <div class="main-container">
      <div class="w1200">
        <div style="padding: 20px 0 5px">
          当前位置：
          <router-link :to="{ name: 'DealerIndex' }"> 店铺首页 </router-link>
          &gt;
          <span>询价/试驾</span>
        </div>
      </div>

      <div class="w1200 clearFix" style="margin-top: 15px; min-height: 520px">
        <div class="fl">
          <div class="enquiry_box" style="height: 504px">
            <h2>
              <span class="redcolor">请您填写如下信息，即可获取最新报价</span
              >(您的私人信息将会得到严格保密)
            </h2>
            <a-form :form="form" @submit="handleSubmit" style="width: 500px">
              <a-form-item v-bind="formItemLayout" label="所属城市">
                <a-select
                  style="width: 155px; margin-right: 20px"
                  @change="handleProvinceChange"
                  size="large"
                  placeholder="请选择省份"
                  :allowClear="true"
                  v-decorator="[
                    'province_id',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择省份!',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="province in cities"
                    :key="province.value"
                    :value="province.value"
                  >
                    {{ province.label }}
                  </a-select-option>
                </a-select>
                <a-select
                  style="width: 155px"
                  size="large"
                  placeholder="请选择城市"
                  :allowClear="true"
                  v-decorator="[
                    'city_id',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择城市!',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="city in subCities"
                    :key="city.value"
                    :value="city.value"
                  >
                    {{ city.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item v-bind="formItemLayout" label="意向车型">
                <a-select
                  style="width: 155px; margin-right: 20px"
                  @change="handleBrandChange"
                  size="large"
                  placeholder="请选择品牌"
                  :allowClear="true"
                  v-decorator="[
                    'brand_id',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择品牌!',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="brand in brands"
                    :key="brand.id"
                    :value="brand.id"
                  >
                    {{ brand.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  style="width: 155px"
                  size="large"
                  placeholder="请选择车系"
                  :allowClear="true"
                  v-decorator="[
                    'series_id',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择车系!',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="s in series"
                    :key="s.id"
                    :value="s.id"
                  >
                    {{ s.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-bind="formItemLayout">
                <span slot="label"> 您的姓名 </span>
                <a-input
                  placeholder="请输入您的姓名"
                  size="large"
                  v-decorator="[
                    'nickname',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入您的姓名！',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>

              <a-form-item v-bind="formItemLayout" label="您的电话">
                <a-input
                  placeholder="请输入您的电话"
                  size="large"
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入您的电话!',
                        },
                        {
                          validator: validatePhone,
                        },
                      ],
                    },
                  ]"
                  style="width: 100%"
                >
                </a-input>
              </a-form-item>

              <a-form-item v-bind="tailFormItemLayout">
                <a-checkbox
                  v-decorator="[
                    'agreement',
                    {
                      valuePropName: 'checked',
                      rules: [
                        {
                          required: true,
                          message: '必须认真仔细看过并同意服务协议',
                        },
                      ],
                    },
                  ]"
                >
                  我已看过并同意
                  <a href="javascript:void(0);" @click="modalVisible = true">
                    《服务协议》
                  </a>
                </a-checkbox>
              </a-form-item>
              <a-form-item v-bind="tailFormItemLayout">
                <a-button
                  type="primary"
                  size="large"
                  html-type="submit"
                  class="submit-btn"
                  :loading="loading"
                >
                  询价/试驾
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div class="fr main_brand_box">
          <!-- 主营品牌 -->
          <DealerMainBrand />
        </div>
      </div>

      <a-modal
        v-model="modalVisible"
        title="晟行汽车服务协议"
        width="80%"
        centered
        @ok="() => (modalVisible = false)"
        :footer="null"
      >
        晟行科技及其关联公司（如无特殊约定，以下统称“晟行”）收集用户的个人信息的最终目的是为了向您提供更好的产品、服务，优化并丰富您的用户体验。晟行将按照本声明的规定合理使用并保护用户的个人信息，据此制订《个人信息保护声明》如下：<br />
        <p>一、个人信息的收集</p>
        <p>
          1、晟行向用户收集的个人信息指的是能够单独或与其他信息结合识别您个人身份的信息，这些信息包括：①车型②联系方式③您在页面上留下的其他信息（车牌号、里程数、卖车意向等）④在您上载到网站的内容中包含的任何个人信息。
        </p>
        <p>
          2、以上个人信息均是用户自愿提供。您有权拒绝提供，但如果您拒绝提供某些个人信息，您将可能无法使用晟行提供的服务、产品，或者可能对您使用服务或产品造成一定的影响。
        </p>
        <p>
          3、对于不满18岁的用户，须在其法定监护人已经阅读本声明并且许可的情况下，通过网站提交个人信息。
        </p>
        <p>二、个人信息的使用</p>
        <p>
          1、我们会根据本声明为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
        </p>
        <p>
          2、您同意，晟行可以通过以下方式对您个人信息进行使用和分享（包含对于个人信息的存储和处理）：
        </p>
        <p>（1）我们和关联公司使用；</p>
        <p>（2）我们向相关第三方服务商分享并由其使用；</p>
        <p>
          （3）我们及关联公司及相关第三方服务商为满足您的需求，可能通过您提供的信息与您联系；
        </p>
        <p>
          （4）我们及关联公司及相关第三方服务商可能定期或不定期向您发送有关产品、服务或相关活动的信息，您同意接收上述信息，您同意免除上述个人信息的接收和/或使用方在按照本法律声明所述情况下进行信息披露和使用而导致的或可能导致的所有索赔、责任和损失。
        </p>
        <p>
          3、用户明确同意使用本平台服务的使用所存在的风险将完全由您自己承担，因用户使用晟行服务而产生的一切后果也由您自己承担，晟行对您不承担任何责任。
        </p>
        <p>三、联系我们</p>
        <p>
          如您对本声明或您个人信息的相关事宜有任何问题、意见或建议，您可随时拨打我们的客服电话：15117971875，或与我们的在线客服取得联系。
        </p>
      </a-modal>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import DealerHeader from "@/components/dealer/DealerHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import CityData from "@/data/city";
import DealerMainBrand from "@/components/dealer/MainBrand";
import { getDealerSeries } from "@/api/dealer";

export default {
  name: "Index",
  components: {
    DealerHeader,
    ServiceIntro,
    Footer,
    DealerMainBrand,
  },
  data() {
    return {
      cities: CityData,
      modalVisible: false,
      loading: false,
      subCities: [],
      brands: [],
      series: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      query: {
        bid: null,
        sid: null,
      },
    };
  },
  computed: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.id = this.$route.params.id;
    if (this.$route.query.bid != undefined) {
      this.query.bid = this.$route.query.bid;
    }
    if (this.$route.query.sid != undefined) {
      this.query.sid = this.$route.query.sid;
    }
    this.getSeriesData();
  },
  beforeDestroy() {},
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      if (this.loading) {
        return;
      }
      let that = this;
      this.form.validateFieldsAndScroll((err) => {
        if (!err) {
          let seconds = parseInt(Math.random() * 1000);
          that.loading = true;
          setTimeout(function () {
            that.$message.success("提交成功，稍后经销商会与您联系");
            that.loading = false;
          }, seconds);
        }
      });
    },
    validatePhone(rule, value, callback) {
      console.log(value);
      if (value && !/^1[3456789]\d{9}$/.test(value)) {
        callback("手机号格式错误");
      } else {
        callback();
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    handleProvinceChange(val) {
      const form = this.form;

      if (val == undefined) {
        this.subCities = [];
        form.setFieldsValue({ city_id: undefined });
        return false;
      }
      CityData.map((item) => {
        if (item.value == val) {
          this.subCities = item.children;
        }
      });
    },
    handleBrandChange(val) {
      if (val == undefined) {
        const form = this.form;
        this.series = [];
        form.setFieldsValue({ sereis_id: undefined });
        return;
      }
      this.brands.map((item) => {
        console.log(val, item.id);
        if (item.id == val) {
          this.series = item.series;
        }
      });
    },
    getSeriesData() {
      getDealerSeries(this.id).then((resp) => {
        this.brands = resp.data;

        if (this.query.bid != null) {
          this.brands.map((item) => {
            if (item.id == this.query.bid) {
              this.series = item.series;
            }
          });
          this.form.setFieldsValue({
            brand_id: parseInt(this.query.bid),
          });

          if (this.query.sid != null) {
            this.form.setFieldsValue({
              series_id: this.query.sid,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.enquiry_box {
  padding: 25px 40px;
  width: 870px;
  box-shadow: 1px 2px 6px 0px rgba(102, 99, 94, 0.35);
  box-sizing: border-box;
  h2 {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 20px;
  }
}
.submit-btn {
  background-color: #ea3434;
  border: 1px solid #ea3434;
}
.redcolor {
  color: #e02b31;
}
</style>

