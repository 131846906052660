<template>
  <div class="container">
    <DealerHeader current="index" />
    <div class="main-container">
      <!-- 顶部新闻 -->
      <div class="top_news">
        <div class="w1200 flexBC">
          <div class="t_newswrap">
            <div class="flexR">
              <img :src="dealer.img_url" style="max-height: 280px" />
            </div>
          </div>
          <div class="dealer__news">
            <dl v-if="news.length > 0">
              <dt class="flexR">
                <router-link
                  :to="{
                    name: 'DealerNewsDetail',
                    params: {
                      id: id,
                      nid: news[0].id,
                    },
                  }"
                  >{{ news[0].title }}
                </router-link>
              </dt>

              <dd class="flexL" v-for="item in news.slice(1, 6)" :key="item.id">
                <router-link
                  :to="{
                    name: 'DealerNewsDetail',
                    params: {
                      id: id,
                      nid: item.id,
                    },
                  }"
                >
                  <span v-if="item.classify == 1">[ 行业新闻 ]</span>
                  <span v-if="item.classify == 2">[ 促销优惠 ]</span>
                </router-link>
                <router-link
                  :to="{
                    name: 'DealerNewsDetail',
                    params: {
                      id: id,
                      nid: item.id,
                    },
                  }"
                >
                  {{ item.title }}
                </router-link>
              </dd>
            </dl>
            <router-link
              class="more flexR"
              :to="{
                name: 'DealerNews',
              }"
              v-if="total >= 7"
            >
              查看更多»
            </router-link>
          </div>

          <div class="dealer-info__container">
            <div class="d-info__name flexBC">
              <div class="d-info__dn">{{ dealer.name }}</div>
              <a class="d-info__askprice" href="#md1">询价/试驾</a>
            </div>
            <div class="di__box">
              <div class="messli flexL" style="align-items: baseline">
                <div style="width: 45px">主营：</div>
                <div
                  style="width: 240px; line-height: 20px"
                  title="比亚迪/比亚迪新能源/"
                >
                  <a
                    class="jxs_brand"
                    href="javascript:void(0);"
                    v-for="b in dealer.brands"
                    :key="b.id"
                    >{{ b.name }}</a
                  >
                </div>
              </div>
              <div class="messli flexL" style="align-items: baseline">
                <div style="width: 45px">级别：</div>
                {{ dealer.level }}
              </div>
              <div class="messli flexL" style="align-items: baseline">
                <div style="width: 45px">电话：</div>
                {{ dealer.sell_phone }}
              </div>
              <div class="messli flexL" style="align-items: baseline">
                <div style="width: 45px">地址：</div>
                <p style="width: 240px; line-height: 20px">
                  {{ dealer.address }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w1200">
        <div class="hot_cars">
          <h2 class="jxs_t_index" id="md1">热销车型</h2>
          <div class="hot_cars_box" v-for="item in hotSeries" :key="item.id">
            <div class="hot_cars_li clearFix">
              <div class="hotcars_l">
                <div class="hc_l_title">
                  <div class="hc_l_con">
                    <div class="hc_l_con_1">
                      <span>{{ item.name }}</span>
                      <router-link
                        :to="{
                          name: 'DealerSeries',
                          params: { id: id },
                          query: { seriesId: item.id },
                        }"
                        >▶更多车型</router-link
                      >
                    </div>
                    <div class="hc_l_con_2">
                      <span class="h_2_t">指导价：</span
                      ><span>{{ item.price }}</span>
                    </div>
                  </div>
                </div>
                <div class="hc_l_detail">
                  <div class="hc_l_lines"></div>
                  <div class="hc_l_d_box">
                    <router-link
                      :to="{
                        name: 'DealerSeries',
                        params: { id: id },
                        query: { seriesId: item.id },
                      }"
                      v-for="model in item.models"
                      :key="model.id"
                      ><p>
                        {{ model.year }}款 {{ model.name }}
                        {{ model.env_standards }}
                      </p>
                    </router-link>
                  </div>
                </div>
                <div class="xjsj_index">
                  &gt;&gt;&gt;
                  <router-link
                    :to="{
                      name: 'DealerQuote',
                      query: {
                        bid: item.brand.id,
                        sid: item.id,
                      },
                    }"
                    class="xjsj_index_btn"
                  >
                    询价/试驾
                  </router-link>
                  &lt;&lt;&lt;
                </div>
              </div>
              <div class="hotcars_r">
                <router-link
                  :to="{
                    name: 'DealerSeries',
                    params: { id: id },
                    query: { seriesId: item.id },
                  }"
                  class="hotcars_img"
                >
                  <div class="img_logo">
                    <img :src="item.brand.logo_url" class="" />
                  </div>
                  <span id="icon-extension" class="icon-extension temai"
                    >特卖</span
                  >
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      height: 100%;
                    "
                  >
                    <img
                      :src="item.cover_url"
                      class="scrollLoading lazy"
                      width="590"
                      height="370"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="jxs_more_cars">
            <router-link
              :to="{
                name: 'DealerSeries',
                params: { id: id },
              }"
            >
              全部车型
            </router-link>
          </div>
        </div>

        <!-- 主营品牌列表 -->
        <div class="jxs_main_brands">
          <h2 class="jxs_t_index">主营品牌</h2>
          <div class="jxs_main_b_li" v-for="brand in series" :key="brand.id">
            <div class="jxsmain_box">
              <div class="jxsmain_title">
                <div class="logo_name">
                  <div class="logobg">
                    <img :src="brand.logo_url" class="scrollLoading lazy" />
                  </div>
                  {{ brand.name }}
                </div>
              </div>
              <div class="downsj_box"><div class="downsj">▼</div></div>
              <ul class="jxs_mbrandslist clearFix">
                <li v-for="item in brand.series.slice(0, 5)" :key="item.id">
                  <router-link
                    :to="{
                      name: 'DealerSeries',
                      params: { id: id },
                      query: { seriesId: item.id },
                    }"
                    style="display: block"
                  >
                    <div class="jxsbrands_img">
                      <img
                        :src="item.cover_url"
                        class="scrollLoading lazy"
                        width="390"
                        height="245"
                      />
                      <div class=""></div>
                    </div>
                    <div class="jxs_brandsname">
                      <div class="name_lines">
                        <div class="name_bransjxs">{{ item.name }}</div>
                      </div>
                    </div>
                  </router-link>
                </li>
                <router-link
                  :to="{ name: 'DealerSeries', params: { id: id } }"
                  v-if="brand.series.length >= 5"
                >
                  <li style="background-color: #fff">
                    <div
                      class="name_lines"
                      style="border: solid 3px #e0e0e0; margin-top: 135px"
                    >
                      <div
                        class="name_bransjxs"
                        style="background-color: #fff; color: #333"
                      >
                        更多车型
                      </div>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
          </div>
        </div>

        <!-- 店内动态 -->
        <div class="jxs_main_news w1200" v-if="news.length > 0">
          <h2 class="jxs_t_index" style="margin-top: 20px">店内动态</h2>
          <ul class="jxs_news_list clearFix">
            <li v-for="item in news.slice(0, 3)" :key="item.id">
              <router-link
                :to="{
                  name: 'DealerNewsDetail',
                  params: {
                    id: id,
                    nid: item.id,
                  },
                }"
              >
                <img
                  class="topimg scrollLoading lazy"
                  :src="item.img_url"
                  style="width: 100%; height: auto"
                />
              </router-link>
              <div class="news_detail">
                <router-link
                  :to="{
                    name: 'DealerNewsDetail',
                    params: {
                      id: id,
                      nid: item.id,
                    },
                  }"
                >
                  <h4>
                    {{ item.title }}
                  </h4>
                </router-link>

                <router-link
                  :to="{
                    name: 'DealerNewsDetail',
                    params: {
                      id: id,
                      nid: item.id,
                    },
                  }"
                >
                  <p>
                    {{ item.summary }}
                  </p>
                </router-link>
                <div class="time_a clearFix">
                  <span class="fl">{{ item.published_at }}</span>

                  <router-link
                    :to="{
                      name: 'DealerNewsDetail',
                      params: {
                        id: id,
                        nid: item.id,
                      },
                    }"
                    style="
                      border: 3px solid rgb(102, 102, 102);
                      background-position: 11px -15px;
                    "
                    class="fr"
                  >
                  </router-link>
                </div>
              </div>
            </li>
          </ul>
          <div class="jxs_more_cars">
            <router-link
              :to="{
                name: 'DealerNews',
                params: {
                  id: id,
                },
              }"
            >
              查看更多
            </router-link>
          </div>
        </div>
      </div>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import DealerHeader from "@/components/dealer/DealerHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import {
  getDealerInfo,
  getDealerHotSeries,
  getDealerSeries,
  getDealerNews,
} from "@/api/dealer";

export default {
  name: "Index",
  components: {
    DealerHeader,
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      id: 0,
      dealer: {},
      hotSeries: [],
      series: [],
      news: [],
      page: 1,
      limit: 10,
      total: 0,
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getDealerInfo(this.id).then((resp) => {
        this.dealer = resp.data;
      });

      getDealerHotSeries(this.id).then((resp) => {
        this.hotSeries = resp.data;
      });

      getDealerSeries(this.id).then((resp) => {
        this.series = resp.data;
      });

      getDealerNews(this.id, {
        page: this.page,
        limit: this.limit,
      }).then((resp) => {
        this.news = resp.data.list;
        this.total = resp.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}

.clearFix {
  zoom: 1;
}

.top_news {
  background-image: url("http://img.shengxingdt.com/web/dealerIndexBg.jpeg?x-oss-process=style/default.jpg");
  background-position: center bottom;
  padding-bottom: 40px;
  background-size: 96%;
  padding-top: 20px;
  .t_newswrap {
    width: 400px;
    height: 280px;
    overflow: hidden;
  }

  .dealer__news {
    width: 400px;
    height: 280px;
    dl {
      margin-bottom: 10px;
      dt {
        a {
          font-size: 20px;
          color: #e02b31;
          margin-bottom: 15px;
        }
      }

      dd {
        line-height: 38px;
        margin-bottom: 0;
        &::before {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          display: inline-block;
          border-radius: 50%;
          background-color: #bebebe;
          margin-right: 10px;
          text-align: center;
        }
        a {
          color: #333;
          outline: none;
          &:nth-child(2) {
            margin-left: 15px;
            flex: 1;
          }
        }
      }
    }

    a.more {
      height: 34px;
      background-color: #f9f9f9;
      border: solid 1px #e9e9e9;
      width: 100%;
      color: #333;
    }
  }

  .dealer-info__container {
    width: 320px;
    height: 280px;
    background-color: #ffffff;
    border: solid 1px #ecf0ff;
    overflow: hidden;
    background-image: url("http://img.shengxingdt.com/web/dealerInfoBg.png?x-oss-process=style/default.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 25px 25px;
    box-sizing: border-box;
    .d-info__name {
      height: 68px;
      overflow: hidden;
      .d-info__dn {
        font-size: 16px;
        color: #333;
        width: 160px;
        line-height: 20px;
      }
      .d-info__askprice {
        width: 94px;
        height: 30px;
        background-color: #e02b31;
        border-radius: 4px;
        display: inline-block;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: box-shadow 0.4s;
        line-height: 30px;
      }
    }

    .di__box {
      margin-top: 40px;
      .messli {
        line-height: 36px;
        height: 36px;
        a {
          text-decoration: none;
          color: #333;
          outline: none;
        }
      }
    }
  }
}
.jxsmain_box {
  overflow: hidden;
}
h2.jxs_t_index {
  font-weight: normal;
  font-size: 32px;
  color: #333;
  line-height: 40px;
  height: 50px;
  padding-left: 15px;
  letter-spacing: 0px;
  border-bottom: 1px solid #333;
}

.hot_cars {
  padding-top: 30px;
  padding-bottom: 35px;

  .hot_cars_box {
    margin-top: 30px;
  }
  .hot_cars_li {
    margin-bottom: 17px;

    width: 1200px;
    height: 430px;
    .hotcars_l {
      width: 570px;
      float: left;
      background-color: #a32222;
      height: 430px;
      .hc_l_title {
        width: 380px;
        border: solid 6px #b22b2b;
        height: 144px;
        margin-top: 32px;
        margin-left: 100px;
        position: relative;
        .hc_l_con {
          background-color: #a32222;
          position: absolute;
          left: -50px;
          top: 22px;
          height: 94px;
          width: 350px;
          color: #fff;
          box-sizing: border-box;
          padding: 15px 0;
        }
        .hc_l_con_1 {
          span {
            font-size: 26px;
            padding-right: 30px;
            display: inline-block;
            max-width: 230px;
            overflow: hidden;
            height: 30px;
            line-height: 30px;
          }
          a {
            font-size: 16px;
            color: #fff;
            cursor: pointer;
          }
        }

        .hc_l_con_2 {
          font-size: 26px;
          margin-top: 5px;
          span.h_2_t {
            font-size: 16px;
          }
        }
      }

      .hc_l_detail {
        color: #fff;
        padding-left: 56px;
        .hc_l_lines {
          width: 30px;
          height: 3px;
          background-color: #ffffff;
          margin: 10px 0 25px;
        }

        .hc_l_d_box {
          max-height: 122px;
          overflow: hidden;
          min-height: 90px;
          a {
            color: #fff;
            display: block;
          }
          p {
            font-size: 16px;
            line-height: 30px;
            width: 400px;
            overflow: hidden;
            height: 30px;
            margin-bottom: 0;
          }
        }
      }

      .xjsj_index {
        text-align: center;
        color: #fff;
        margin-top: 24px;
        font-size: 16px;
        line-height: 54px;
        a.xjsj_index_btn {
          cursor: pointer;
          color: #fff;
          display: inline-block;
          width: 150px;
          height: 44px;
          line-height: 44px;
          font-size: 16px;
          background-image: linear-gradient(
              0deg,
              #ce4747 0%,
              #a32222 50%,
              #b93535 77%,
              #ce4747 100%
            ),
            linear-gradient(#ed494f, #ed494f);
          background-blend-mode: normal, normal;
          border-radius: 8px;
          border: solid 1px #ffbcbc;
        }
      }
    }
  }

  .hotcars_r {
    width: 630px;
    position: relative;
    float: right;
    height: 430px;
    background-color: #f6f6f6;
    .hotcars_img {
      width: 590px;
      height: 370px;
      padding-top: 0;
      background-color: #fff;
      position: absolute;
      top: 30px;
      left: -25px;
      text-align: center;
      box-sizing: border-box;
    }
    .img_logo {
      position: absolute;
      left: 20px;
      top: 15px;
      width: 110px;
      height: 70px;
      background-color: #fff;
      border-radius: 0.5rem;
      overflow: hidden;
      & > img {
        width: auto;
        height: 70px;
        margin-top: 0;
        text-align: center;
        display: inline;
        border: none;
        font-size: 0px;
      }
    }

    .icon-extension {
      position: absolute;
      right: 0;
      background: url("http://img.shengxingdt.com/web/dealer/index-icon.png?x-oss-process=style/default.jpg")
        no-repeat;
      top: 0;
      width: 152px;
      height: 93px;
      overflow: hidden;
      line-height: 200px;
    }
    .icon-extension.temai {
      background-position: -320px 0;
    }
  }
}

.jxs_more_cars {
  text-align: center;
  margin: 20px 0 0px;
  a {
    display: inline-block;
    width: 200px;
    height: 60px;
    background-image: linear-gradient(
        0deg,
        #cacaca 0%,
        #e5e5e5 45%,
        #ffffff 100%
      ),
      linear-gradient(#000000, #000000);
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: solid 1px #999999;
    font-size: 24px;
    color: #333;
    line-height: 60px;
  }
}

.jxs_main_brands {
  min-height: 450px;
  overflow: hidden;
  .jxs_main_b_li {
    background-image: linear-gradient(
        180deg,
        #d0d0d0 0%,
        #e8e8e8 5%,
        #ffffff 100%
      ),
      linear-gradient(#ececec, #ececec);
    background-blend-mode: normal, normal;
    &:last-child {
      margin-bottom: 40px;
    }

    .jxsmain_title {
      background-color: #a32222;
      line-height: 70px;
      height: 70px;
      font-size: 28px;
      color: #fff;
      text-align: center;
      position: relative;
      .logo_name {
        position: absolute;
        top: -13px;
        left: 35%;
        line-height: 91px;
        .logobg {
          background-image: url("http://img.shengxingdt.com/web/dealer/jxslogobg.png?x-oss-process=style/default.jpg");
          width: 138px;
          height: 100px;
          display: inline-block;
          img {
            width: 70px;
            height: 70px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .downsj_box {
    text-align: center;
    margin-top: -20px;
    .downsj {
      color: #a32222;
      font-size: 30px;
      font-stretch: ultra-expanded;
    }
  }

  ul.jxs_mbrandslist {
    margin: 0 -7.5px;
    & li {
      height: 390px;
      box-sizing: border-box;
      border: 1px solid #ececec;
      margin: 0 7.5px;
      margin-bottom: 15px;
      width: 390px;

      background-color: #ececec;
      float: left;
      box-sizing: border-box;
      .jxsbrands_img,
      .jxsbrands_img img {
        overflow: hidden;
        background-color: #fff;
        text-align: center;
        width: 388px;
        height: 300px;
        padding-top: 0;
      }

      .jxs_brandsname {
        width: 390px;
        height: 90px;
        background-color: #a32222;
        text-align: center;
        padding-top: 14px;
        box-sizing: border-box;
      }
      .name_lines {
        width: 100px;
        height: 56px;
        border: solid 3px #b22b2b;
        margin: 0 auto;
        position: relative;
        .name_bransjxs {
          background-color: #a32222;
          position: absolute;
          font-size: 24px;
          color: #fff;
          width: 390px;
          text-align: center;
          top: 13px;
          left: -148px;
        }
      }
    }
  }
}

.jxs_news_list {
  width: 1220px;
  height: 480px;
  li {
    list-style: outside none none;
    margin-right: 15px;
    width: 390px;
    height: 450px;
    background-color: #ffffff;
    box-shadow: 1px 2px 6px 0px rgb(102, 99, 94);
    float: left;
    .news_detail {
      padding: 25px 20px;
      height: 250px;
      box-sizing: border-box;
      margin-top: -9px;
      h4 {
        font-size: 18px;
        color: #333;
        font-weight: normal;
        height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      p {
        width: 350px;
        font-size: 14px;
        height: 98px;
        line-height: 30px;
        color: #666;
        margin: 8px 0 15px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .time_a {
        span {
          color: #999;
          line-height: 40px;
        }

        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          border: solid 3px #666666;
          border-radius: 30px;
          background-image: url("http://img.shengxingdt.com/web/dealer/gonews.png?x-oss-process=style/default.jpg");
          background-repeat: no-repeat;
          background-position: 11px -15px;
        }
      }
    }
  }
}

.new-row-a {
  display: block;
  width: 390px;
  height: 200px;
  overflow: hidden;
}
</style>

