<template>
  <div class="container">
    <PageHeader />
    <div class="main-container">
      <div class="w1200 city-container">
        <div class="allCity_dl cityhot" style="padding-bottom: 0">
          <dl class="flexL" style="padding-left: 10px; align-items: flex-start">
            <dt style="width: 6em; font-size: 16px">热门城市：</dt>
            <dd style="border: 0; flex: 1">
              <span
                class="city-hot__item"
                v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                v-bind:key="item"
              >
                北京
              </span>
            </dd>
          </dl>
        </div>
        <p style="padding: 0 0 20px 10px; font-size: 16px; margin-bottom: 0px">
          全部城市：
        </p>
        <div class="allCity_dl">
          <dl class="flexL" style="align-items: flex-start" v-for="(item, i) in cities" v-bind:key="i">
            <dt class="city__province">{{ item.label }}</dt>
            <dd style="flex: 1; border-bottom: 1px solid #e4e9ed">
              <span
                class="city-hot__item"
                v-for="(city, ii) in item.children"
                v-bind:key="ii"
              >
                {{city.label}}
              </span>
            </dd>
          </dl>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>

      <Footer />
    </div>
  </div>
</template>

<script>

import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import CityData from '@/data/city';

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      cities: CityData
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      // getIndexNews({ page: this.page, limit: this.limit }).then((response) => {
      //   this.news = response.data;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.city-container {
  min-height: 500px;
  .allCity_dl dl {
    padding: 20px 0 0 16px;
    dd {
      .city-hot__item {
        margin-bottom: 20px;
        display: inline-block;
        vertical-align: top;
        width: 8%;
        height: 22px;
        overflow: hidden;
        cursor: pointer;
      }
    }
    .city__province {
      width: 45px;
    }
  }
}
</style>

