<template>
  <div class="container">
    <PageHeader />

    <div class="main-container">
      <div class="w1200 w-breadcrumb">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item> <router-link :to="{ name: 'Index' }">首页</router-link> </a-breadcrumb-item>
          <a-breadcrumb-item> <router-link :to="{ name: 'Search' }">搜索</router-link> </a-breadcrumb-item>
          <a-breadcrumb-item> 结果 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="search w1200">
        <div class="search__item" v-for="item in series" v-bind:key="item.id">
          <router-link :to="{ name: 'Series', params: { id: item.id } }">
            <div class="car_images">
              <img :src="item.cover_url" width="310px" />
            </div>
          </router-link>
          <div class="p10 car_info">
            <h2>
              <router-link
                :to="{ name: 'Series', params: { id: item.id } }"
                style="color: #333"
                >{{ item.name }}</router-link
              >
            </h2>
            <!-- <p>
              <span>特卖时间：<em>3月30号</em></span>
            </p> -->
            <p>
              <router-link
                target="_blank"
                :to="{
                  name: 'AskPrice',
                  query: {
                    brand: item.brand.letter + ',' + item.brand.id,
                    series: item.name,
                  },
                }"
                class="tg-btn"
              >
                我要询价
              </router-link>
            </p>
          </div>
        </div>

        <div
          class=""
          style="margin: 80px auto 200px; text-align: center"
          v-show="total === 0"
        >
          <img
            src="@/assets/icons/noresult.png"
            style="width: auto; height: auto"
          /><br />
          <p style="font-size: 18px; margin-bottom: 5px">
            没有找到你想要的车型
          </p>
          <p>
            如需了解更多车型，您可以通过网站的品牌分类查找更多或
            与我们的专业顾问取得联系。
          </p>
        </div>
      </div>
      <div class="w1200" v-show="total >= limit">
        <div class="page-container">
          <a-pagination
            :default-current="2"
            :defaultPageSize="limit"
            :total="500"
            @change="onChange"
          />
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import { getSearchCars } from "@/api/car";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      keyword: "",
      series: [],
      total: 0,
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    if (this.$route.query.keyword !== undefined && this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getSearchCars({
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
      }).then((response) => {
        this.series = response.data.list;
        this.total = response.data.count;
      });
    },
    onChange(current) {
      this.page = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 20px;
  clear: both;
  zoom: 1;
  overflow: hidden;

  .search__item {
    &:hover {
      border: 1px solid #dd2a2a;
    }
    float: left;
    width: 278px;
    border: 1px solid #fff;
    margin: 0 10px 10px 10px;
    background-color: #fff;
    box-shadow: 0 2px 0 #e9e9e9, 0 2px 0 #e9e9e9;
    padding-bottom: 10px;
    .car_images {
      height: 180px;
      width: 270px;
      img {
        width: 260px;
        height: 173px;
      }
    }

    .car_info {
      text-align: center;
      padding: 10px;
      h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        color: #333;
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 10px;
        margin-bottom: 0px;
      }
      p {
        // height: 20px;
        // line-height: 20px;
        font-size: 14px;
        color: #999;
        margin-top: 10px;
        margin-bottom: 0px;
        .tg-btn {
          width: 100px;
          height: 36px;
          line-height: 36px;
          padding: 5px 20px;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          color: #fff;
          background: #dd2a2a;
        }
      }
    }
  }
}

.page-container {
  clear: both;
  width: 420px;
  margin: 20px auto 10px;
}
.w-breadcrumb {
  margin: 10px auto;
  font-size: 14px;
  padding: 0 10px;
}
</style>

