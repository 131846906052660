<template>
  <div class="container">
    <PageHeader current="carimg" />
    <div class="main-container">
      <div class="w1200">
        <a-breadcrumb separator=">" class="w-breadcrumb">
          <a-breadcrumb-item href="/" style="color: #fff">
            <router-link :to="{ name: 'Index' }">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link :to="{ name: 'CarImgs' }">精美车图</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item> 车系图片 </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="view-left fl">
          <a-carousel arrows dots-class="slick-dots slick-thumb">
            <a slot="customPaging" slot-scope="props">
              <img :src="getImgUrl(props.i)" />
            </a>
            <div v-for="(item, i) in images" v-bind:key="i">
              <img :src="item.img_url" />
            </div>
          </a-carousel>
        </div>
        <div class="view-right fr">
          <ul class="slide-nav">
            <li v-for="(item, i) in origin_imgs" :key="i">
              <a
                href="javascript:void(0);"
                :class="item.classify === classify ? 'on' : ''"
                @click="changeClassify(item)"
                ><label class="box-left">{{ item.classify_name }}</label></a
              >
            </li>
          </ul>
          <div class="recommand">
            <h4>全国热门购车惠</h4>
            <ul>
              <li>
                <img
                  src="//img.haoche.cn/0/580/space/2021-04-04/20210404154952532.jpg"
                  style="display: inline"
                />
              </li>
              <li>
                <img
                  src="//img.haoche.cn/0/1142/space/2021-03-31/20210331160049529.jpg"
                  style="display: inline"
                />
              </li>
              <li>
                <img
                  src="//img.haoche.cn/0/1164/space/2021-04-02/20210402163956607.jpg"
                  style="display: inline"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 底部介绍 -->
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import { getSeriesImagesById } from "@/api/car";

export default {
  name: "Index",
  components: {
    PageHeader,
    Footer,
  },
  data() {
    return {
      id: 0,
      images: [],
      classify: 1,
      img_id: 0,
      origin_imgs: [],
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getSeriesImagesById(this.id).then((resp) => {
        this.origin_imgs = resp.data;
        this.origin_imgs.map((item) => {
          if (item.classify == this.classify) {
            this.images = item.images;
          }
        });
      });
    },
    getImgUrl(i) {
      return this.images[i].img_url;
    },

    changeClassify(item) {
      this.classify = item.classify;
      this.images = item.images;
    },
  },
};
</script>

<style scoped>
.view-left {
  width: 980px;
  min-height: 1000px;
  overflow: hidden;
}
.view-right {
  margin-left: 20px;
  width: 200px;
  overflow: hidden;
}

.slide-nav li {
  margin-bottom: 10px;
}

.slide-nav li a {
  display: block;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  background: #2b2c33;
  padding: 0 15px;
  text-align: center;
}

.w-breadcrumb {
  margin: 0 0 15px;
  color: #fff;
}

.w-breadcrumb span {
  color: #fff;
}

.w-breadcrumb span a {
  color: #fff;
}

.slide-nav li a label {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.slide-nav li a.on {
  background: #f54a45;
}

.recommand h4 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.recommand li {
  width: 100%;
  height: 120px;
}

.recommand li img {
  width: 100%;
  height: 100px;
}

.main-container {
  overflow: hidden;
  background: #222329;
  padding-top: 30px;
}
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  /* max-width: 80%; */
  width: 100%;
  height: 800px;
}
.ant-carousel >>> .slick-thumb {
  bottom: -140px;
}
.ant-carousel >>> .slick-thumb li {
  width: 220px;
  height: 130px;
  margin: 0px 10px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>

