<template>
  <div class="container">
    <PageHeader current="cars" />
    <div class="w1200" style="padding: 20px 0 20px">
      <a-breadcrumb separator=">" class="w-breadcrumb">
        <a-breadcrumb-item>
          <router-link :to="{ name: 'Index' }">首页</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{ name: 'Series', params: { id: series.id } }">{{
            series.name
          }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> 参数配置 </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="main-container w1200">
      <div class="compare-area">
        <table class="tbcs tbset" style="width: 911px">
          <tbody>
            <tr>
              <th>
                <div id="config_setbox" class="setbox" style="text-align: left">
                  <p>
                    注：<i class="icons-standard"></i>标配
                    <i class="icons-select"></i>选配 -无
                  </p>
                </div>
              </th>
              <td v-for="(item, i) in header.items" :key="i">
                <div class="btn_delbar">
                  <a
                    target="_self"
                    href="javascript:void(0);"
                    class="btn_del"
                  ></a>
                </div>
                <div class="carbox">
                  <div>
                    {{ item.value }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="tbcs"
          id="tab_1"
          style="width: 911px"
          v-for="item in list"
          :key="item.name"
        >
          <tbody>
            <tr>
              <th class="cstitle" colspan="100">
                <h3>
                  <span>{{ item.name }}</span>
                </h3>
              </th>
            </tr>
            <tr v-for="conf in item.items" :key="conf.cf_id">
              <th>
                <div class="cstitle2">{{ conf.cf_name }}</div>
              </th>
              <td v-for="model in conf.items" :key="model.mid">
                <div v-if="model.sublist.length == 0">{{ model.value }}</div>
                <div
                  class="fontf optional"
                  v-if="model.sublist.length > 0 && item.name != '选装包'"
                >
                  <p v-for="(sub, i) in model.sublist" :key="i">
                    <span>
                      <i class="-standard" v-if="sub.subvalue == '1'"></i>
                      <i class="icons-select" v-if="sub.subvalue == '2'"></i>
                      {{ sub.subname }}
                    </span>
                  </p>
                </div>
                <div
                  class="fontf optional"
                  v-if="model.sublist.length > 0 && item.name == '选装包'"
                >
                  <ul class="color-ul">
                    <li v-for="(sub, i) in model.sublist" :key="i">
                      <span
                        :title="sub.name"
                        v-if="sub.value.indexOf('/') == -1"
                      >
                        <em :style="{ 'background-color': sub.value }"></em>
                        <em class="em-bg"></em>
                      </span>
                      <span title="黑色/阿拉斯红" v-else>
                        <em
                          class="em-top"
                          :style="{
                            'background-color': sub.value.split('/')[0],
                          }"
                        ></em
                        ><em
                          class="em-bottom"
                          :style="{
                            'background-color': sub.value.split('/')[1],
                          }"
                        ></em
                        ><em class="em-bg"></em
                      ></span>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";
import { getSeriesConfigById } from "@/api/car";

export default {
  name: "Index",
  components: {
    PageHeader,
    Footer,
  },
  data() {
    return {
      id: 0,
      list: [],
      header: {
        items: [],
      },
      series: {},
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getSeriesConfigById(this.id).then((resp) => {
        this.list = resp.data.configs;
        this.header = resp.data.headers;
        this.series = resp.data.series;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  //   overflow: hidden;
  overflow-x: scroll;
}

.tbcs:not(:last-child) {
  border-bottom: 0 none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}
.tbcs {
  position: relative;
  width: 100%;
  border-left: solid 1px #d9e5f3;
  border-bottom: solid 1px #d9e5f3;
  background-color: #fff;

  .color-ul {
    display: block;
    height: auto;
    min-height: 82px;
    overflow: hidden;
    padding-left: 8px;
    li {
      position: relative;
      float: left;
      width: 18px;
      height: 18px;
      line-height: 0;
      margin: 5px 3px;

      em {
        width: 16px;
        height: 16px;
        line-height: 0;
        overflow: hidden;
        zoom: 1;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 5;
      }

      em.em-top {
        width: 16px;
        height: 8px;
        top: 1px;
        left: 1px;
        z-index: 10;
      }

      em.em-bottom {
        width: 16px;
        height: 8px;
        top: 9px;
        left: 1px;
        z-index: 10;
      }

      //   a {
      //     display: block;
      //     width: 16px;
      //     height: 16px;
      //     border: 1px solid #d60000;
      //   }
    }
  }

  th {
    width: 200px;
    height: 30px;
    text-align: right;
    font-weight: 400;
    vertical-align: middle;
    border-top: solid 1px #d9e5f3;
    border-right: solid 1px #d9e5f3;
    h3 {
      height: 35px;
      color: #993000;
      line-height: 35px;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      margin: 0px;
      padding-left: 15px;
    }
  }
  td {
    height: 30px;
    vertical-align: middle;
    border-top: solid 1px #d9e5f3;
    border-right: solid 1px #d9e5f3;
    & > div {
      position: relative;
      width: 156px;
      padding: 10px;
      vertical-align: middle;
    }
  }
  th > div {
    width: 180px;
    padding: 10px;
    overflow: hidden;
    text-align: right;
  }
  th.cstitle {
    background-color: #f0f3f8;
  }
  td.cstitle2 {
    color: #3b5998;
  }
}
.icons-standard {
  background-color: #000;
  display: inline-block;
  box-sizing: border-box;
  width: 9px;
  height: 9px;
  margin: 0 2px;
  border-radius: 50%;
}
.icons-select {
  display: inline-block;
  box-sizing: border-box;
  width: 9px;
  height: 9px;
  margin: 0 2px;
  border-radius: 50%;
  border: solid 1px #000;
}
.tbset {
  .btn_delbar {
    height: 16px;
    line-height: 16px;
    padding: 4px;
    text-align: center;
    position: relative;
  }
}

.compare-area {
  font-size: 12px;
  .carbox {
    text-align: center;
    line-height: 15px;
    & > div {
      height: 50px;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
    }
    & > p {
      position: relative;
      zoom: 1;
      height: 15px;
      margin: 0px;
      padding: 0px;
    }
  }
}
</style>

