<template>
  <div class="container">
    <div class="main-container">
      <div class="dealerCon">
        <div
          class="dealerTop flexBC"
          style="align-items: flex-end; margin-bottom: 20px; margin-top: 15px"
        >
          <div class="flexL">
            <a href="/">
              <img src="@/assets/logo.png" style="height: 53px" />
            </a>
            <p style="font-size: 20px; margin-top: 20px; margin-left: 10px">
              欢迎入驻晟行汽车
            </p>
          </div>
          <div class="" style="line-height: 54px">
            <router-link
              :to="{ name: 'DealerLogin' }"
              class="delearLogin"
              target="_blank"
            >
              去登录
            </router-link>
          </div>
        </div>
        <div class="error_tip" id="error" style="display: none">
          请输入手机号
        </div>
        <div class="dealerReg">
          <h3 class="flexR">立即注册</h3>

          <div
            class="reg1 flexR"
            style="padding: 50px 0; flex-direction: column"
          >
            <div class="reg-form">
              <a-form :form="form" @submit="handleSubmit" style="width: 500px">
                <a-form-item v-bind="formItemLayout" label="所属城市">
                  <a-select
                    style="width: 155px; margin-right: 20px"
                    @change="handleProvinceChange"
                    size="large"
                    placeholder="请选择省份"
                    :allowClear="true"
                    v-decorator="[
                      'province_id',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择省份!',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="province in cities"
                      :key="province.value"
                      :value="province.value"
                    >
                      {{ province.label }}
                    </a-select-option>
                  </a-select>
                  <a-select
                    style="width: 155px"
                    size="large"
                    placeholder="请选择城市"
                    :allowClear="true"
                    v-decorator="[
                      'city_id',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择城市!',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="city in subCities"
                      :key="city.value"
                      :value="city.value"
                    >
                      {{ city.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item
                  v-bind="formItemLayout"
                  extra="城市店名品牌，例如：北京中进锦旺广汽三菱"
                >
                  <span slot="label"> 用户名 </span>
                  <a-input
                    placeholder="请输入公司简称"
                    size="large"
                    v-decorator="[
                      'nickname',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入您的用户名！',
                            whitespace: true,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item v-bind="formItemLayout" label="设置密码">
                  <span slot="extra">
                    密码必须是字母、数字、特殊字符组合并且在8-18位之间
                  </span>
                  <a-input
                    size="large"
                    placeholder="请输入密码"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入密码!',
                          },
                          {
                            validator: validateToNextPassword,
                          },
                          {
                            validator: validatePassword,
                          },
                        ],
                      },
                    ]"
                    type="password"
                  />
                </a-form-item>

                <a-form-item v-bind="formItemLayout" label="确认密码">
                  <a-input
                    size="large"
                    placeholder="请再次输入密码"
                    v-decorator="[
                      'confirm',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请再次输入密码!',
                          },
                          {
                            validator: compareToFirstPassword,
                          },
                        ],
                      },
                    ]"
                    type="password"
                    @blur="handleConfirmBlur"
                  />
                </a-form-item>

                <a-form-item v-bind="formItemLayout" label="手机号">
                  <a-input
                    placeholder="请输入手机号"
                    addon-before="86"
                    size="large"
                    v-decorator="[
                      'phone',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入手机号!',
                          },
                          {
                            validator: validatePhone,
                          },
                        ],
                      },
                    ]"
                    style="width: 100%"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-bind="formItemLayout" label="手机验证码">
                  <a-row :gutter="8">
                    <a-col :span="12">
                      <a-input
                        placeholder="请输入手机验证码"
                        size="large"
                        v-decorator="[
                          'captcha',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入手机验证码!',
                              },
                              {
                                validator: validateCaptcha,
                              },
                            ],
                          },
                        ]"
                      />
                    </a-col>
                    <a-col :span="12">
                      <a-button
                        size="large"
                        @click="handleSendCaptcha"
                        :disabled="captcha.disabled"
                        >{{ captcha.text }}</a-button
                      >
                    </a-col>
                  </a-row>
                </a-form-item>
                <a-form-item v-bind="tailFormItemLayout">
                  <a-checkbox
                    v-decorator="[
                      'agreement',
                      {
                        valuePropName: 'checked',
                        rules: [
                          {
                            required: true,
                            message: '必须认真仔细看过并同意服务协议',
                          },
                        ],
                      },
                    ]"
                  >
                    我已看过并同意
                    <a @click="modalVisible = true" href="javascript:voide(0);">
                      《服务协议》
                    </a>
                  </a-checkbox>
                </a-form-item>
                <a-form-item v-bind="tailFormItemLayout">
                  <a-button
                    type="primary"
                    size="large"
                    html-type="submit"
                    class="sub-btn"
                    :loading="loading"
                  >
                    注册
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>

      <a-modal
        v-model="modalVisible"
        title="晟行汽车服务协议"
        width="80%"
        centered
        @ok="() => (modalVisible = false)"
        :footer="null"
      >
        晟行科技及其关联公司（如无特殊约定，以下统称“晟行”）收集用户的个人信息的最终目的是为了向您提供更好的产品、服务，优化并丰富您的用户体验。晟行将按照本声明的规定合理使用并保护用户的个人信息，据此制订《个人信息保护声明》如下：<br />
        <p>一、个人信息的收集</p>
        <p>
          1、晟行向用户收集的个人信息指的是能够单独或与其他信息结合识别您个人身份的信息，这些信息包括：①车型②联系方式③您在页面上留下的其他信息（车牌号、里程数、卖车意向等）④在您上载到网站的内容中包含的任何个人信息。
        </p>
        <p>
          2、以上个人信息均是用户自愿提供。您有权拒绝提供，但如果您拒绝提供某些个人信息，您将可能无法使用晟行提供的服务、产品，或者可能对您使用服务或产品造成一定的影响。
        </p>
        <p>
          3、对于不满18岁的用户，须在其法定监护人已经阅读本声明并且许可的情况下，通过网站提交个人信息。
        </p>
        <p>二、个人信息的使用</p>
        <p>
          1、我们会根据本声明为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
        </p>
        <p>
          2、您同意，晟行可以通过以下方式对您个人信息进行使用和分享（包含对于个人信息的存储和处理）：
        </p>
        <p>（1）我们和关联公司使用；</p>
        <p>（2）我们向相关第三方服务商分享并由其使用；</p>
        <p>
          （3）我们及关联公司及相关第三方服务商为满足您的需求，可能通过您提供的信息与您联系；
        </p>
        <p>
          （4）我们及关联公司及相关第三方服务商可能定期或不定期向您发送有关产品、服务或相关活动的信息，您同意接收上述信息，您同意免除上述个人信息的接收和/或使用方在按照本法律声明所述情况下进行信息披露和使用而导致的或可能导致的所有索赔、责任和损失。
        </p>
        <p>
          3、用户明确同意使用本平台服务的使用所存在的风险将完全由您自己承担，因用户使用晟行服务而产生的一切后果也由您自己承担，晟行对您不承担任何责任。
        </p>
        <p>三、联系我们</p>
        <p>
          如您对本声明或您个人信息的相关事宜有任何问题、意见或建议，您可随时拨打我们的客服电话：15117971875，或与我们的在线客服取得联系。
        </p>
      </a-modal>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import CityData from "@/data/city";

export default {
  name: "Index",
  components: {
    ServiceIntro,
    Footer,
  },
  data() {
    return {
      confirmDirty: false,
      cities: CityData,
      subCities: [],
      formData: {
        province_id: null,
        city_id: null,
      },
      loading: false,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      captcha: {
        text: "发送验证码",
        is_send: false,
        second: 59,
        disabled: false,
      },
      modalVisible: false,
    };
  },
  computed: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {},
    handleSubmit(e) {
      e.preventDefault();
      if (this.loading) {
        return;
      }
      let that = this;
      this.form.validateFieldsAndScroll((err) => {
        if (!err) {
          let seconds = parseInt(Math.random() * 1000);
          that.loading = true;
          setTimeout(function () {
            that.$message.warning("手机验证码错误");
            that.loading = false;
          }, seconds);
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("两次密码不一致!");
      } else {
        callback();
      }
    },
    handleSendCaptcha() {
      const form = this.form;
      let phone = form.getFieldValue("phone");
      if (
        phone == "" ||
        phone == undefined ||
        !/^1[3456789]\d{9}$/.test(phone)
      ) {
        return this.$message.warning("请输入正确的手机号");
      }

      if (this.captcha.is_send) {
        return false;
      }
      this.captcha.is_send = true;
      this.captcha.disabled = true;
      this.captcha.text = "重新发送（" + this.captcha.second + "）";
      var timer = setInterval(() => {
        this.captcha.second--;
        this.captcha.text = "重新发送（" + this.captcha.second + "）";
        if (this.captcha.second <= 0) {
          clearInterval(timer);
          this.captcha.text = "重新发送";
          this.captcha.is_send = false;
          this.captcha.disabled = false;
          this.captcha.second = 59;
        }
      }, 1000);
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    validatePassword(rule, value, callback) {
      // const form = this.form;
      console.log(value);
      if (value && !/^[a-zA-Z-_0-9]{8,18}$/.test(value)) {
        callback("密码格式错误");
      } else {
        callback();
      }
    },
    validatePhone(rule, value, callback) {
      console.log(value);
      if (value && !/^1[3456789]\d{9}$/.test(value)) {
        callback("手机号格式错误");
      } else {
        callback();
      }
    },
    validateCaptcha(rule, value, callback) {
      console.log(value);
      if (value && !/^\d{6}$/.test(value)) {
        callback("验证码格式错误");
      } else {
        callback();
      }
    },
    handleProvinceChange(val) {
      const form = this.form;
      if (val == undefined) {
        this.subCities = [];
        form.setFieldsValue({ city_id: undefined });
        return false;
      }
      CityData.map((item) => {
        if (item.value == val) {
          this.subCities = item.children;
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #f0f3f5;
}
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dealerCon {
  width: 900px;
  margin: 0 auto 20px;
}
.delearLogin {
  color: #dd2a2a;
  font-size: 18px;
  text-decoration: underline;
}
.dealerReg {
  background-color: #fff;
}
.dealerReg > h3 {
  height: 60px;
  font-size: 22px;
  color: #fff;
  background-color: #dd2a2a;
  font-weight: normal;
}
.sub-btn {
  width: 200px;
  background: #dd2a2a;
  border: 1px solid #dd2a2a;
}
</style>

