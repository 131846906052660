<template>
  <div class="" style="margin: 50px auto 50px; text-align: center">
    <img
      src="@/assets/icons/noresult.png"
      style="width: auto; height: auto"
    /><br />
    <span style="font-size: 16px">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "NoData",
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      default: "该条件暂无车型数据，换个条件试试吧 ~",
      required: false,
    },
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
