<template>
  <div class="container">
    <PageHeader current="news" />
    <div class="main-container">
      <div class="w1200">
        <a-breadcrumb separator=">" class="w-breadcrumb">
          <a-breadcrumb-item > <router-link :to="{ name: 'Index' }">首页</router-link> </a-breadcrumb-item>
          <a-breadcrumb-item> <router-link :to="{ name: 'News' }"> 资讯 </router-link> </a-breadcrumb-item>
          <a-breadcrumb-item> 文章详情 </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="w1200 article">
        <div class="box-left">
          <a-skeleton
            :loading="loading.detail"
            active
            :paragraph="{ rows: 20 }"
            :title="false"
          >
            <div class="art-tit">
              <h3>{{ news.title }}</h3>
              <p>
                发布时间：<span class="art-time">{{ news.published_at }}</span>
                来源：<span class="publish">{{news.resource}}</span>
              </p>
            </div>

            <!--  内容 -->
            <div class="art-cont" v-html="news.content"></div>

            <div style="margin:10px 0px; text-align:center;">
              <!-- （声明：文章来源于网络，版权属原作者所有，如有侵权请联系删除） -->
            </div>

            <!-- <div class="art-nav">
              <h4><span class="nav-tag"></span> <span>国内新车</span></h4>
            </div> -->
          </a-skeleton>
        </div>
        <!-- 右侧 -->
        <div class="box-right">
          <div class="benefit-info">
            <h4 class="m-title">特惠品牌</h4>
            <ul id="detailAct">
              <li>
                <router-link :to="{ name: 'Cars', query: { bid: 28 } }"
                  ><img
                    src="//img.haoche.cn/0/407/space/2021-03-26/20210326141531352.jpg"
                  />
                </router-link>
              </li>
            </ul>
          </div>
          <div class="hotChoice hotChoiceNum">
            <h5>热门精选</h5>
            <!--热门精选（调取本周内发布的文章，访问量最高的十条。）-->

            <div class="choiceCnt">
              <a-skeleton
                :loading="loading.hot"
                active
                :paragraph="{ rows: 12 }"
                :title="false"
              >
                <p v-for="(item, i) in hotNews" v-bind:key="item.id">
                  <router-link
                    :to="{ name: 'NewsDetail', params: { id: item.id } }"
                    ><span :class="i < 3 ? 'on' : ''">{{ i + 1 }}. </span
                    >{{ item.title }}</router-link
                  >
                </p>
              </a-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getNewDetail } from "@/api/news";
import { getCommonRankNews } from "@/api/common";
import PageHeader from "@/components/PageHeader";
import Footer from "@/components/Footer";

export default {
  name: "Index",
  components: {
    PageHeader,
    Footer,
  },
  route: {
    canReuse: false,
  },
  data() {
    return {
      id: 0,
      news: {},
      loading: {
        detail: true,
        hot: true,
      },
      hotNews: [],
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.id = this.$route.params.id;
      this.getData();
    },
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getNewDetail(this.id).then((response) => {
        this.news = response.data;
        this.loading.detail = false;
      });

      getCommonRankNews().then((resp) => {
        this.hotNews = resp.data;
        this.loading.hot = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w-breadcrumb {
  margin: 15px 0;
}
.main-container {
  overflow: hidden;
}
.article {
  .box-left {
    width: 830px;
    float: left;
    .art-tit {
      text-align: center;
      padding-top: 20px;
      h3 {
        font-size: 28px;
        color: #3b3b3d;
        font-weight: bold;
        line-height: 40px;
        position: relative;
        display: inline-block;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        color: #76767a;
      }

      span {
        margin-right: 10px;
      }
      span.original {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        color: #fff;
        background: #f54a45;
        width: 40px;
        height: 20px;
        line-height: 20px;
      }
    }

    .art-cont {
      width: 750px;
      margin: 20px auto;
    }

    .art-nav {
      h4 {
        font-size: 14px;
        color: #f54a45;
        line-height: 30px;
        border-bottom: 1px solid #e1e2eb;
        .nav-tag {
          display: inline-block;
          width: 30px;
          height: 16px;
          background: url(/images/newPchcw/icon.png) 0 -371px;
          margin-right: 5px;
        }
        span {
          color: #f54a45;
          margin-right: 10px;
        }
      }
    }
  }
  .box-right {
    width: 330px;
    float: right;
    margin-top: 20px;
    .benefit-info {
      margin-bottom: 40px;
      width: 100%;
      .m-title {
        font-size: 18px;
        font-weight: bold;
      }

      ul {
        li {
          margin: 20px 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .hotChoice {
      h5 {
        font-size: 18px;
        color: #3b3b3d;
        padding-bottom: 10px;
        font-weight: bold;
        margin-top: 20px;
      }

      .choiceCnt {
        overflow: hidden;

        p {
          font-size: 14px;
          color: #76767a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 10px;
          & > a {
            color: #76767a;
          }
          span {
            margin-right: 5px;
          }
          span.on {
            color: #f54a45;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>

