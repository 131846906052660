import request from '@/utils/request'

export function getAskpricePeoples(id, params) {
    return request({
        url: `/groupon/askprice/peoples`,
        method: 'get',
        params
    })
}

export function getGroupons(params) {
    return request({
        url: `/groupons`,
        method: 'get',
        params
    })
}

export function getGrouponById(id, params) {
    return request({
        url: `/groupon/${id}`,
        method: 'get',
        params
    })
}

export function getGrouponIndexRecommends(params) {
    return request({
        url: `/groupon/index/recommends`,
        method: 'get',
        params
    })
}