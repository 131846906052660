<template>
  <div class="container">
    <PageHeader current="aboutus" />
    <div class="main-container">
      <div class="w1200 f6 clearFix">
        <div class="fl about_left">
          <IntroNav current="saleflow" />
        </div>
        <div class="fr about_right">
          <div class="c-box">
            <div class="question">
              <ul>
                <li class="clearFix">
                  <div class="fl num_q">1</div>
                  <div class="fl con">
                    <h4>1、免费报名</h4>
                    <p>
                      特卖人员网上报名（或电话报名），按要求填写特卖信息。同一车系只能报名一款车型，最多报名三个车系。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">2</div>
                  <div class="fl con">
                    <h4>2、确定信息</h4>
                    <p>
                      在您完成报名后，我们的网站系统会自动发送确认成功报名短信。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">3</div>
                  <div class="fl con">
                    <h4>3、客服预约</h4>
                    <p>
                      特卖前一天，短信或者电话通知所有参团人员开团时间、4S店地址、联系人等特卖信息。（备注：电话沟通或者QQ咨询中不报特卖价格
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">4</div>
                  <div class="fl con">
                    <h4>4、参加活动</h4>
                    <p>
                      到短信通知的4S店参加活动：
                      ①签到：按照先签到先提车、订车原则，请团员准时到达4S店签到，签到时团长需再次核对车型、配置、颜色、车主等细节问题
                      ②公布特卖价格：4S店根据到场人数及特卖车型、配置、颜色及店内现车资源等综合因素宣布特卖价格。特卖人数越多，特卖价格越低
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">5</div>
                  <div class="fl con">
                    <h4>5、订购爱车</h4>
                    <p>
                      满意-签单/不满意-退团：凡是接受特卖价格的会员，如有现车可以当时提车，也可以缴纳定金先签订订单，等有时间再来提车，还可以分期付款买车。没有现车的会员需签订车协议，4S店会根据车型、配置、颜色等告之大概到车时间；凡是不满意特卖价格的团员，可以退出本批特卖参加粒子汽车网下批活动。
                    </p>
                  </div>
                </li>
                <li class="clearFix">
                  <div class="fl num_q">6</div>
                  <div class="fl con">
                    <h4>6、客服回访</h4>
                    <p>了解团友满意度，以便更好的为团友服务.</p>
                  </div>
                </li>
                <li class="clearFix"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import IntroNav from "@/components/IntroNav";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
    IntroNav,
  },
  data() {
    return {};
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {},
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  .about_left {
    width: 320px;
    padding: 40px;
  }
  .about_right {
    min-height: 400px;
    width: 880px;
    background: #fff;
    padding: 40px;
    .question {
      ul {
        li {
          margin-bottom: 15px;
        }
      }
      .num_q {
        background-image: url("../../assets/icons/qa-icon.png");
        background-position: center center;
        background-repeat: no-repeat;
        width: 39px;
        height: 37px;
        line-height: 37px;
        color: #fff;
        font-size: 24px;
        padding-left: 13px;
      }
      .con {
        width: 760px;
        padding-left: 10px;
      }
    }
  }
}
</style>

