<template>
  <div class="container">
    <PageHeader current="aboutus" />
    <div class="main-container">
      <div class="w1200 f6 clearFix">
        <div class="fl about_left">
          <IntroNav current="contactus" />
        </div>
        <div class="fr about_right">
          <div class="c-box">
            <img
              src="@/assets/logo.png"
              alt=""
              width="200"
              style="margin-bottom: 20px"
            />
            <p>联系邮箱</p>
            <p>kf@shengxingdt.com</p>
            <p>商务合作</p>
            <p>电话:15117971875</p>
            <p>邮箱：bd@shengxingdt.com</p>
          </div>
        </div>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import IntroNav from "@/components/IntroNav";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    Footer,
    IntroNav,
  },
  data() {
    return {};
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {},
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  .about_left {
    width: 320px;
    padding: 40px;
  }
  .about_right {
    min-height: 400px;
    width: 880px;
    background: #fff;
    padding: 40px;
  }
}
</style>

