import request from '@/utils/request'

export function getCarsouelImages(params) {
    return request({
        url: '/carimg/carsouel',
        method: 'get',
        params
    })
}

export function getLevelImages(params) {
    return request({
        url: '/carimg/levels',
        method: 'get',
        params
    })
}