<template>
  <div class="container">
    <DealerHeader current="news" />
    <div class="main-container">
      <!-- 面包屑 -->
      <div class="w1200">
        <div style="padding: 20px 0 5px">
          当前位置：
          <router-link :to="{ name: 'DealerIndex' }"> 店铺首页 </router-link>
          &gt;
          <span>店内动态</span>
        </div>
      </div>

      <div class="w1200 clearFix">
        <div class="fl dynamic_box">
          <div class="newslist clearFix">
            <a
              href="javascript:void(0);"
              :class="it.id == classify ? 'on' : ''"
              v-for="it in classifies"
              :key="it.id"
              @click="handleChangeClassify(it.id)"
              >{{ it.txt }}</a
            >
          </div>

          <div class="daynamic">
            <a-skeleton :loading="loading" active :paragraph="{ rows: 20 }">
              <ul v-if="!loading && news.length > 0">
                <li class="clearFix" v-for="item in news" :key="item.id">
                  <router-link
                    :to="{
                      name: 'DealerNewsDetail',
                      params: {
                        id: id,
                        nid: item.id,
                      },
                    }"
                    class="news_listimg fl"
                  >
                    <img :src="item.img_url" width="260" height="155" />
                  </router-link>
                  <div class="daynamic_info fl clearFix">
                    <div class="fl daynamic_data_l">
                      <h2>
                        <router-link
                          :to="{
                            name: 'DealerNewsDetail',
                            params: {
                              id: id,
                              nid: item.id,
                            },
                          }"
                          >{{ item.title }}</router-link
                        >
                      </h2>
                      <router-link
                        :to="{
                          name: 'DealerNewsDetail',
                          params: {
                            id: id,
                            nid: item.id,
                          },
                        }"
                        ><p class="da_detailinfor">
                          {{ item.summary }}
                        </p>
                      </router-link>

                      <p class="clearFix">
                        <span class="news_time">{{ item.published_at }}</span>
                      </p>
                    </div>
                    <div class="fr">
                      <router-link
                        :to="{
                          name: 'DealerNewsDetail',
                          params: {
                            id: id,
                            nid: item.id,
                          },
                        }"
                        class="d_news_detail"
                      >
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="jxs_no_data" v-if="!loading && news.length == 0">
                <img src="@/assets/icons/notice_img.png" />
                <p>Sorry, 暂时还没有任何数据，请看看其他资讯吧！</p>
                <p></p>
              </div>
            </a-skeleton>
          </div>
          <div
            class="clearFix pagenav pb30"
            style="margin: 15px auto; text-align: center"
          >
            <div class="tc_page_con">
              <form id="frm" method="post">
                <input name="news_type" type="hidden" value="" />
              </form>
            </div>
          </div>
        </div>
        <div class="fr main_brand_box" style="margin-top: 32px">
          <DealerMainBrand />
        </div>
      </div>
      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import DealerHeader from "@/components/dealer/DealerHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import Footer from "@/components/Footer";
import DealerMainBrand from "@/components/dealer/MainBrand";
import { getDealerNews } from "@/api/dealer";

export default {
  name: "Index",
  components: {
    DealerHeader,
    ServiceIntro,
    Footer,
    DealerMainBrand,
  },
  data() {
    return {
      classify: 1,
      id: 0,
      classifies: [
        {
          id: 1,
          txt: "行业新闻",
        },
        {
          id: 2,
          txt: "促销优惠",
        },
      ],
      news: [],
      series: [],
      loading: true,
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      this.loading = true;
      getDealerNews(this.id, {
        classify: this.classify,
      }).then((resp) => {
        this.news = resp.data.list;
        this.loading = false;
      });
    },
    handleChangeClassify(id) {
      this.classify = id;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.clearFix {
  zoom: 1;
}
.flexL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dynamic_box {
  width: 874px;
  box-sizing: border-box;
  .newslist {
    margin-top: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #999;
    height: 40px;
    a {
      font-size: 18px;
      color: #666;
      padding: 3px;
      border: solid 1px #999999;
      width: 180px;
      height: 40px;
      display: inline-block;
      text-align: center;
      line-height: 34px;
      float: left;
      margin-bottom: -1px;
    }
    a.on {
      color: #fff;
      border-bottom: none;
      background-color: #e02b31;
      border: solid 1px #e02b31;
    }
  }

  .daynamic {
    padding-bottom: 30px;
    ul {
      li {
        height: 180px;
        background-color: #ffffff;
        box-shadow: 1px 2px 6px 0px rgba(102, 99, 94, 0.35);
        box-sizing: border-box;
        margin-bottom: 20px;
        a.news_listimg {
          width: 320px;
          height: 180px;
        }
        a {
          img {
            width: 320px;
            height: 180px;
          }
        }
        .daynamic_info {
          .daynamic_data_l {
            width: 440px;
          }
          width: 554px;
          box-sizing: border-box;
          padding: 25px 25px 20px;
          height: 180px;
          h2 {
            margin-bottom: 15px;
            a {
              font-size: 18px;
              color: #333;
              font-weight: normal;
              line-height: 34px;
            }
          }

          .da_detailinfor {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            font-size: 14px;
            color: #666;
            line-height: 24px;
          }

          span.news_time {
            color: #999;
            font-size: 14px;
          }
        }
      }
    }

    a.d_news_detail {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: solid 3px #666666;
      border-radius: 30px;
      background-image: url(http://img.shengxingdt.com/web/dealer/gonews.png?x-oss-process=style/default.jpg);
      background-repeat: no-repeat;
      background-position: 9px -18px;
      margin-top: 40px;
    }
  }
}

.jxs_no_data {
  text-align: center;
  margin: 50px auto 100px;
  img {
    vertical-align: middle;
    width: 125px;
    height: 125px;
    display: inline-block;
  }
  p {
    line-height: 125px;
    font-size: 16px;
    color: #9f9f9f;
    display: inline-block;
    height: 125px;
    padding-left: 25px;
  }
}
</style>

