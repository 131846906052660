<template>
  <div class="scar-container w1200">
    <ul class="flexL scar__options">
      <li class="flexR" @click="goSelectCar('1')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') 15px 8px no-repeat',
          }"
        ></span>
        <p>微型车</p>
      </li>
      <li class="flexR" @click="goSelectCar('2')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -69px 8px no-repeat',
          }"
        ></span>
        <p>小型车</p>
      </li>
      <li class="flexR" @click="goSelectCar('3')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -241px 8px no-repeat',
          }"
        ></span>
        <p>紧凑型</p>
      </li>
      <li class="flexR" @click="goSelectCar('4')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -241px 8px no-repeat',
          }"
        ></span>
        <p>中型车</p>
      </li>
      <li class="flexR" @click="goSelectCar('5')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -327px 8px no-repeat',
          }"
        ></span>
        <p>中大型</p>
      </li>
      <li class="flexR" @click="goSelectCar('6')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -411px 8px no-repeat',
          }"
        ></span>
        <p>大型车</p>
      </li>
      <li class="flexR" @click="goSelectCar('6')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -837px 8px no-repeat',
          }"
        ></span>
        <p>超大型</p>
      </li>
      <li class="flexR resultSUV" @click="goSelectCar('7,8,9,10,11')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -500px 8px no-repeat',
          }"
        ></span>
        <p>SUV</p>
      </li>
      <li class="flexR resultSUV" @click="goSelectCar('12,13,14,15')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -587px 8px no-repeat',
          }"
        ></span>
        <p>MPV</p>
        <div
          class="resultNav2"
          style="left: auto; right: undefinedpx; min-with: 80px"
        ></div>
      </li>
      <li class="flexR" @click="goSelectCar('16')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -674px 8px no-repeat',
          }"
        ></span>
        <p>跑车</p>
      </li>
      <li class="flexR" @click="goSelectCar('100')">
        <span
          :style="{
            background:
              'url(' +
              require('@/assets/carIconImg.png') +
              ') -760px 8px no-repeat',
          }"
        ></span>
        <p>新能源</p>
      </li>
    </ul>
    <div class="scar__recommend">
      <ul class="scar__recommend__ul">
        <li class="flexBC" v-for="(item, i) in cars" v-bind:key="i">
          <div class="flexBC scar__recommend__level">
            <label>{{ item.level_name }}</label>
            <div><a-icon type="right-circle" /></div>
          </div>
          <div class="flexL scar__recommend__series">
            <span
              v-for="series in item.list"
              v-bind:key="series.id"
              class="scar-name__item"
            >
              <router-link :to="{ name: 'Series', params: { id: series.id } }"
                >{{ series.name }}
              </router-link>
            </span>
          </div>
          <router-link :to="{ name: 'Cars', query: { level: item.level_id } }">
            <div class="classArrows">更多<a-icon type="right" /></div>
          </router-link>
        </li>
      </ul>
      <ul class="flexBC scar_recommend__brands" style="flex-wrap: wrap">
        <li v-for="item in brands" v-bind:key="item.id">
          <router-link
            :to="{ name: 'Cars', query: { bid: item.id } }"
            class="flexR"
          >
            <img :src="item.logo_url" />
            <p>{{ item.name }}</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import reData from "@/data/recommends";
console.log(reData.brands);
export default {
  name: "SelectCar",
  data() {
    return {
      cars: reData.series,
      brands: reData.brands,
    };
  },
  props: {},
  mounted() {},
  computed: {},
  methods: {
    back() {},
    goSelectCar(level) {
      // this.$route.go();
      this.$router.push({ name: "Cars", query: { level } });
    },
  },
};
</script>

<style lang="scss" scoped>
.scar-container {
  flex: 1;
  display: block;
  background-color: #fff;
  height: 400px;
  margin-top: 10px;
  .scar__options {
    position: relative;
    height: 100px;
    border-bottom: 1px solid #e7e7e7;
    & > li {
      &:hover {
        span {
          filter: drop-shadow(#f7891b -75px 0);
          position: relative;
          left: 75px;
        }

        p {
          color: #f7891b;
        }
      }
      margin: 0 18px;
      flex-direction: column;
      overflow: hidden;
      cursor: pointer;
      & > span {
        display: inline-block;
        width: 75px;
        height: 50px;
      }
    }
  }

  .scar__recommend {
    .scar__recommend__ul {
      margin-bottom: 0;
      li {
        height: 60px;
        margin: 0 20px;
        border-bottom: 1px solid #e7e7e7;
        .scar__recommend__level {
          width: 80px;
          font-weight: 700;
          box-sizing: border-box;
        }
        .scar__recommend__series {
          flex: 1;
          & > .scar-name__item {
            flex: 1;
            text-align: center;
            max-width: 8%;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #333;
            & > a {
              color: #2c3e50;
            }
          }
        }
      }
    }
    .scar_recommend__brands {
      position: relative;
      justify-content: flex-start;
      .scar_rb__more {
        background-color: #f8f8f8;
      }
      & > li {
        position: relative;
        // padding-right: 88px;
        justify-content: flex-start;
        text-align: center;
        height: 114px;
        box-sizing: border-box;
        width: 85.6px;
        display: inline-block;
        a {
          flex-direction: column;
          height: 100%;
          color: #333;
          img {
            width: 62px;
            height: 62px;
            margin-top: 10px;
            display: inline;
            border: none;
            font-size: 0px;
          }
        }
      }
    }
  }
}

.classArrows {
  border: solid 1px #e7e7e7;
  padding: 0 10px;
  color: #666;
}
</style>
