import request from '@/utils/request'

export function getIndexNews(params) {
    return request({
        url: '/index/news',
        method: 'get',
        params
    })
}

export function getIndexCarImgs(params) {
    return request({
        url: '/index/car-imgs',
        method: 'get',
        params
    })
}

export function getSaleRanks(params) {
    return request({
        url: '/car/sale/ranks',
        method: 'get',
        params
    })
}

export function getTuan(params) {
    return request({
        url: '/index/tuan',
        method: 'get',
        params
    })
}