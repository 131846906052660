var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('DealerHeader',{attrs:{"current":"news"}}),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"w1200"},[_c('div',{staticStyle:{"padding":"20px 0 5px"}},[_vm._v(" 当前位置： "),_c('router-link',{attrs:{"to":{ name: 'DealerIndex' }}},[_vm._v(" 店铺首页 ")]),_vm._v(" > "),_c('span',[_vm._v("店内动态")])],1)]),_c('div',{staticClass:"w1200 clearFix"},[_c('div',{staticClass:"fl dynamic_box"},[_c('div',{staticClass:"newslist clearFix"},_vm._l((_vm.classifies),function(it){return _c('a',{key:it.id,class:it.id == _vm.classify ? 'on' : '',attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.handleChangeClassify(it.id)}}},[_vm._v(_vm._s(it.txt))])}),0),_c('div',{staticClass:"daynamic"},[_c('a-skeleton',{attrs:{"loading":_vm.loading,"active":"","paragraph":{ rows: 20 }}},[(!_vm.loading && _vm.news.length > 0)?_c('ul',_vm._l((_vm.news),function(item){return _c('li',{key:item.id,staticClass:"clearFix"},[_c('router-link',{staticClass:"news_listimg fl",attrs:{"to":{
                    name: 'DealerNewsDetail',
                    params: {
                      id: _vm.id,
                      nid: item.id,
                    },
                  }}},[_c('img',{attrs:{"src":item.img_url,"width":"260","height":"155"}})]),_c('div',{staticClass:"daynamic_info fl clearFix"},[_c('div',{staticClass:"fl daynamic_data_l"},[_c('h2',[_c('router-link',{attrs:{"to":{
                          name: 'DealerNewsDetail',
                          params: {
                            id: _vm.id,
                            nid: item.id,
                          },
                        }}},[_vm._v(_vm._s(item.title))])],1),_c('router-link',{attrs:{"to":{
                        name: 'DealerNewsDetail',
                        params: {
                          id: _vm.id,
                          nid: item.id,
                        },
                      }}},[_c('p',{staticClass:"da_detailinfor"},[_vm._v(" "+_vm._s(item.summary)+" ")])]),_c('p',{staticClass:"clearFix"},[_c('span',{staticClass:"news_time"},[_vm._v(_vm._s(item.published_at))])])],1),_c('div',{staticClass:"fr"},[_c('router-link',{staticClass:"d_news_detail",attrs:{"to":{
                        name: 'DealerNewsDetail',
                        params: {
                          id: _vm.id,
                          nid: item.id,
                        },
                      }}})],1)])],1)}),0):_vm._e(),(!_vm.loading && _vm.news.length == 0)?_c('div',{staticClass:"jxs_no_data"},[_c('img',{attrs:{"src":require("@/assets/icons/notice_img.png")}}),_c('p',[_vm._v("Sorry, 暂时还没有任何数据，请看看其他资讯吧！")]),_c('p')]):_vm._e()])],1),_vm._m(0)]),_c('div',{staticClass:"fr main_brand_box",staticStyle:{"margin-top":"32px"}},[_c('DealerMainBrand')],1)]),_c('service-intro')],1),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clearFix pagenav pb30",staticStyle:{"margin":"15px auto","text-align":"center"}},[_c('div',{staticClass:"tc_page_con"},[_c('form',{attrs:{"id":"frm","method":"post"}},[_c('input',{attrs:{"name":"news_type","type":"hidden","value":""}})])])])}]

export { render, staticRenderFns }