var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('PageHeader',{attrs:{"current":"groupon","headerCityChange":_vm.headerCityChange}}),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"w1200",staticStyle:{"width":"980px"}},[_c('a-breadcrumb',{staticClass:"w-breadcrumb",staticStyle:{"margin":"15px 0"},attrs:{"separator":">"}},[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Index' }}},[_vm._v("首页")])],1),_c('a-breadcrumb-item',[_vm._v(" 团购活动 ")])],1)],1),_c('div',{staticClass:"groupon-brandlist"},[(_vm.list.length > 0)?_c('div',{staticClass:"wrapper groupon-wrapper"},[_c('a-skeleton',{attrs:{"loading":_vm.loading,"active":"","paragraph":{ rows: 3 },"title":false}},[_c('ul',{staticClass:"clearfix"},[_vm._l((_vm.topBrands),function(brand){return _c('li',{key:brand.id},[_c('router-link',{attrs:{"to":{
                  name: 'GrouponDetail',
                  params: { id: brand.groupon_id },
                }}},[_c('img',{attrs:{"src":brand.logo_url,"width":"40","height":"40"}}),_c('p',[_vm._v(_vm._s(brand.name))])])],1)}),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.topBrands.length >= 14),expression:"topBrands.length >= 14"}],staticClass:"last"},[_c('a',{staticClass:"J_barndMore",attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.more = !_vm.more}}},[_c('p',[_vm._v("更多"),_c('i',{staticClass:"icon"})])])])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.more),expression:"more"}],staticClass:"more-brand-pop clearfix"},[_c('div',{staticClass:"sub-detail fl"},_vm._l((_vm.brands.slice(0, 9)),function(b){return _c('dl',{key:b.id,staticClass:"clearfix"},[_c('dt',[_vm._v(_vm._s(b.letter))]),_c('dd',{staticClass:"clearfix"},_vm._l((b.brands),function(bb){return _c('router-link',{key:bb.id,attrs:{"to":{
                      name: 'GrouponDetail',
                      params: { id: bb.groupon_id },
                    }}},[_vm._v(" "+_vm._s(bb.name)+" ")])}),1)])}),0),_c('div',{staticClass:"sub-detail last fl"},_vm._l((_vm.brands.slice(9, 18)),function(b){return _c('dl',{key:b.id,staticClass:"clearfix"},[_c('dt',[_vm._v(_vm._s(b.letter))]),_c('dd',{staticClass:"clearfix"},_vm._l((b.brands),function(bb){return _c('router-link',{key:bb.id,attrs:{"to":{
                      name: 'GrouponDetail',
                      params: { id: bb.groupon_id },
                    }}},[_vm._v(_vm._s(bb.name)+" ")])}),1)])}),0)])])],1):_vm._e()]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title mod-title clearfix"},[_c('h3',[_c('i',{staticClass:"border"}),_vm._v("品牌专场团购会 "),_c('p',[_c('b',[_vm._v("HOT!")]),_vm._v("共有"),_c('span',[_vm._v(_vm._s(_vm.list.length))]),_vm._v("场团购会，本期已报名"),_c('span',[_vm._v(_vm._s(_vm.totalPeople))]),_vm._v("人 ")])])]),_c('div',{staticClass:"buy-box"},[_c('a-skeleton',{attrs:{"loading":_vm.loading,"active":"","paragraph":{ rows: 14 },"title":false}},[(_vm.list.length > 0)?_c('ul',{staticClass:"clearfix"},_vm._l((_vm.list),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"to":{ name: 'GrouponDetail', params: { id: item.id } }}},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":item.brand.logo_url}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"style-list"},_vm._l((item.series),function(s){return _c('router-link',{key:s.id,attrs:{"to":{ name: 'Series', params: { id: s.id } }}},[_c('span',[_vm._v(_vm._s(s.name))])])}),1),_c('div',{staticClass:"bottom"},[_c('p',[_vm._v(" 已报名"),_c('em',[_vm._v(" "+_vm._s(item.current_people))]),_vm._v("人 ")]),_c('p',[_vm._v(_vm._s(item.start_date)+"-"+_vm._s(item.end_date))]),_c('router-link',{attrs:{"to":{ name: 'GrouponDetail', params: { id: item.id } }}},[_c('span',[_vm._v("详情")])])],1)],1)}),0):_vm._e(),(_vm.list.length ==0)?_c('div',{},[_c('NoData',{attrs:{"text":"本地暂未开通团购活动"}})],1):_vm._e()])],1)]),_c('service-intro')],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }