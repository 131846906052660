<template>
  <section class="footer">
    <div class="w1200 flexBC pt20 pb-20">
      <div class="flexR footer__left">
        <div class="flexBC">
          <div
            class="flexR"
            style="
              width: 330px;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <p style="width: 100%">客服热线：(周一至周日 8:30-18:00)</p>
            <p class="f32" style="width: 100%">15117971875</p>
          </div>
          <div class="flexEnd footNav" style="flex: 1; align-items: flex-start">
            <div
              class="flexL"
              style="
                flex-direction: column;
                margin-right: 100px;
                align-items: flex-start;
              "
            >
              <router-link
                :to="{ name: 'AboutUs' }"
                class="c7b818d"
                target="_blank"
                style="font-size: 15px; margin-bottom: 10px"
              >
                关于我们
              </router-link>
              <router-link
                :to="{ name: 'Agreement' }"
                class="c7b818d"
                target="_blank"
                style="font-size: 15px; margin-bottom: 10px"
              >
                服务协议
              </router-link>
            </div>
            <div
              class="flexL"
              style="
                flex-direction: column;
                margin-right: 100px;
                align-items: flex-start;
              "
            >
              <router-link
                :to="{ name: 'QuotePage' }"
                class="c7b818d"
                target="_blank"
                style="font-size: 15px; margin-bottom: 10px"
              >
                汽车报价
              </router-link>
            </div>
            <div
              class="flexL"
              style="
                flex-direction: column;
                margin-right: 100px;
                align-items: flex-start;
              "
            >
              <router-link
                :to="{ name: 'Cars' }"
                class="c7b818d"
                target="_blank"
                style="font-size: 15px; margin-bottom: 10px"
              >
                特卖品牌
              </router-link>
            </div>
          </div>
        </div>
        <div class="f-left__copyright">
          <div class="footbottomTxt" style="font-size: 12px">
            ©2017-2027 www.shengxingdt.com All Rights Reserved
            北京晟行科技有限公司 版权所有
            ICP备案证书号：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="icp">京ICP备2020040719号-1</a><br />
          </div>
        </div>
        <div id="scroll_div" class="scroll_div footScroll footNav">
          <div id="scroll_begin">
            <ul style="display: inline"></ul>
          </div>
          <div id="scroll_end"></div>
        </div>
      </div>
      <!-- <div class="flexR footer__right">
        <div style="height: 145px" class="mb-05">
          <img
            src="//img.kchezhan.com/images/lzweb/lzewm.jpg"
            style="width: 145px; height: 145px"
          />
        </div>
        <p class="f16">扫码关注官方微信</p>
      </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  props: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 20px 0;
  background-color: #303030;
  box-shadow: inset 0px 2px 0px 0px #d62727;
  color: #7b818d;

  .footer__left {
    flex-direction: column;

    .f-left__copyright {
      width: 100%;
      border-top: 1px solid #242424;
      box-shadow: 0px -1px 0px 0px #3e3e3e;
      margin-top: 20px;
      padding-top: 15px;
    }
  }

  .footer__right {
    width: 250px;
    margin-left: 50px;
    flex-direction: column;
    box-shadow: -1px 0px 0px 0px #4d4d4d;
    border-left: solid 1px #0e0e0e;
  }
  p {
    margin-bottom: 0;
  }
  .f32 {
    font-size: 32px;
  }
  .c7b818d {
    color: #7b818d;
  }
  .icp {
    color:  #7b818d;
    &:hover {
      color:  #7b818d;
    }
  }
}
</style>
