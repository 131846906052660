<template>
  <!-- 主营品牌 -->
  <div class="main_brand">
    <div class="jxsindex_title">主营品牌</div>
    <ul>
      <li
        class="clearFix"
        v-for="brand in brands"
        :key="brand.id"
        style="padding-left: 20px"
      >
        <a class="fl" style="width: 66px; height: 66px; margin-right: 10px">
          <img :src="brand.logo_url" width="66" height="66" />
        </a>
        <div class="main_brandinfor fl">
          <h2>{{ brand.name }}</h2>
          <ul>
            <li
              v-for="item in brand.series"
              :key="item.id"
              style="margin-bottom: 5px"
            >
              <router-link
                :to="{
                  name: 'DealerSeries',
                  params: { id: id },
                  query: { seriesId: item.id },
                }"
                style="color: #333"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getDealerSeries } from "@/api/dealer";

export default {
  name: "DealerMainBrand",
  data() {
    return {
      brands: [],
      id: 0,
    };
  },
  props: {},
  mounted() {},
  computed: {},
  created() {
    this.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    getData() {
      getDealerSeries(this.id).then((resp) => {
        this.brands = resp.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main_brand {
  width: 300px;
  border: solid 1px #c6c6c6;
  min-height: 280px;
  overflow: hidden;
  padding-bottom: 10px;
  .jxsindex_title {
    padding-left: 25px;
    font-size: 20px;
    line-height: 34px;
    height: 34px;
    margin: 10px auto;
    color: #333;
    letter-spacing: 0px;
  }
  .main_brand_item_warp {
    .main_brand_item {
      padding: 10px 25px;
      border-bottom: 1px solid #dadada;
      overflow: hidden;

      &:last-child {
        border-bottom: none;
      }

      .main_brandinfor {
        padding-left: 15px;
        h2 {
          font-size: 16px;
        }

        ul {
          li {
            border-bottom: none;
            padding: 0;
            cursor: pointer;
          }
          li.selection {
            a {
              color: #dd2a2a;
            }
          }
        }
      }
    }
  }
}
</style>
