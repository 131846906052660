<template>
  <div class="container">
    <PageHeader current="index" />
    <div class="main-container">
      <!-- 轮播图 -->
      <div class="slide">
        <a-carousel autoplay>
          <div class="slide-item">
            <img src="@/assets/banner1.jpg" alt="" />
          </div>
          <div class="slide-item">
            <img src="@/assets/banner2.jpg" alt="" />
          </div>
        </a-carousel>
      </div>

      <!-- 选车 -->
      <select-car> </select-car>
      <!-- 资讯 -->
      <div class="w1200 cm-container">
        <section class="cm-container__left">
          <div class="cm-container__title">
            <div class="cmc-title__text fl">
              <h2 class="">精选资讯</h2>
            </div>
            <ul class="cmc-subtitle__list fl">
              <li class="cmc-subtitle__item fl">
                <router-link
                  target="_blank"
                  :to="{ name: 'News', query: { classify: 2 } }"
                  class="cmc-title__more"
                >
                  新车资讯
                </router-link>
              </li>
              <li class="cmc-subtitle__item fl">
                <router-link
                  target="_blank"
                  :to="{ name: 'News', query: { classify: 3 } }"
                  class="cmc-title__more"
                >
                  导购精选
                </router-link>
              </li>
            </ul>
            <div class="fr">
              <router-link
                target="_blank"
                :to="{ name: 'News' }"
                class="cmc-title__more"
              >
                更多资讯<i class="more-icon icon-arrow-ic-r"></i
              ></router-link>
            </div>
          </div>

          <!-- 资讯轮播 -->
          <a-skeleton
            :loading="carsouelNewsLoading"
            active
            :paragraph="{ rows: 11 }"
            :title="false"
          >
            <a-carousel :autoplay="true">
              <div
                class="cm-container__carousel"
                v-for="(item, i) in carsouelNews"
                v-bind:key="i"
              >
                <!-- 资讯大图 -->
                <div class="cmc-carousel__left fl">
                  <div style="overflow: hidden">
                    <div class="cmc-carousel__bigwarpper">
                      <router-link
                        :to="{ name: 'NewsDetail', params: { id: item[0].id } }"
                      >
                        <div
                          :style="{
                            'background-image': 'url(' + item[0].img_url + ')',
                          }"
                          class="cmc-carousel__bigimg"
                        ></div>
                      </router-link>
                    </div>
                    <div class="pic-title">
                      <div class="title">
                        <router-link
                          :to="{
                            name: 'NewsDetail',
                            params: { id: item[0].id },
                          }"
                        >
                          {{ item[0].title }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 资讯小图 -->
                <div class="cmc-carousel__right fr">
                  <div
                    class="small-video-wrapper"
                    v-for="news in item.slice(1)"
                    v-bind:key="news.id"
                  >
                    <div class="video-transition">
                      <router-link
                        :to="{ name: 'NewsDetail', params: { id: news.id } }"
                      >
                        <div
                          :style="{
                            'background-image': 'url(' + news.img_url + ')',
                          }"
                          class="small-video"
                        ></div>
                      </router-link>
                    </div>
                    <div class="pic-title">
                      <div class="title">
                        <router-link
                          :to="{ name: 'NewsDetail', params: { id: news.id } }"
                        >
                          {{ news.title }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-carousel>
          </a-skeleton>
        </section>
        <section class="cm-container__right">
          <div class="videos-wrapper">
            <div class="title">
              <h2 class="">资讯排行榜</h2>
            </div>
          </div>
          <ul style="margin-top: 1px" class="cm-container__rank">
            <a-skeleton
              :loading="rankNewsLoading"
              active
              :paragraph="{ rows: 11 }"
              :title="false"
            >
              <li
                class="cmc-rank__item"
                v-for="(item, i) in rankNews"
                v-bind:key="item.id"
              >
                <span class="cmc-rank__num">{{ i + 1 }}</span>
                <router-link
                  :to="{ name: 'NewsDetail', params: { id: item.id } }"
                >
                  <span class="cmc-rank__title gid-title-bold">{{
                    item.title
                  }}</span>
                </router-link>
                <!-- <span class="cmc-rank__count">118.3万次播放</span> -->
              </li>
            </a-skeleton>
          </ul>
        </section>
      </div>

      <!-- 车图 -->
      <div class="w1200 cm-container">
        <section class="cm-container__left">
          <div class="cm-container__title">
            <div class="cmc-title__text fl">
              <h2 class="">精美车图</h2>
            </div>
            <div class="fr">
              <router-link
                target="_blank"
                :to="{ name: 'CarImgs' }"
                class="cmc-title__more"
              >
                更多车图<i class="more-icon icon-arrow-ic-r"></i
              ></router-link>
            </div>
          </div>
          <a-skeleton
            :loading="carImgLoading"
            active
            :paragraph="{ rows: 11 }"
            :title="false"
          >
            <a-carousel autoplay>
              <div
                class="cm-container__carousel"
                v-for="(item, i) in carImgs"
                v-bind:key="i"
              >
                <div class="cmc-carousel__left fl">
                  <div class="image-transition">
                    <router-link
                      target="_blank"
                      :to="{ name: 'CarImgSeries', params: { id: item[0].id } }"
                    >
                      <div
                        :style="{
                          'background-image': 'url(' + item[0].cover_url + ')',
                          'background-repeat': 'no-repeat',
                          'background-size': '100% 100%',
                        }"
                        class="big-image"
                      ></div>
                      <div class="series-name">{{ item[0].name }}</div>
                    </router-link>
                  </div>
                </div>
                <div class="cmc-carousel__right fr">
                  <div
                    class="image-wrapper"
                    v-for="s in item.slice(1)"
                    v-bind:key="s.id"
                  >
                    <div class="image-transition">
                      <router-link
                        target="_blank"
                        :to="{ name: 'CarImgSeries', params: { id: s.id } }"
                      >
                        <div
                          :style="{
                            'background-image': 'url(' + s.cover_url + ')',
                            'background-repeat': 'no-repeat',
                            'background-size': '100% 100%',
                          }"
                          class="small-image"
                        ></div>
                        <div class="series-name">{{ s.name }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </a-carousel>
          </a-skeleton>
        </section>
        <section class="cm-container__right">
          <div class="videos-wrapper">
            <div class="title">
              <h2 class="">特卖品牌</h2>
            </div>
          </div>

          <div class="tm__brand">
            <router-link :to="{ name: 'Cars', query: { bid: 249 } }"
              ><img src="@/assets/images/mzd.jpeg" />
            </router-link>
          </div>
        </section>
      </div>

      <!-- 热卖车型 -->
      <div class="w1200 cm-container">
        <section class="cm-container__left">
          <div class="cm-container__title">
            <div class="cmc-title__text fl">
              <h2 class="">团购活动</h2>
            </div>
          </div>
          <div class="temai">
            <div
              class="flexL"
              style="margin-top: 10px; position: relative"
              v-for="item in tuans.slice(0, 3)"
              v-bind:key="item.id"
            >
              <div class="tm__logo">
                <img :src="item.brand.logo_url" />
              </div>
              <div class="tm-car__img flexR">
                <img :src="item.series[0].img_url" />
              </div>
              <div class="tm__info pl30 pt10">
                <h3>{{ item.title }}团购会</h3>
                <p class="tm__brand">品牌：{{ item.brand.name }}</p>
                <p class="tm__time">
                  特卖时间：{{ item.start_date }} - {{ item.end_date }}
                </p>
                <p class="time">
                  特卖车系：<span
                    v-for="s in item.series.slice(0, 4)"
                    :key="s.id"
                    style="margin-right: 8px"
                    class="f666"
                    >{{ s.name }}</span
                  >
                </p>
                <div class="tm__submit flexR">
                  <router-link
                    :to="{ name: 'GrouponDetail', params: { id: item.id } }"
                    style="color: #fff"
                  >
                    我要报名
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="cm-container__right">
          <div class="videos-wrapper">
            <div class="title">
              <h2 class="">销量排行榜</h2>
            </div>
            <div class="sale-rank">
              <a-skeleton
                :loading="saleRankLoading"
                active
                :paragraph="{ rows: 15 }"
                :title="false"
              >
                <div
                  class="s-rank__series"
                  v-for="(item, i) in saleRanks"
                  v-bind:key="i"
                >
                  <span
                    class="s-rank__icon s-rank__icon1"
                    v-if="i === 0"
                  ></span>
                  <span
                    class="s-rank__icon s-rank__icon2"
                    v-if="i === 1"
                  ></span>
                  <span
                    class="s-rank__icon s-rank__icon3"
                    v-if="i === 2"
                  ></span>
                  <span class="s-rank__icon s-rank__text" v-if="i > 2"
                    >{{ i + 1 }}.</span
                  >
                  <router-link
                    :to="{ name: 'Series', params: { id: item.id } }"
                  >
                    <span
                      class="s-rank_carimg"
                      :style="{
                        'background-image': 'url(' + item.cover_url + ')',
                      }"
                    ></span
                    ><span class="s-rank__name">{{ item.name }}</span
                    ><span class="s-rank__num"
                      >总销量
                      <!-- -->{{ item.sale_num }}</span
                    ></router-link
                  >
                </div>
              </a-skeleton>
            </div>
          </div>
        </section>
      </div>

      <!-- 底部介绍 -->
      <service-intro></service-intro>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  getIndexNews,
  getIndexCarImgs,
  getSaleRanks,
} from "@/api/index";
import { getCommonRankNews } from "@/api/common";
import PageHeader from "@/components/PageHeader";
import ServiceIntro from "@/components/index/ServiceIntro";
import SelectCar from "@/components/index/SelectCar";
import Footer from "@/components/Footer";
import { getGrouponIndexRecommends } from "@/api/groupon";

export default {
  name: "Index",
  components: {
    PageHeader,
    ServiceIntro,
    SelectCar,
    Footer,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      carsouelNewsLoading: true,
      rankNewsLoading: true,
      carImgLoading: true,
      saleRankLoading: true,
      carsouelNews: [],
      rankNews: [],
      carImgs: [],
      saleRanks: [],
      tuans: [],
    };
  },
  computed: {},
  beforeCreadted() {},
  created() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      getIndexNews().then((response) => {
        this.carsouelNews = response.data;
        this.carsouelNewsLoading = false;
      });

      getCommonRankNews().then((resp) => {
        this.rankNews = resp.data;
        this.rankNewsLoading = false;
      });

      getIndexCarImgs().then((resp) => {
        this.carImgs = resp.data;
        this.carImgLoading = false;
      });

      getSaleRanks().then((resp) => {
        this.saleRanks = resp.data;
        this.saleRankLoading = false;
      });

      getGrouponIndexRecommends().then((resp) => {
        this.tuans = resp.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-item {
  height: 440px;

  img {
    height: 440px;
    width: auto;
  }
}

.cm-container {
  display: flex;
  padding-top: 40px;
  .cm-container__left {
    width: 800px;
  }

  .cm-container__right {
    width: 380px;
    margin-left: 20px;
    .cm-container__rank {
      .cmc-rank__item {
        margin-bottom: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #1f2129;
        .cmc-rank__num {
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 18px;
          -webkit-letter-spacing: normal;
          -moz-letter-spacing: normal;
          -ms-letter-spacing: normal;
          letter-spacing: normal;
          color: #979aa8;
          width: 18px;
          height: 18px;
          border-radius: 2px;
          text-align: center;
          margin-right: 8px;
        }
        .cmc-rank__title {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #1f2129;
        }
        .cmc-rank__count {
          margin-left: auto;
          font-size: 12px;
          color: #979aa8;
          padding-left: 12px;
        }
      }
    }
  }
  .cm-container__title {
    height: 36px;
    line-height: 36px;
    margin-bottom: 16px;
    .cmc-title__text {
      h2 {
        font-size: 24px;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
    .cmc-subtitle__list {
      // display: inline-block;
      margin: 0 20px 0 40px;

      .cmc-subtitle__item {
        &:not(:last-child)::after {
          content: "|";
          padding: 0 8px;
          color: #e6e6e6;
        }
        cursor: pointer;
        & > a {
          font-size: 16px;
          color: #1a1a1a;
        }
      }
    }
    .cmc-title__more {
      font-size: 16px;
      color: #1a1a1a;
      -webkit-text-decoration: none;
      text-decoration: none;
      & > a {
        font-size: 16px;
        color: #1a1a1a;
      }
    }
  }

  .cm-container__carousel {
    width: 800px;
    .cmc-carousel__left {
      width: 380px;
      .pic-title {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
      }
    }

    .pic-title {
      display: flex;
      height: 52px;
      width: 100%;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #1f2129;
      background: #f2f4fa;
      .title {
        max-height: 52px;
        max-width: 100%;
        padding: 0 12px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        & > a {
          color: #1f2129;
        }
      }
    }

    .cmc-carousel__right {
      font-size: 14px;
      line-height: 20px;
      width: 420px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .video-transition {
        overflow: hidden;
      }
      .small-video-wrapper {
        width: 194px;
        margin-left: 16px;
        margin-bottom: 8px;
        border-radius: 2px;
        .small-video {
          width: 194px;
          height: 110px;
          overflow: hidden;
          position: relative;
          font-size: 10px;
          color: #ffffff;
          background-position: center;
          background-size: 100% 100%;
        }
      }
    }

    .cmc-carousel__bigwarpper {
      width: 380px;
      height: 280px;
      padding: 33px 0;
      overflow: hidden;
      border-radius: 2px;
      background-color: black;
      .cmc-carousel__bigimg {
        width: 100%;
        height: 214px;
        background-position: center;
        background-size: 100% 100%;
        position: relative;
      }
    }
  }
}

.image-transition {
  overflow: hidden;
  position: relative;

  .big-image {
    width: 380px;
    height: 260px;
    background-size: cover;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    border-radius: 2px;
  }
  .series-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #1f2129);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #ffffff;
  }
}
.image-wrapper {
  margin-left: 16px;
  margin-bottom: 8px;
}
.small-image {
  width: 194px;
  height: 126px;
}
.temai {
  // width: 750px;
  position: relative;
  height: 100%;
  .tm__logo {
    position: absolute;
    left: 20px;
    top: 5px;
    width: 75px;
    height: 75px;
    overflow: hidden;
    img {
      display: inline;
      border: none;
      font-size: 0px;
      width: 100%;
    }
  }
  .tm-car__img {
    width: 390px;
    margin-right: 20px;
    img {
      max-width: 95%;
      height: 240px;
    }
  }

  .tm__info {
    border-left: 1px solid #e7e7e7;
    height: 230px;
    padding-left: 50px;
    padding-top: 10px;
    position: relative;
    & > h3 {
      margin-bottom: 20px;
      font-size: 20px;
      margin-left: -0.5em;
    }
    & > p {
      color: #444;
      margin-bottom: 5px;
    }
    .tm__submit {
      width: 200px;
      height: 41px;
      background-image: linear-gradient(0deg, #f7891b 0%, #f7b11b 100%),
        linear-gradient(#fa8d00, #fa8d00);
      font-size: 16px;
      color: #fff;
      margin-top: 30px;
      margin-left: 3.5em;
      cursor: pointer;
    }
  }
}

.tm__brand {
  img {
    width: 100%;
  }
}

.sale-rank {
  padding-bottom: 16px;
  .s-rank__series {
    height: 60px;
    margin-top: 16px;
    .s-rank__icon {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-right: 4px;
      vertical-align: middle;
    }
    .s-rank__icon1 {
      background: url("../assets/icons/sale1.png") no-repeat center / 36px 36px;
    }
    .s-rank__icon2 {
      background: url("../assets/icons/sale2.png") no-repeat center / 36px 36px;
    }
    .s-rank__icon3 {
      background: url("../assets/icons/sale3.png") no-repeat center / 36px 36px;
    }
    .s-rank__text {
      font-size: 18px;
      font-weight: bold;
      font-family: DINAlternate;
      text-align: center;
      line-height: 36px;
    }
    .s-rank_carimg {
      width: 90px;
      height: 60px;
      margin-right: 12px;
      vertical-align: middle;
      position: relative;
      display: inline-block;

      background-size: cover;
      background-position: center center;
      transition: transform 0.3s ease-in-out 0s;
    }

    .s-rank__name {
      color: #1f2129;
      display: inline-block;
      width: 90px;
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      vertical-align: text-top;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      -webkit-transition: color 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;
    }

    .s-rank__num {
      float: right;
      margin-top: 20px;
      color: #1a1a1a;
    }
  }
}

.f666 {
  color: #666;
}

</style>

