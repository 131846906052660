import request from '@/utils/request'

export function getBrands(params) {
    return request({
        url: '/brands',
        method: 'get',
        params
    })
}

export function getSeriesByBrandId(id, params) {
    return request({
        url: `/brand/${id}`,
        method: 'get',
        params
    })
}

export function getSeriesById(id, params) {
    return request({
        url: `/car/series/${id}`,
        method: 'get',
        params
    })
}

export function getSeriesImagesById(id, params) {
    return request({
        url: `/car/series/${id}/images`,
        method: 'get',
        params
    })
}

export function getSeriesNewsById(id, params) {
    return request({
        url: `/series/news/${id}`,
        method: 'get',
        params
    })
}

export function getSearchCars(params) {
    return request({
        url: '/car/search',
        method: 'get',
        params
    })
}

export function getHotSeries(params) {
    return request({
        url: '/car/series/hots',
        method: 'get',
        params
    })
}

export function getHotBrands(params) {
    return request({
        url: '/brand/hots',
        method: 'get',
        params
    })
}

export function getSeriesByName(params) {
    return request({
        url: `/series/name`,
        method: 'get',
        params
    })
}

export function getSeriesConfigById(id, params) {
    return request({
        url: `/car/series/${id}/configs`,
        method: 'get',
        params
    })
}